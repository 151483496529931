import React, { useState } from "react";
import { Button, Text } from "@hackthenorth/north";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

import { useSiteContext } from "context/site";
import copy from "copy";
import { Background } from "static/img";

import CustomGoose from "../../components/CustomGoose";
import AccessoryPicker from "./components/AccessoryPicker";
import ColorPicker from "./components/ColorPicker";
import Heading from "components/Heading";
import { useFormContext } from "context/form";

/**
 * Represents the two steps that a user needs to go through
 * to build their robogoose. Applicable only on mobile.
 */
export enum Step {
  PICK_COLOR,
  PICK_ACCESSORY
}

export interface SelectionProps {
  selected: boolean;
  readOnly?: boolean;
}

export interface DisplayProps {
  show: boolean;
}

export interface SplitViewProps {
  showOnDesktop?: boolean;
  showOnMobile?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 35px;

  ${({ theme }) => theme.mediaQueries.tablet`
    height: auto;
  `}
`;

const Container = styled.div`
  display: grid;
  position: relative;
  font-family: ${props => props.theme.globalConstants.fontFamily.heading};
  grid-template-areas:
    "header header header header header header"
    "color goose goose goose accessory accessory "
    "button button tip tip . .";
  width: 850px;

  ${({ theme }) => theme.mediaQueries.tablet`
    grid-template-areas:
      "header"
      "accessory"
      "tip"
      "color"
      "goose"
      "button";
  `}
`;

const Header = styled.div<SplitViewProps>`
  border: 10px solid ${({ theme }) => theme.globalConstants.color.navy};
  grid-area: header;
  text-align: right;
  padding: 5px 25px;

  & > div {
    margin-bottom: 0;
  }

  ${({ theme, showOnMobile }) => theme.mediaQueries.tablet`
    display: ${showOnMobile ? "initial" : "none"};
    text-align: center;
    padding: 10px;

    h1 {
      font-size: ${theme.globalConstants.fontSize.heading}px;
    }

    h3 {
      font-size: ${theme.globalConstants.fontSize.subtext}px;
    }
  `}
`;

// Wrapper for the CustomGoose
const GooseCage = styled.div`
  grid-area: goose;

  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;

  height: 350px;
  margin-top: 10px;
  padding: 20px 15px 0 15px;

  border: 10px solid ${({ theme }) => theme.globalConstants.color.navy};
  border-left: none;
  border-right: none;
  background-image: url(${Background});

  ${({ theme }) => theme.mediaQueries.tablet`
    width: 275px;
    margin: auto;

    border: none;
  `}
`;

const TipText = styled(Text)<SplitViewProps>`
  grid-area: tip;
  justify-self: center;
  align-self: center;
  width: 90%;
  padding: 20px 0 0 0;

  ${({ theme, showOnMobile }) => theme.mediaQueries.tablet`
    display: ${showOnMobile ? "initial" : "none"};
    text-align: center;


    padding-bottom: 20px;

    width: 100%;
  `}
`;

const ButtonWrapper = styled.div`
  grid-area: button;
  display: flex;
  margin-top: 20px;

  ${({ theme }) => theme.mediaQueries.tablet`
    justify-self: center;
    margin-top: 15px;
  `}
`;

const NavButton = styled(Button)<SplitViewProps>`
  display: ${({ showOnDesktop }) => (showOnDesktop ? "initial" : "none")};

  ${({ theme, showOnMobile }) => theme.mediaQueries.tablet`
    display: ${showOnMobile ? "block" : "none"};
  `}
`;

// many `!important` since ReactTooltip does not apply most inline styles and we need
// to manually override
const ToolTip = styled(ReactTooltip)`
  font-family: ${props => props.theme.globalConstants.fontFamily.heading};
  font-size: 16px;

  border-radius: 0 !important;
  opacity: 1 !important;
  color: ${({ theme }) => theme.globalConstants.color.navy} !important;
  background-color: ${({ theme }) =>
    theme.globalConstants.color.white} !important;
  border: 5px solid ${({ theme }) => theme.globalConstants.color.navy};
  &:after {
    bottom: -10px !important;
    border-top-color: ${({ theme }) =>
      theme.globalConstants.color.navy} !important;
  }
`;

const PYRG = () => {
  const { goToNextStage, loggedIn } = useSiteContext();
  const {
    user: { name }
  } = useFormContext();
  const [step, setStep] = useState(Step.PICK_COLOR); // only applicable to mobile

  return (
    <Wrapper>
      <Container>
        <Header showOnMobile={step === Step.PICK_COLOR}>
          <Heading
            title={copy.pyrg.greeting(name)}
            subtitle={copy.pyrg.hashtag}
          />
        </Header>

        <ColorPicker showOnMobile={step === Step.PICK_COLOR} />

        <GooseCage>
          <CustomGoose editable />
        </GooseCage>

        <AccessoryPicker showOnMobile={step === Step.PICK_ACCESSORY} />

        <TipText variant="hint" showOnMobile={step === Step.PICK_ACCESSORY}>
          {loggedIn ? copy.pyrg.tipLoggedIn : copy.pyrg.tipLoggedOut}
        </TipText>

        <ButtonWrapper>
          <NavButton
            variant="textOnly"
            showOnMobile={step === Step.PICK_ACCESSORY}
            onClick={() => setStep(Step.PICK_COLOR)}
          >
            Back
          </NavButton>
          <NavButton
            variant="primary"
            showOnMobile={step === Step.PICK_COLOR}
            onClick={() => setStep(Step.PICK_ACCESSORY)}
          >
            {copy.pyrg.cta}
          </NavButton>
          <NavButton
            variant="primary"
            showOnDesktop
            showOnMobile={step === Step.PICK_ACCESSORY}
            onClick={goToNextStage}
          >
            {step === Step.PICK_ACCESSORY ? "Next" : copy.pyrg.cta}
          </NavButton>
        </ButtonWrapper>
      </Container>
      <ToolTip id="pyrg-tooltip" effect="solid" />
    </Wrapper>
  );
};

export default PYRG;
