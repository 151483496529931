import { ThemeDefinition } from "@hackthenorth/north";
import globalStyles from "./globalStyles";
import globalConstants from "./globalConstants";
import componentStyles from "./componentStyles";

const themeDef: ThemeDefinition = {
  ...(componentStyles as Partial<ThemeDefinition>),
  globalStyles,
  globalConstants,
  deviceBreakpoints: {
    large: 1440,
    medium: 1024,
    tablet: 768,
    largeMobile: 425,
    mobile: 375,
    smallMobile: 320
  }
};

export default themeDef;
