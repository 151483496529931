import React from "react";
import styled from "styled-components";

import Logo from "./components/Logo";
import NavBar from "components/NavBar";
import AccountInfo from "./components/AccountInfo";

const Border = styled.div`
  z-index: 100;
  background-color: ${props => props.theme.globalConstants.color.primaryBlue};
  position: fixed;

  &#left,
  &#right {
    top: 0;
    bottom: 0;
    width: ${({ theme }) => theme.globalConstants.border.pageSides}px;

    ${({ theme }) => theme.mediaQueries.largeMobile`
      width: ${theme.globalConstants.border.pageSidesMobile}px;
    `}
  }
  &#left {
    left: 0;
  }
  &#right {
    right: 0;
  }

  &#top,
  &#bottom {
    left: 0;
    right: 0;
  }
  &#top {
    top: 0;
    height: ${({ theme }) => theme.globalConstants.border.pageTop}px;

    ${({ theme }) => theme.mediaQueries.largeMobile`
      height: ${theme.globalConstants.border.pageTopMobile}px;
    `}
  }
  &#bottom {
    bottom: 0;
    height: ${({ theme }) => theme.globalConstants.border.pageBottom}px;

    ${({ theme }) => theme.mediaQueries.largeMobile`
      height: ${theme.globalConstants.border.pageBottomMobile}px;
    `}
  }
`;

const PageWrapper: React.FC = () => (
  <>
    <Border id="left" />
    <Border id="right" />
    <Border id="top">
      <NavBar />
    </Border>
    <Border id="bottom">
      <AccountInfo />
      <Logo />
    </Border>
  </>
);

export default PageWrapper;
