import React from "react";
import styled from "styled-components";
import { UnstyledTextInput, TextInputProps } from "@hackthenorth/north";
import { ApplicationStatus } from "interfaces/application";
import { useFormContext } from "context/form";

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  &:before {
    content: "";
    position: absolute;

    width: 100%;
    height: ${props => props.theme.globalConstants.border.input};
    bottom: 0;

    background-color: ${props => props.theme.globalConstants.color.activeBlue};
    visibility: hidden;
    transform: scaleX(0);
    transition: all 150ms ease 0s;
  }

  & input {
    width: 100%;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &:focus-within input {
    outline: none;
  }

  &:focus-within:before {
    visibility: visible;
    transform: scaleX(1);
  }
`;

const UnderlineTextInput: React.FC<TextInputProps> = (
  props: TextInputProps
) => {
  const {
    application: { status }
  } = useFormContext();
  return (
    <Wrapper>
      <UnstyledTextInput
        disabled={status !== ApplicationStatus.IN_PROGRESS}
        {...props}
      />
    </Wrapper>
  );
};

export default UnderlineTextInput;
