import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { Button, Loader, Text } from "@hackthenorth/north";

import { useFormContext, hasValidResponse } from "context/form";
import Spinner from "components/Spinner";
import { EditButton } from "components/MiscButtons";
import copy from "copy";

const ErrorText = styled(Text)`
  margin-left: 15px !important;
`;

const LongQuestionEdit: React.FC<{ questionKey: string }> = ({
  questionKey
}) => {
  const [isSavingQuestion, setIsSavingQuestion] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);

  const {
    application,
    saveApplicationQuestion,
    setApplication
  } = useFormContext();

  const createNewApplication = useCallback(
    (key: string) => ({
      ...application,
      questions: {
        ...application.questions,
        [key]: {
          ...application.questions[key],
          allowEdit: !application.questions[key].allowEdit
        }
      }
    }),
    [application]
  );

  const saveOnClick = useCallback(() => {
    const appQuestion = application.questions[questionKey];
    const isValidResponse = hasValidResponse(appQuestion);

    if (isValidResponse) {
      const newApplication = createNewApplication(questionKey);
      setApplication(newApplication);
      setIsSavingQuestion(true);
      setIsInvalid(false);
      saveApplicationQuestion(application.questions[questionKey]).then(() =>
        setIsSavingQuestion(false)
      );
    } else {
      setIsInvalid(true);
    }
  }, [
    application.questions,
    createNewApplication,
    questionKey,
    saveApplicationQuestion,
    setApplication
  ]);

  return (
    <>
      {application.questions[questionKey].allowEdit || isSavingQuestion ? (
        <Button
          onClick={saveOnClick}
          variant="secondary"
          disabled={isSavingQuestion}
        >
          <Loader loading={isSavingQuestion} render={Spinner} />
          {!isSavingQuestion && "Save"}
        </Button>
      ) : (
        <EditButton
          onClick={() => {
            const newApplication = createNewApplication(questionKey);
            setApplication(newApplication);
          }}
        />
      )}
      {isInvalid && (
        <ErrorText variant="subtextError">
          {application.questions[questionKey].hackerapiField === "links"
            ? copy.extendedQuestions.subtextInvalidLink
            : copy.extendedQuestions.subtextUnanswered}
        </ErrorText>
      )}
    </>
  );
};

export default LongQuestionEdit;
