/**
 * Context to handle stage-related state
 *  - keeps track of current state of user
 *  - saves seen stages
 *  - functions to go to next/previous stages
 *  - callbacks to set/clear HackerAPI tokens
 */

import React, {
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
  useMemo
} from "react";
import { useRouter } from "utils/hooks/routerHooks";

const { HackerAPI } = window as any; // eslint-disable-line

export enum Stage {
  LANDING,
  ABOUT,
  ACCOUNT,
  APPLICATION, // more long answer questions
  REVIEW,
  STATUS
}

export const FIRST_STAGE: Stage = 0;
export const LAST_STAGE: Stage = Object.keys(Stage).length / 2;

export enum AuthPage {
  LOG_IN,
  CREATE_ACCOUNT,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  LOADING
}

export const RouteNames = {
  [Stage.LANDING]: "/",
  [Stage.ABOUT]: "/about",
  [Stage.ACCOUNT]: "/account",
  [Stage.APPLICATION]: "/application",
  [Stage.REVIEW]: "/review",
  [Stage.STATUS]: "/status",
  LOGIN: "/login"
};

const validRoutes = new Set(Object.values(RouteNames));

export interface SiteState {
  seenStages: Set<Stage>; // stages the user can access
  curStage: Stage;
  loggedIn: boolean;
  authPage: AuthPage;
  bypassToLogin: boolean;
  token: string | null;

  addSeenStage: (stage: Stage) => void;
  setSeenStages: (stages: Set<Stage>) => void;
  goToPrevStage: () => void;
  goToNextStage: () => void;
  goToStage: (stage: Stage) => void;
  setAuthPage: (page: AuthPage) => void;
  onLogIn: (token: SiteState["token"]) => void;
  onLogOut: () => void;
}

export const defaultState: SiteState = {
  seenStages: new Set([Stage.LANDING]),
  curStage: Stage.LANDING,
  loggedIn: false,
  authPage: AuthPage.LOADING,
  bypassToLogin: true,
  token: null,

  addSeenStage: () => {},
  setSeenStages: () => {},
  goToPrevStage: () => {},
  goToNextStage: () => {},
  goToStage: () => {},
  setAuthPage: () => {},
  onLogIn: () => {},
  onLogOut: () => {}
};

export const SiteContext: React.Context<SiteState> = createContext(
  defaultState
);

export const useSiteContext = () => useContext(SiteContext);

export const SiteProvider: React.FC = ({ children, ...rest }) => {
  const {
    history,
    location: { pathname }
  } = useRouter();
  const [seenStages, updateSeenStages] = useState(defaultState.seenStages);
  const [curStage, updateCurStage] = useState(defaultState.curStage);
  const [token, updateToken] = useState<string | null>(defaultState.token);
  const [authPage, internalSetAuthPage] = useState(defaultState.authPage);

  const setToken = useCallback((newToken: SiteState["token"]) => {
    updateToken(newToken);
    HackerAPI.Auth.token = newToken;
  }, []);

  const addSeenStage = useCallback(
    (stage: Stage) =>
      updateSeenStages(prevSeenStages => prevSeenStages.add(stage)),
    []
  );
  const setSeenStages = useCallback(
    (stages: Set<Stage>) => updateSeenStages(stages),
    []
  );
  const goToStage = useCallback(
    (stage: Stage) => {
      addSeenStage(stage);
      history.push(RouteNames[stage]);
    },
    [addSeenStage, history]
  );
  const goToPrevStage = useCallback(() => {
    const isFirstStage = curStage === FIRST_STAGE;
    const newStage = isFirstStage ? curStage : curStage - 1;
    goToStage(newStage);
  }, [curStage, goToStage]);
  const goToNextStage = useCallback(() => {
    const isLastStage = curStage === LAST_STAGE;
    const newStage = isLastStage ? curStage : curStage + 1;
    goToStage(newStage);
  }, [curStage, goToStage]);

  const bypassToLogin = useMemo(() => {
    const b = pathname === RouteNames.LOGIN;

    if (b) {
      history.push(RouteNames.LOGIN);
      internalSetAuthPage(AuthPage.LOG_IN);
      return true;
    }
    return b;
  }, [pathname]); // eslint-disable-line

  const onLogIn = useCallback(
    (newToken: string | null) => {
      setToken(newToken);
    },
    [setToken]
  );

  const onLogOut = useCallback(() => {
    setToken(null);
  }, [setToken]);

  const setAuthPage = useCallback(
    (page: AuthPage) => {
      if (bypassToLogin && page === AuthPage.CREATE_ACCOUNT) {
        goToStage(Stage.LANDING);
      }
      internalSetAuthPage(page);
    },
    [bypassToLogin, goToStage]
  );

  useEffect(() => {
    if (validRoutes.has(pathname) && pathname !== RouteNames[Stage.LANDING]) {
      history.push(RouteNames.LOGIN);
    } else {
      goToStage(Stage.LANDING);
    }
  }, []); // eslint-disable-line

  useEffect(() => {
    const stageForCurRoute = Object.keys(RouteNames).filter(
      route => RouteNames[route] === pathname
    )[0];

    if (stageForCurRoute) updateCurStage(Number(stageForCurRoute));

    // since login pathname is not a real stage, update stage to Account
    if (pathname === RouteNames.LOGIN) updateCurStage(Stage.ACCOUNT);
  }, [pathname]);

  const siteState: SiteState = {
    seenStages,
    curStage,
    loggedIn: token !== null,
    authPage,
    bypassToLogin,
    token,
    addSeenStage,
    setSeenStages,
    goToPrevStage,
    goToNextStage,
    goToStage,
    setAuthPage,
    onLogIn,
    onLogOut
  };

  return (
    <SiteContext.Provider value={siteState} {...rest}>
      {children}
    </SiteContext.Provider>
  );
};
