export enum EducationLevel {
  UNDERGRADUATE = "undergraduate",
  GRADUATE = "graduate",
  HIGHSCHOOL = "high_school",
  MIDDLESCHOOL = "middle_school",
  OTHER = "other"
}

export enum School {
  UOFT = "University of Toronto (CA)",
  UW = "University of Waterloo (CA)",
  UBC = "University of British Columbia (CA)",
  CARLETON = "Carleton University (CA)",
  MCGILL = "McGill University (CA)",
  MCMASTER = "McMaster University (CA)",
  UOTTAWA = "University of Ottawa (CA)",
  WESTERN = "University of Western Ontario (CA)"
}

export enum GooseColor {
  MINT = "mint",
  BEIGE = "beige",
  BLUE = "blue",
  GREEN = "green"
}

export const NUM_GOOSE_COLORS = Object.keys(GooseColor).length;

export enum GooseAccessory {
  HARDHAT = "hardhat",
  BERET = "beret",
  HEADPHONES = "headphones",
  SCARF = "scarf"
}

export const NUM_GOOSE_ACCESSORIES = Object.keys(GooseAccessory).length;

export interface User {
  // About
  name: string;
  email: string;
  graduatingYear: "2019" | "2020" | "2021" | "2022" | "2023" | "2024" | "";
  educationLevel: EducationLevel | null;
  // Education
  school: string;
  degree: string;
  // Travel
  location: string;
  latitude: number;
  longitude: number;
  // Hackathons
  numHackathons: number | null; // `hackathons` in HackerAPI?
  // Goose
  color: GooseColor;
  accessory: GooseAccessory | null;
}

/**
 * Allows you to nest Partials.
 * Taken from https://stackoverflow.com/questions/47914536/use-partial-in-nested-property-with-typescript
 */
export type NestedPartial<T> = {
  [K in keyof T]?: T[K] extends (infer R)[]
    ? NestedPartial<R>[]
    : NestedPartial<T[K]>
};
