import React from "react";
import styled from "styled-components";
import { Text } from "@hackthenorth/north";

interface TermProps {
  text: React.ReactChild;
  label: React.ReactChild;
  onClick: (checked: boolean) => void;
  checked: boolean;
  id: string;
  disabled: boolean;
}

const TermContainer = styled.div`
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Margin = styled.div`
  margin-bottom: 8px;
`;

const Flex = styled.div`
  display: flex;
  input[type="checkbox"] {
    position: relative;
    top: 3px;
    margin-right: 5px;
  }
`;

const Term: React.FC<TermProps> = React.memo(
  ({ text, label, onClick, checked, id, disabled }) => (
    <TermContainer>
      <Margin>
        <Text variant="subtext">{text}</Text>
      </Margin>
      <Flex>
        <input
          type="checkbox"
          checked={checked}
          id={id}
          onChange={e => onClick(e.target.checked)}
          disabled={disabled}
        />
        <Text variant="subtextBold">
          <label htmlFor={id}>{label}</label>
        </Text>
      </Flex>
    </TermContainer>
  )
);

export default Term;
