import React, { useState, useCallback } from "react";
import styled from "styled-components";
import { Text, Button, Loader } from "@hackthenorth/north";

import {
  inputElementFactory,
  subOptionFactory
} from "pages/ExtendedResponses/Question";
import { withPageWrapper } from "utils/withPageWrapper";
import { withAuth } from "utils/withAuth";

import { useFormContext } from "context/form";
import copy from "copy";
import { useScrollTopOnMount } from "utils/hooks/useScrollTopOnMount";

import { EditButton } from "components/MiscButtons";
import ActionButtonsContainer from "components/ActionButtonsContainer";
import ContentContainer from "components/ContentContainer";
import NameTextInput from "components/NameTextInput";
import EmailTextInput from "components/EmailTextInput";
import SchoolDropdown from "components/SchoolDropdown";
import GradYearDropdown from "components/GradYearDropdown";
import LevelOfStudyDropdown from "components/LevelOfStudyDropdown";
import FieldOfStudyDropdown from "components/FieldOfStudyDropdown";
import TravelDropdown from "components/TravelDropdown";
import HackathonDropdown from "components/HackathonDropdown";
import LongQuestionEdit from "./components/LongQuestionEdit";
import Heading from "components/Heading";
import Spinner from "components/Spinner";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;

  ${({ theme }) => theme.mediaQueries.tablet`
    grid-template-columns: 1fr;
  `}
`;

const Section = styled.div`
  margin-bottom: 10px;
  padding-right: 25px;
`;

const SectionHeading = styled.div`
  padding-top: 10px;
  grid-column: 1 / -1;
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  p:first-child {
    font-size: ${props => props.theme.globalConstants.fontSize.heading}px;
    ${({ theme }) => theme.mediaQueries.tablet`
      font-size: ${theme.globalConstants.fontSize.smallBody}px;
    `}

    ${({ theme }) => theme.mediaQueries.largeMobile`
      font-size: ${theme.globalConstants.fontSize.subtext}px;
    `}

    display: inline-block;
    margin-right: 20px;
    margin-bottom: 0;
  }
`;

const QuestionWrapper = styled.div`
  grid-column: 1 / -1;
  border-top: 1px solid #d7d9dd;
  padding-top: 15px;
  margin-top: 15px;
`;

const TextWrapper = styled.div`
  word-break: break-word;
`;

const FactoryWrapper = styled.div`
  > div:first-of-type {
    display: block;
  }
`;

const Review = () => {
  useScrollTopOnMount();

  const [isInfoVisible, setInfoVisibility] = useState(false);
  const [isSavingPersonalInfo, setIsSavingPersonalInfo] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    application,
    savePersonalInfo,
    updateApplicationResponse,
    canSaveApplication,
    submitApplication,
    submitted
  } = useFormContext();

  const internalUpdateApplicationResponse = useCallback(
    (questionId: string, isCustomResponse: boolean) => (
      newResponse: string | string[]
    ) => updateApplicationResponse(questionId, newResponse, isCustomResponse),
    [updateApplicationResponse]
  );

  const setVisibility = useCallback(
    prevVisibility => setInfoVisibility(!prevVisibility),
    []
  );

  return (
    <ContentContainer>
      <Heading title={copy.review.heading} subtitle={copy.review.description} />
      <Grid>
        <SectionHeading>
          <Text variant="reviewTitle">{copy.review.personalInfo.heading}</Text>
          {!submitted &&
            (isInfoVisible || isSavingPersonalInfo ? (
              <Button
                onClick={() => {
                  setVisibility(isInfoVisible);
                  setIsSavingPersonalInfo(true);
                  savePersonalInfo().then(() => setIsSavingPersonalInfo(false));
                }}
                disabled={isSavingPersonalInfo}
                variant="secondary"
              >
                <Loader loading={isSavingPersonalInfo} render={Spinner} />
                {!isSavingPersonalInfo && "Save"}
              </Button>
            ) : (
              <EditButton onClick={() => setVisibility(isInfoVisible)} />
            ))}
        </SectionHeading>
        <Section>
          <Text variant="reviewTitle">{copy.review.personalInfo.name}</Text>
          <NameTextInput allowEdit={isInfoVisible} />
        </Section>

        <Section>
          <Text variant="reviewTitle">{copy.review.personalInfo.email}</Text>
          <EmailTextInput allowEdit={isInfoVisible} isReview />
        </Section>

        <Section>
          <Text variant="reviewTitle">{copy.review.personalInfo.gradYear}</Text>
          <GradYearDropdown allowEdit={isInfoVisible} />
        </Section>

        <Section>
          <Text variant="reviewTitle">
            {copy.review.personalInfo.levelOfStudy}
          </Text>
          <LevelOfStudyDropdown allowEdit={isInfoVisible} isReview />
        </Section>

        <Section>
          <Text variant="reviewTitle">
            {copy.review.personalInfo.currSchool}
          </Text>
          <SchoolDropdown allowEdit={isInfoVisible} />
        </Section>

        <Section>
          <Text variant="reviewTitle">
            {copy.review.personalInfo.fieldOfStudy}
          </Text>
          <FieldOfStudyDropdown allowEdit={isInfoVisible} />
        </Section>

        <Section>
          <Text variant="reviewTitle">
            {copy.review.personalInfo.prevExperience}
          </Text>
          <HackathonDropdown allowEdit={isInfoVisible} isReview />
        </Section>

        <Section>
          <Text variant="reviewTitle">{copy.review.personalInfo.location}</Text>
          <TravelDropdown allowEdit={isInfoVisible} />
        </Section>

        {Object.keys(application.questions)
          .filter(
            key =>
              application.questions[key] && !application.questions[key].isSurvey
          )
          .map((key, index) => {
            return (
              <QuestionWrapper key={key}>
                <SectionHeading>
                  <Text variant="reviewTitle">
                    {`${copy.review.questionHeading} ${index + 1}`}
                  </Text>
                  {!submitted && <LongQuestionEdit questionKey={key} />}
                </SectionHeading>
                <TextWrapper>
                  <Text variant="reviewTitle">
                    {application.questions[key].title}
                  </Text>
                  <FactoryWrapper>
                    {application.questions[key].allowEdit ? (
                      [
                        inputElementFactory(
                          key,
                          application.questions[key],
                          internalUpdateApplicationResponse(key, false),
                          internalUpdateApplicationResponse(key, true),
                          true,
                          !application.questions[key].allowEdit
                        ),
                        subOptionFactory(
                          application.questions[key],
                          () => null,
                          false,
                          true,
                          !application.questions[key].allowEdit
                        )
                      ]
                    ) : application.questions[key].responses &&
                      (application.questions[key].responses as []).length ? (
                      (application.questions[key].responses as []).map(
                        response => (
                          <Text key={response} variant="reviewBody">
                            {" "}
                            {response}{" "}
                          </Text>
                        )
                      )
                    ) : (
                      <Text variant="reviewBody">
                        {application.questions[key].response}
                      </Text>
                    )}
                  </FactoryWrapper>
                </TextWrapper>
              </QuestionWrapper>
            );
          })}
      </Grid>
      {!submitted && (
        <ActionButtonsContainer>
          <Button
            onClick={() => {
              setIsSubmitting(true);
              submitApplication().then(() => setIsSubmitting(false));
            }}
            variant="primary"
            disabled={!canSaveApplication || isSubmitting}
          >
            <Loader loading={isSubmitting} render={Spinner} />
            {!isSubmitting && copy.review.submit}
          </Button>
        </ActionButtonsContainer>
      )}
    </ContentContainer>
  );
};

export default withAuth(withPageWrapper(Review));
