import React, { useMemo } from "react";
import styled from "styled-components";
import { Text } from "@hackthenorth/north";
import posed, { PoseGroup } from "react-pose";

import { useFormContext } from "context/form";
import { ComicSectionProps } from "components/ComicPage";
import copy from "copy";

import { ComicSectionContainer } from "components/ComicPage/components/ComicSectionContainer";
import CustomGoose from "components/CustomGoose";
import HackathonDropdown, {
  HackathonExperience
} from "components/HackathonDropdown";

import {
  HackerBackgroundImg,
  StarBackground,
  SpeechBubbleAlt,
  SpeechBubbleAltMobile,
  FirstTimeHackerImg,
  VetHackerImg
} from "static/img";

interface GraphicWrapperProps {
  isFirstHackathon: boolean;
}

const Grid = styled.div`
  display: grid;
  grid-template-areas: "graphic content" "tip tip";
  grid-template-columns: 3fr 4.5fr;
  justify-content: center;
  position: relative;

  ${({ theme }) => theme.mediaQueries.tablet`
    grid-template-areas: "content" "graphic" "tip";
    grid-template-columns: 100%;
    height: 550px;
  `}
`;

const TipWrapper = styled.div<{ show: boolean }>`
  grid-area: tip;
  justify-self: end;
  margin-top: 10px;
  position: relative;

  transition: opacity 250ms;
  opacity: ${({ show }) => (show ? 1 : 0)};

  ${({ theme }) => theme.mediaQueries.tablet`
    width: 100%;
  `}
`;

const GraphicWrapper = styled.div<GraphicWrapperProps>`
  position: relative;
  overflow: hidden;
  grid-area: graphic;
  border: 10px solid ${({ theme }) => theme.globalConstants.color.primaryBlue};
  width: 100%;
  height: 450px;
  background: url(${HackerBackgroundImg}) center / cover no-repeat;

  ${({ theme, isFirstHackathon }) => theme.mediaQueries.tablet`
    width: 100%;
    margin-top: ${isFirstHackathon ? "-8px" : "-20px"};
    height: 225px;
  `}
`;

const ContentWrapper = styled.div`
  position: relative;
  display: grid;
  grid-area: content;
  background-image: url(${StarBackground});
  background-color: ${({ theme }) => theme.globalConstants.color.navy};
  color: ${({ theme }) => theme.globalConstants.color.primaryBlue};
  border: 10px solid ${({ theme }) => theme.globalConstants.color.primaryBlue};
  border-left: none;
  width: 100%;
  height: 450px;
  background-position: 63% 50%;

  ${({ theme }) => theme.mediaQueries.tablet`
    margin-left: 0;
    width: 100%;
    height: 310px;

    border: 10px solid ${theme.globalConstants.color.primaryBlue};
  `}
`;

const GooseCage = styled.div`
  position: absolute;
  bottom: -100px;
  left: -15px;

  ${({ theme }) => theme.mediaQueries.tablet`
    bottom: -130px;
    left: 0;
  `}

  ${({ theme }) => theme.mediaQueries.largeMobile`
    left: -25px;
  `}
`;

const SpeechBubble = styled.div`
  position: relative;
  grid-row: 1;
  grid-column: 1;
  align-self: center;
  width: 118%;
  height: 100%;
  top: 3%;
  right: 20%;

  background: url(${SpeechBubbleAlt}) center / auto no-repeat;

  ${({ theme }) => theme.mediaQueries.tablet`
    width: 100%;
    height: 100%;
    top: initial;
    right: initial;

    background: url(${SpeechBubbleAltMobile}) center / auto no-repeat;
  `}
`;

const SpeechTextWrapper = styled.div`
  grid-row: 1;
  grid-column: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  z-index: 2;
  width: 100%;

  position: relative;

  & p:last-child {
    margin: 0;
    width: 90%;
  }

  ${({ theme }) => theme.mediaQueries.tablet`
    width: 95%;
  `}
`;

interface AnimImgProps {
  width: string;
  top?: string;
  bottom?: string;
  left?: string;
  right?: string;
}

const AnimImgContainer = styled(
  posed.div({
    enter: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0 }
  })
)<AnimImgProps>`
  position: absolute;
  width: ${({ width }) => width};
  top: ${({ top }) => top || "unset"};
  bottom: ${({ bottom }) => bottom || "unset"};
  left: ${({ left }) => left || "unset"};
  right: ${({ right }) => right || "unset"};

  ${({ theme }) => theme.mediaQueries.tablet`
    bottom: 0;
  `}
`;

const AnimImg = styled.img<AnimImgProps>`
  position: absolute;
  width: ${({ width }) => width};
  top: ${({ top }) => top || "unset"};
  bottom: ${({ bottom }) => bottom || "unset"};
  left: ${({ left }) => left || "unset"};
  right: ${({ right }) => right || "unset"};
`;

const FirstLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  & input {
    text-align: center;
  }
`;

const experienceImages = {
  beenBefore: {
    width: "350px",
    top: "10px",
    bottom: "100px",
    left: "-5px",
    alt: "An assortment of technologies floating in the air",
    src: VetHackerImg
  },
  firstTime: {
    width: "130px",
    top: "10px",
    right: "10px",
    alt: "A gold badge that says '1st Time Hacker'",
    src: FirstTimeHackerImg
  }
};

const Hackathon: React.FC<ComicSectionProps> = ({
  id,
  sectionIndex,
  allowEdit,
  isActive,
  setActive,
  goBackwards,
  goForwards
}) => {
  const {
    user: { numHackathons }
  } = useFormContext();

  const isFirstHackathon = useMemo(
    () => numHackathons === HackathonExperience.FIRST,
    [numHackathons]
  );

  // memoize the displayed image to avoid re-rendering if you select
  // options that should display the same image (second, third, ... options)
  const DisplayedImage = useMemo(() => {
    if (numHackathons === null) {
      return <span key="nullImg" />;
    } else if (isFirstHackathon) {
      return (
        <AnimImgContainer key="firstTimeImage" {...experienceImages.firstTime}>
          <AnimImg {...experienceImages.firstTime} />
        </AnimImgContainer>
      );
    } else {
      return (
        <AnimImgContainer
          key="beenBeforeImage"
          {...experienceImages.beenBefore}
        >
          <AnimImg {...experienceImages.beenBefore} />
        </AnimImgContainer>
      );
    }
  }, [isFirstHackathon, numHackathons]);

  return (
    <ComicSectionContainer
      id={id}
      sectionIndex={sectionIndex}
      isActive={isActive}
      onClick={isActive ? () => {} : setActive}
    >
      <Grid>
        <GraphicWrapper isFirstHackathon={isFirstHackathon}>
          <PoseGroup>{DisplayedImage}</PoseGroup>
          <GooseCage>
            <CustomGoose />
          </GooseCage>
        </GraphicWrapper>

        <ContentWrapper>
          <SpeechBubble />
          <SpeechTextWrapper>
            <FirstLine>
              {allowEdit ? (
                <Text variant="body">{copy.hackathon.firstLine}</Text>
              ) : null}
              <HackathonDropdown comic allowEdit={allowEdit} />
            </FirstLine>
            {allowEdit ? (
              <Text variant="body">{copy.hackathon.secondLine}</Text>
            ) : null}
          </SpeechTextWrapper>
        </ContentWrapper>
        <TipWrapper show={isFirstHackathon}>
          <Text variant="hint" as="p">
            {copy.hackathon.hint}
          </Text>
        </TipWrapper>
      </Grid>
    </ComicSectionContainer>
  );
};

export default Hackathon;
