import { createGlobalStyle } from "styled-components";

/*
 * Castledown Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2019 Colophon Foundry
 *
 * Licenced to Stanley Huang, Techyon
 */
import FontCastledownEOT from "static/fonts/castledown-heavy.eot";
import FontCastledownTTF from "static/fonts/castledown-heavy.ttf";
import FontCastledownWOFF from "static/fonts/castledown-heavy.woff";
import FontCastledownWOFF2 from "static/fonts/castledown-heavy.woff2";

import FontInterRegularWOFF from "static/fonts/Inter-Regular.woff";
import FontInterRegularWOFF2 from "static/fonts/Inter-Regular.woff2";
import FontInterMediumWOFF from "static/fonts/Inter-Medium.woff";
import FontInterMediumWOFF2 from "static/fonts/Inter-Medium.woff2";
import FontInterBoldWOFF from "static/fonts/Inter-Bold.woff";
import FontInterBoldWOFF2 from "static/fonts/Inter-Bold.woff2";

const globalStyles = createGlobalStyle`
  @font-face {
      font-family: "Castledown";
      src: url(${FontCastledownEOT});
      src: url(${FontCastledownEOT}#iefix) format("embedded-opentype"),
           url(${FontCastledownWOFF2}) format("woff2"),
           url(${FontCastledownWOFF}) format("woff"),
           url(${FontCastledownTTF}) format("truetype");
      font-weight: normal;
      font-style: normal;
  }

  @font-face {
    font-family: "Inter";
    font-style:  normal;
    font-weight: 400;
    src: url(${FontInterRegularWOFF2}) format("woff2"),
         url(${FontInterRegularWOFF}) format("woff");
  }

  @font-face {
    font-family: "Inter";
    font-style:  normal;
    font-weight: 500;
    src: url(${FontInterMediumWOFF2}) format("woff2"),
         url(${FontInterMediumWOFF}) format("woff");
  }

  @font-face {
    font-family: "Inter";
    font-style:  normal;
    font-weight: 700;
    src: url(${FontInterBoldWOFF2}) format("woff2"),
         url(${FontInterBoldWOFF}) format("woff");
  }

  html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  *, *:before, *:after {
    box-sizing: inherit;
  }
`;

export default globalStyles;
