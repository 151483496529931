import styled from "styled-components";

const FormField = styled.div`
  margin-bottom: 30px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export default FormField;
