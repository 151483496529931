import React, { useCallback, useState } from "react";
import { useFormContext } from "context/form";
import { Auth, Terms } from "./pages";
import { withPageWrapper } from "utils/withPageWrapper";
import { useSiteContext } from "context/site";

const Account = () => {
  const { agreesToTerms, saveApplication } = useFormContext();
  const { bypassToLogin, loggedIn } = useSiteContext();

  const [showAuth, setShowAuth] = useState(agreesToTerms() || bypassToLogin);

  const onContinue = useCallback(() => {
    if (loggedIn) {
      saveApplication();
    }
    setShowAuth(true);
  }, [loggedIn, saveApplication]);

  if (showAuth || bypassToLogin) {
    return <Auth />;
  } else {
    return <Terms onContinue={onContinue} />;
  }
};

export default withPageWrapper(Account);
