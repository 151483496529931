/**
 * HTN styles for the Mapbox Geocoder input
 */
import styled from "styled-components";

const GeocoderInput = styled.div`
  position: relative;
  width: 100%;

  svg {
    display: none;
  }

  .mapboxgl-ctrl-geocoder {
    display: inline-block;
    position: relative;
    width: 100%;

    &:before {
      content: "";
      position: absolute;

      width: 100%;
      height: ${props => props.theme.globalConstants.border.input};
      bottom: 0;

      background-color: ${props =>
        props.theme.globalConstants.color.activeBlue};
      visibility: hidden;
      transform: scaleX(0);
      transition: all 150ms ease 0s;
    }

    &:focus-within input {
      outline: none;
    }

    &:focus-within:before {
      visibility: visible;
      transform: scaleX(1);
    }
  }
  .mapboxgl-ctrl-geocoder--input {
    width: 100%;
    font-family: ${props => props.theme.globalConstants.fontFamily.heading};
    font-size: ${props => props.theme.globalConstants.fontSize.body}px;
    color: ${props => props.theme.globalConstants.color.activeBlue};
    padding: ${props => props.theme.globalConstants.padding.input};
    border: none;
    border-bottom: ${props =>
      `${props.theme.globalConstants.border.input} solid ${
        props.theme.globalConstants.color.inactive
      }`};

    &::placeholder {
      color: ${props => props.theme.globalConstants.color.inactive};
    }
    &:disabled {
      cursor: not-allowed;
      background-color: #ebebe4;
    }
  }
  .mapboxgl-ctrl-geocoder--pin-right {
    display: none;
  }

  .suggestions-wrapper {
    position: relative;
  }
  .suggestions {
    background-color: ${props => props.theme.globalConstants.color.primaryBlue};
    color: ${props => props.theme.globalConstants.color.light};
    border: 2px solid ${props => props.theme.globalConstants.color.activeBlue};
    top: -2px;
    margin: 0;
    padding: 0;
    position: absolute;
    width: 100%;
    z-index: 10;

    li {
      list-style: none;
      font-family: ${props => props.theme.globalConstants.fontFamily.heading};
      font-size: ${props => props.theme.globalConstants.fontSize.subtext}px;
      border: 2px solid
        ${props => props.theme.globalConstants.color.primaryBlue};

      &:hover {
        background-color: ${props => props.theme.globalConstants.color.light};
        color: ${props => props.theme.globalConstants.color.activeBlue};
        border: 2px solid
          ${props => props.theme.globalConstants.color.activeBlue};
      }
    }
  }
`;

export default GeocoderInput;
