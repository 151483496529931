import React, { useCallback } from "react";
import styled from "styled-components";
import { Text } from "@hackthenorth/north";

import Map from "components/ComicPage/sections/Travel/components/Map";
import GeocoderInput from "components/GeocoderInput";
import { useFormContext } from "context/form";

const MapWrapper = styled.div`
  display: none;
`;

interface TravelDropdownProps {
  allowEdit: boolean;
}

const TravelDropdown: React.FC<TravelDropdownProps> = ({ allowEdit }) => {
  const {
    user: { location, latitude, longitude },
    setUser
  } = useFormContext();

  const setLocation = useCallback(
    (newLocation: string) =>
      setUser({
        location: newLocation
      }),
    [setUser]
  );

  return (
    <>
      {allowEdit ? (
        <>
          <MapWrapper>
            <Map
              location={location}
              latitude={latitude}
              longitude={longitude}
              onResult={setLocation}
              allowEdit={allowEdit}
            />
          </MapWrapper>
          <GeocoderInput id="geocoder" />
        </>
      ) : (
        <Text variant="reviewBody">{location}</Text>
      )}
    </>
  );
};

export default TravelDropdown;
