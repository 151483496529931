import React, { useCallback } from "react";
import { Text, Dropdown } from "@hackthenorth/north";

import { useFormContext } from "context/form";
import copy from "copy";

interface FieldOfStudyDropdownProps {
  allowEdit: boolean;
  show?: boolean;
}

const degreeOptions = copy.education.degrees.map(optionValue => ({
  value: optionValue
}));

const FieldOfStudyDropdown: React.FC<FieldOfStudyDropdownProps> = ({
  allowEdit
}) => {
  const {
    user: { degree },
    setUser
  } = useFormContext();

  const updateDegree = useCallback(
    (newDegree: string) => {
      setUser({
        degree: newDegree
      });
    },
    [setUser]
  );

  return (
    <>
      {allowEdit ? (
        <Dropdown
          readOnly={!allowEdit}
          options={degreeOptions}
          value={degree}
          placeholder={copy.education.placeholderSecond}
          filterable
          creatable
          onChange={updateDegree}
        />
      ) : (
        <Text variant="reviewBody">{degree}</Text>
      )}
    </>
  );
};

export default FieldOfStudyDropdown;
