import React from "react";
import styled from "styled-components";

import AboutComicSection from "./sections/About";
import EducationComicSection from "./sections/Education";
import TravelComicSection from "./sections/Travel";
import HackathonComicSection from "./sections/Hackathon";

export enum ComicSection {
  ABOUT = "comic-about-section",
  EDUCATION = "comic-education-section",
  TRAVEL = "comic-travel-section",
  HACKATHON = "comic-hackathon-section"
}

export interface ComicPageProps {
  allowEdit?: boolean;
  showPersonalInfo?: boolean;
  curSection?: number;
  setAsActive?: (i: number) => () => void;
  goBackwards?: (i: number) => () => void;
  goForwards?: (i: number) => () => void;
}

export interface ComicSectionProps {
  id: string;
  sectionIndex: number;
  allowEdit: boolean;
  showPersonalInfo?: boolean;
  isActive: boolean;
  setActive: () => void;
  goBackwards: () => void;
  goForwards: () => void;
}

export const NUM_SECTIONS = 4;
export const SECTION_IDS = Object.values(ComicSection);

export const SectionToComponentMap: {
  [section in ComicSection]: React.FC<ComicSectionProps>
} = {
  [ComicSection.ABOUT]: AboutComicSection,
  [ComicSection.EDUCATION]: EducationComicSection,
  [ComicSection.TRAVEL]: TravelComicSection,
  [ComicSection.HACKATHON]: HackathonComicSection
};

const ComicPageContainer = styled.div`
  color: ${props => props.theme.globalConstants.color.primaryBlue};
`;

const ComicPage: React.FC<ComicPageProps> = ({
  allowEdit = true,
  showPersonalInfo = true,
  curSection,
  setAsActive = () => () => {},
  goBackwards = () => () => {},
  goForwards = () => () => {}
}) => {
  return (
    <ComicPageContainer>
      {SECTION_IDS.map((sectionId, i) => {
        const SectionComponent: React.FC<ComicSectionProps> =
          SectionToComponentMap[sectionId];

        return (
          <SectionComponent
            key={sectionId}
            id={sectionId}
            sectionIndex={i}
            allowEdit={allowEdit}
            showPersonalInfo={showPersonalInfo}
            isActive={i === curSection || !allowEdit}
            setActive={setAsActive(i)}
            goBackwards={goBackwards(i)}
            goForwards={goForwards(i)}
          />
        );
      })}
    </ComicPageContainer>
  );
};

export default ComicPage;
