/**
 * Validates if a string is a valid url.
 * Taken from an answer in https://stackoverflow.com/questions/5717093/check-if-a-javascript-string-is-a-url/45567717#45567717
 * @param url String that's being verified as valid or not
 */
export const isValidUrl = (url: string): boolean => {
  // treat naked urls like normal ones
  if (url.indexOf("https://") === -1 && url.indexOf("http://") === -1) {
    url = "http://" + url;
  }

  // fragment locator
  const pattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?" + // port
    "(\\/[-a-z\\d%@_.~+&:]*)*" + // path
    "(\\?[;&a-z\\d%@_.,~+&:=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$",
    "i"
  );

  return pattern.test(url);
};
