/**
 * Given an element, scroll so that the element is
 * centered in the viewport with respect to its height.
 * @param element the element being scrolled to.
 */
export const scrollToCenter = (element: HTMLElement): void => {
  const elementRect = element.getBoundingClientRect();
  const absoluteElementTop = elementRect.top + window.pageYOffset;
  const middle = absoluteElementTop - window.innerHeight / 2;
  const halfElemHeight = element.offsetHeight / 2;
  window.scrollTo(0, middle + halfElemHeight);
};

/**
 * Determines, given two questionId's, which is the most centered in the viewport.
 * Returns:
 *  1. `-1` if element1 is more centered or element2 does not exist.
 *  2. `1` if element2 is more centered or element1 does not exist.
 *  3. `0` if they're equally centered or both elements do not exist.
 * @param elemId1 the id of the first component being compared.
 * @param elemId2 the id of the second question component being compared.
 * @param compare `"x"` if only y position should be taken into account, `"y"` if only x position should be taken into account. If not specified, both will be compared to find the most centered element.
 * @returns -1 | 0 | 1
 */
export const findMostCentered = (
  elemId1: string,
  elemId2: string,
  compare?: "x" | "y"
): -1 | 0 | 1 => {
  const element1 = document.getElementById(elemId1);
  const element2 = document.getElementById(elemId2);

  if (!element1) return 1;
  if (!element2) return -1;

  const {
    top: elem1Top,
    left: elem1Left,
    height: elem1Height,
    width: elem1Width
  } = element1.getBoundingClientRect();
  const {
    top: elem2Top,
    left: elem2Left,
    height: elem2Height,
    width: elem2Width
  } = element2.getBoundingClientRect();

  const windowCenter = {
    x:
      Math.max(document.documentElement.clientWidth, window.innerWidth || 0) /
      2,
    y:
      Math.max(document.documentElement.clientHeight, window.innerHeight || 0) /
      2
  };
  const elem1DistanceFromCenter = {
    x: Math.abs(elem1Left + elem1Width / 2 - windowCenter.x),
    y: Math.abs(elem1Top + elem1Height / 2 - windowCenter.y)
  };
  const elem2DistanceFromCenter = {
    x: Math.abs(elem2Left + elem2Width / 2 - windowCenter.x),
    y: Math.abs(elem2Top + elem2Height / 2 - windowCenter.y)
  };

  switch (compare) {
    case "x":
      if (elem1DistanceFromCenter.x < elem2DistanceFromCenter.x) return -1;
      if (elem1DistanceFromCenter.x > elem2DistanceFromCenter.x) return 1;
      return 0; // distances are equal

    case "y":
      if (elem1DistanceFromCenter.y < elem2DistanceFromCenter.y) return -1;
      if (elem1DistanceFromCenter.y > elem2DistanceFromCenter.y) return 1;
      return 0; // distances are equal
    default:
      /* eslint-disable no-case-declarations */
      const elem1CartesianDistanceFromCenter = Math.sqrt(
        Math.pow(elem1DistanceFromCenter.x, 2) +
          Math.pow(elem1DistanceFromCenter.y, 2)
      );
      const elem2CartesianDistanceFromCenter = Math.sqrt(
        Math.pow(elem2DistanceFromCenter.x, 2) +
          Math.pow(elem2DistanceFromCenter.y, 2)
      );
      /* eslint-enable no-case-declarations */

      if (elem1CartesianDistanceFromCenter < elem2CartesianDistanceFromCenter)
        return -1;
      if (elem1CartesianDistanceFromCenter > elem2CartesianDistanceFromCenter)
        return 1;
      return 0; // distances are equal
  }
};

export const elemIsInViewport = (elemId: string): boolean => {
  const element = document.getElementById(elemId);

  if (!element) return false;

  const rect = element.getBoundingClientRect();

  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const elemIsPartiallyInViewport = (elemId: string): boolean => {
  const element = document.getElementById(elemId);

  if (!element) return false;
  const rect = element.getBoundingClientRect();
  // DOMRect { x: 8, y: 8, width: 100, height: 100, top: 8, right: 108, bottom: 108, left: 8 }
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth;

  // http://stackoverflow.com/questions/325933/determine-whether-two-date-ranges-overlap
  const vertInView = rect.top <= windowHeight && rect.top + rect.height >= 0;
  const horInView = rect.left <= windowWidth && rect.left + rect.width >= 0;

  return vertInView && horInView;
};

/**
 * Finds the amount of an element that's visible in the current viewport,
 * measured in pixels.
 * @param element the DOM element to find visibility for
 */
export const getElemVisiblePx = (element: HTMLElement): number => {
  const viewportHeight = window.innerHeight;
  const elRect = element.getBoundingClientRect();
  const elHeight = elRect.bottom - elRect.top;
  const elVisible = {
    top: elRect.top >= 0 && elRect.top < viewportHeight,
    bottom: elRect.bottom > 0 && elRect.bottom < viewportHeight
  };

  let elVisiblePx = 0;

  if (elVisible.top && elVisible.bottom) {
    // whole element is visible
    elVisiblePx = elHeight;
  } else if (elVisible.top) {
    elVisiblePx = viewportHeight - elRect.top;
  } else if (elVisible.bottom) {
    elVisiblePx = elRect.bottom;
  } else if (elHeight > viewportHeight && elRect.top < 0) {
    var absTop = Math.abs(elRect.top);

    if (absTop < elHeight) {
      // part of the element is visible
      elVisiblePx = elHeight - absTop;
    }
  }

  return elVisiblePx;
};

/**
 *
 * @param elements
 */
export const getMostVisible = (
  elements: HTMLElement[]
): { el: HTMLElement; visiblePx: number } => {
  let maxVisiblePx = 0;
  let maxVisibleEl = elements[0];

  elements.forEach(element => {
    const elementVisiblePx = getElemVisiblePx(element);

    if (elementVisiblePx > maxVisiblePx) {
      maxVisiblePx = elementVisiblePx;
      maxVisibleEl = element;
    } else if (elementVisiblePx === maxVisiblePx) {
      const mostCentered = findMostCentered(element.id, maxVisibleEl.id, "y");
      if (mostCentered === -1) {
        maxVisiblePx = elementVisiblePx;
        maxVisibleEl = element;
      }
    }
  });

  return { el: maxVisibleEl, visiblePx: maxVisiblePx };
};
