import { useState, useCallback, useEffect } from "react";

/**
 * Calculates how far the current viewport has been scrolled on
 * the current document, given as a percentage of the current document.
 * @returns percentage as a number from 0 to 100
 */
export const usePercentScrolled = () => {
  const [percentScrolled, setPercentScrolled] = useState(0);

  const calculatePercentScrolled = useCallback(() => {
    // total height of entire page
    const pageHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    const windowHeight = window.innerHeight; // height of viewport
    const curScrollY = window.scrollY;
    const maxScrollY = pageHeight - windowHeight; // maximum possible vertical scroll distance
    const curPercentScrolled = (curScrollY / maxScrollY) * 100;

    setPercentScrolled(curPercentScrolled);
  }, [setPercentScrolled]);

  /**
   * Recalculates the percentage of the page that's been scrolled whenever you scroll.
   */
  useEffect(() => {
    window.addEventListener("scroll", calculatePercentScrolled);
    window.addEventListener("resize", calculatePercentScrolled);

    return () => {
      window.removeEventListener("scroll", calculatePercentScrolled);
      window.removeEventListener("resize", calculatePercentScrolled);
    };
  }, [calculatePercentScrolled]);

  return percentScrolled;
};
