import React, { useCallback } from "react";
import { Text, Dropdown } from "@hackthenorth/north";

import { useFormContext } from "context/form";
import { EducationLevel } from "interfaces/user";
import copy from "copy";

interface LevelOfStudyDropdownProps {
  allowEdit: boolean;
  isReview?: boolean;
  show?: boolean;
}

const EduLevelNames = {
  [EducationLevel.MIDDLESCHOOL]: "Middle school student",
  [EducationLevel.HIGHSCHOOL]: "High school student",
  [EducationLevel.UNDERGRADUATE]: "Undergrad student",
  [EducationLevel.GRADUATE]: "Graduate student",
  [EducationLevel.OTHER]: "other"
};

const EduLevelNamesReview = {
  [EducationLevel.MIDDLESCHOOL]: "Middle school",
  [EducationLevel.HIGHSCHOOL]: "High school",
  [EducationLevel.UNDERGRADUATE]: "Undergraduate",
  [EducationLevel.GRADUATE]: "Graduate",
  [EducationLevel.OTHER]: "Other"
};

const LevelOfStudyDropdown: React.FC<LevelOfStudyDropdownProps> = ({
  allowEdit,
  isReview,
  show
}) => {
  const {
    user: { educationLevel },
    setUser
  } = useFormContext();

  const updateEduLevel = useCallback(
    (newEduLevel: EducationLevel) =>
      setUser({
        educationLevel: newEduLevel
      }),
    [setUser]
  );

  const onChange = useCallback(
    value =>
      updateEduLevel(Object.keys(EduLevelNames).filter(
        name => EduLevelNames[name] === value
      )[0] as EducationLevel),
    [updateEduLevel]
  );

  return (
    <>
      {allowEdit ? (
        <Dropdown
          className="dotPostfix"
          readOnly={!allowEdit}
          options={Object.values(EducationLevel).map(level => ({
            value: EduLevelNames[level as number]
          }))}
          value={EduLevelNames[educationLevel as string]}
          placeholder={copy.about.placeholderEdu}
          onChange={onChange}
          tabIndex={show ? 0 : -1}
        />
      ) : (
        <Text variant="reviewBody">
          {isReview
            ? EduLevelNamesReview[educationLevel as string]
            : `${EduLevelNames[educationLevel as string]}.`}
        </Text>
      )}
    </>
  );
};

export default LevelOfStudyDropdown;
