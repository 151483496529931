import React from "react";
import styled from "styled-components";

import copy from "copy";
import { Link, Text } from "@hackthenorth/north";

const { pageWrapper } = copy;

const LogoContainer = styled(Link)`
  display: flex;
  align-items: center;

  position: fixed;
  z-index: 101;

  right: ${({ theme }) => theme.globalConstants.border.pageSides + 15}px;
  bottom: 10px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    right: ${theme.globalConstants.border.pageSidesMobile + 5}px;
    bottom: 5px;
  `}
`;

const LogoImage = styled.img`
  height: 25px;
  margin-right: 10px;

  ${props => props.theme.mediaQueries.largeMobile`
    height: 15px;
  `}
`;

const Logo = () => (
  <LogoContainer variant="plain" href={pageWrapper.link} newTab>
    <LogoImage src={pageWrapper.logo} alt="logo" />
    <Text variant="nav">{pageWrapper.brandText}</Text>
  </LogoContainer>
);

export default React.memo(Logo);
