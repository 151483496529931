/**
 * Options for the skills dropdown
 */
const skills = [
  "Android",
  "AR",
  "Backend",
  "Blockchain",
  "Cryptocurrency",
  "Design",
  "Frontend",
  "Hardware",
  "Healthcare",
  "iOS",
  "Internet of Things",
  "Machine Learning",
  "Mobile",
  "Robotics",
  "VR"
];

export default skills;
