import { GlobalConstants } from "@hackthenorth/north";

// See componentStyles.tsx, a lot of the same guidelines
// apply to adding additional globalConstants
const globalConstants: GlobalConstants = {
  color: {
    /* Main Colors */
    primaryBlue: "#183249",
    activeBlue: "#43AFDE",
    light: "#EFF8FA",
    inactive: "#D3D3D3",
    gradientBlue:
      "linear-gradient(90deg, #F8FCFD 0%, rgba(244, 250, 251, 0.5) 53.83%, rgba(239, 248, 250, 0.2) 100%)",

    /* Accent Colors */
    navy: "#373F57",
    grey: "#EFF8FA",
    tan: "#FCE5D0",
    blue: "#84E1FF",
    green: "#85EFC3",
    error: "#D8000C",
    white: "#FFFFFF"
  },
  fontFamily: {
    heading:
      "Castledown, Roboto, Helvetica, BlinkMacSystemFont, -apple-system, sans-serif",
    text:
      "Inter, 'Segoe UI', Arial, BlinkMacSystemFont, -apple-system, sans-serif"
  },
  fontSize: {
    // numbers instead of {n}px to allow us to dynamically resize
    title: 48,
    heading: 32,
    body: 26,
    smallBody: 22,
    subtext: 18,
    hint: 14
  },
  margin: {
    heading: "0 0 20px",
    headingBottom: 32
  },
  padding: {
    button: "10px 20px",
    input: "5px 0"
  },
  border: {
    input: "3px",
    // for PageWrapper
    pageSides: 30,
    pageSidesMobile: 20,
    pageTop: 75,
    pageTopMobile: 60,
    pageBottom: 45,
    pageBottomMobile: 30
  },
  borderRadius: {
    button: "100px"
  },
  opacity: {
    inactive: 0.35
  }
};

export default globalConstants;
