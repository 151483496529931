export enum ApplicationStatus {
  IN_PROGRESS = "in_progress",
  SUBMITTED = "submitted_application",
  WAITLISTED = "waitlisted",
  NOT_ACCEPTED = "not_accepted",
  ACCEPTED = "accepted"
}

export interface AppQuestion {
  title: string;
  desc: string;
  hackerapiField: string;
  type: "shortAnswer" | "longAnswer" | "select" | "multiSelect" | "number";
  optional: boolean;
  allowCustomResponses: boolean;
  options?: string[];
  charLimit?: number;
  allowEdit: boolean;
  isSurvey?: boolean;
  triggerKeys?: string[];

  isCustomResponse?: boolean;
  response: string;
  responses?: string[];
}

export interface Application {
  questions: {
    [questionId: string]: AppQuestion;
  };
  status: ApplicationStatus;
}
