import React from "react";
import styled from "styled-components";
import { UnstyledTextAreaInput, TextAreaInputProps } from "@hackthenorth/north";
import { ApplicationStatus } from "interfaces/application";
import { useFormContext } from "context/form";

export const Wrapper = styled.div`
  display: inline-block;
  position: relative;
  width: 100%;

  &:before {
    content: "";
    position: absolute;

    width: 100%;
    height: ${props => props.theme.globalConstants.border.input};
    bottom: ${props => props.theme.globalConstants.border.input};

    background-color: ${props => props.theme.globalConstants.color.activeBlue};
    visibility: hidden;
    transform: scaleX(0);
    transition: all 150ms ease 0s;
  }

  & textarea {
    width: 100%;

    &:disabled {
      cursor: not-allowed;
    }
  }

  &:focus-within textarea {
    outline: none;
  }

  &:focus-within:before {
    visibility: visible;
    transform: scaleX(1);
  }
`;
const UnderlineTextAreaInput: React.FC<TextAreaInputProps> = (
  props: TextAreaInputProps
) => {
  const {
    application: { status }
  } = useFormContext();
  return (
    <Wrapper>
      <UnstyledTextAreaInput
        disabled={status !== ApplicationStatus.IN_PROGRESS}
        {...props}
      />
    </Wrapper>
  );
};

export default UnderlineTextAreaInput;
