import React, { useMemo } from "react";

import { useScrollTopOnMount } from "utils/hooks/useScrollTopOnMount";
import { withPageWrapper } from "utils/withPageWrapper";
import { withAuth } from "utils/withAuth";
import { useFormContext } from "context/form";
import { ApplicationStatus } from "interfaces/application";
import copy from "copy";

import ContentContainer from "components/ContentContainer";
import FinalComic from "./components/FinalComic";
import Heading from "components/Heading";

const ALLOW_DISPLAY_COMIC_STATUSES = [
  ApplicationStatus.ACCEPTED,
  ApplicationStatus.NOT_ACCEPTED,
  ApplicationStatus.SUBMITTED,
  ApplicationStatus.WAITLISTED
];

const AppStatus: React.FC = () => {
  useScrollTopOnMount();

  const {
    user: { name },
    application: { status }
  } = useFormContext();

  const shouldDisplayComic: boolean = useMemo(
    () => ALLOW_DISPLAY_COMIC_STATUSES.includes(status),
    [status]
  );

  const statusText = useMemo(() => {
    if (status === ApplicationStatus.IN_PROGRESS) {
      return copy.applicationStatus.statuses[ApplicationStatus.IN_PROGRESS];
    } else {
      return copy.applicationStatus.statuses[ApplicationStatus.SUBMITTED];
    }
  }, [status]);

  return (
    <ContentContainer>
      <Heading title={`Hi${name && `, ${name}`}!`} subtitle={statusText} />
      {shouldDisplayComic && <FinalComic />}
    </ContentContainer>
  );
};

export default withAuth(withPageWrapper(AppStatus));
