import React, { useState, useCallback, useMemo } from "react";
import Heading from "components/Heading";

import fullCopy from "copy";
import { Text, Button, TextInput } from "@hackthenorth/north";
import { useFormContext } from "context/form";
import { useSiteContext, AuthPage } from "context/site";
import ActionButtonsContainer from "components/ActionButtonsContainer";
import { FormField, SubtextContainer } from "./components";

const copy = fullCopy.auth.logIn;

const LogIn = () => {
  const { user, setUser, logIn } = useFormContext();
  const { setAuthPage, bypassToLogin } = useSiteContext();
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const onLogInClick = useCallback(
    (e?: React.FormEvent) => {
      if (e) {
        e.preventDefault();
      }
      setLoading(true);
      logIn(password).catch(() => {
        setLoading(false);
        setLoginFailed(true);
      });
    },
    [logIn, password]
  );

  const canSubmit = useMemo(
    () => user.email.trim().length > 0 && password.trim().length > 0,
    [user, password]
  );

  const onEmailChange = useCallback(
    (email: string) => {
      setUser({
        email
      });
    },
    [setUser]
  );

  const goToCreateAccount = useCallback(() => {
    setAuthPage(AuthPage.CREATE_ACCOUNT);
  }, [setAuthPage]);

  const goToForgotPassword = useCallback(() => {
    setAuthPage(AuthPage.FORGOT_PASSWORD);
  }, [setAuthPage]);

  return (
    <form onSubmit={onLogInClick}>
      <Heading title={copy.title} subtitle={copy.subtitle} />
      <FormField>
        <Text variant="heading">{copy.email}</Text>
        <TextInput
          value={user.email}
          onChange={onEmailChange}
          placeholder={copy.emailPlaceholder}
        />
      </FormField>
      <FormField>
        <Text variant="heading">{copy.password}</Text>
        <TextInput
          value={password}
          onChange={setPassword}
          placeholder={copy.passwordPlaceholder}
          type="password"
        />

        <SubtextContainer>
          {loginFailed && (
            <Text variant="subtextError" as="span">
              {copy.error}{" "}
            </Text>
          )}
          <Button variant="link" onClick={goToForgotPassword} type="button">
            {copy.forgotPassword}
          </Button>
        </SubtextContainer>
      </FormField>

      <ActionButtonsContainer>
        <Button
          variant="primary"
          onClick={() => {}}
          disabled={!canSubmit || loading}
        >
          {copy.button}
        </Button>
        <Button variant="link" onClick={goToCreateAccount} type="button">
          {bypassToLogin ? copy.noAccountOrApplication : copy.noAccount}
        </Button>
      </ActionButtonsContainer>
    </form>
  );
};

export default LogIn;
