import React, { useEffect, useCallback } from "react";
import debounce from "lodash.debounce";

import { useSiteContext, AuthPage } from "context/site";
import { useScrollTopOnMount } from "utils/hooks/useScrollTopOnMount";
import {
  AlreadyLoggedIn,
  ResetPassword,
  LogIn,
  ForgotPassword,
  CreateAccount,
  AccountLoading
} from "./pages";

import ContentContainer from "components/ContentContainer";
import { useFormContext } from "context/form";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const { HackerAPI } = window as any;

const Auth = () => {
  useScrollTopOnMount();

  const { authPage, loggedIn, bypassToLogin, setAuthPage } = useSiteContext();
  const {
    user: { email }
  } = useFormContext();

  const checkEmailExists = useCallback(
    debounce((email: string) => {
      if (!bypassToLogin) {
        HackerAPI.Auth.checkEmail(email)
          .then((exists: boolean) => {
            if (exists) {
              setAuthPage(AuthPage.LOG_IN);
            } else {
              setAuthPage(AuthPage.CREATE_ACCOUNT);
            }
          })
          .catch(() => setAuthPage(AuthPage.CREATE_ACCOUNT));
      }
    }, 1000),
    [setAuthPage, bypassToLogin]
  );

  useEffect(() => checkEmailExists(email), [email, checkEmailExists]);

  let section;
  switch (authPage) {
    case AuthPage.LOG_IN:
      section = <LogIn />;
      break;
    case AuthPage.CREATE_ACCOUNT:
      section = <CreateAccount />;
      break;
    case AuthPage.FORGOT_PASSWORD:
      section = <ForgotPassword />;
      break;
    case AuthPage.RESET_PASSWORD:
      section = <ResetPassword />;
      break;
    case AuthPage.LOADING:
      section = <AccountLoading />;
      break;
    default:
      section = null;
      break;
  }

  if (loggedIn) section = <AlreadyLoggedIn />;

  return <ContentContainer>{section}</ContentContainer>;
};

export default Auth;
