import React, { useMemo } from "react";
import styled from "styled-components";
import { Text } from "@hackthenorth/north";

import { useFormContext } from "context/form";
import { useScrollTopOnMount } from "utils/hooks/useScrollTopOnMount";
import { ComicSectionProps } from "components/ComicPage";

import { Dots, HighSchoolImg, UniversityImg, GraduateImg } from "static/img";
import CustomGoose from "components/CustomGoose";
import SpeechBubble from "./components/SpeechBubble";
import { ComicSectionContainer } from "components/ComicPage/components/ComicSectionContainer";
import { EducationLevel } from "interfaces/user";

import NameTextInput from "components/NameTextInput";
import copy from "copy";

/**
 * Represents the steps that a user needs to go through
 * to complete this section.
 */
export enum Step {
  PROVIDE_NAME,
  PROVIDE_OTHER
}

const EduLevelBackgrounds = {
  "": Dots,
  [EducationLevel.MIDDLESCHOOL]: Dots,
  [EducationLevel.HIGHSCHOOL]: HighSchoolImg,
  [EducationLevel.UNDERGRADUATE]: UniversityImg,
  [EducationLevel.GRADUATE]: GraduateImg,
  [EducationLevel.OTHER]: Dots
};

const ComicFrame = styled.div`
  display: grid;
  position: relative;
  grid-template-areas:
    "name other"
    "goose other";

  border: 10px solid ${({ theme }) => theme.globalConstants.color.navy};
  color: ${({ theme }) => theme.globalConstants.color.primaryBlue};

  ${({ theme }) => theme.mediaQueries.medium`
    grid-template-areas:
      "name"
      "goose"
      "other";
  `}

  ${({ theme }) => theme.mediaQueries.largeMobile`
    overflow-x: hidden;
    overflow-y: visible;
  `}
`;

const NameQuestionContainer = styled.div`
  grid-area: name;
  padding: 20px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
      padding: 15px;

  `}
`;

// Wrapper & background for the CustomGoose
const GooseContainer = styled.div<{ shrink: boolean; imgSrc: string }>`
  grid-area: goose;

  position: relative;
  width: 130%;
  z-index: -1;
  background: url(${({ imgSrc }) => imgSrc}) center / cover no-repeat;

  & > div {
    transition: all 250ms;
    transform-origin: left center;
    transform: ${({ shrink }) =>
      shrink ? "translate(10%, 0%) scale(1)" : "translate(60%, 0%) scale(1.3)"};
  }

  ${({ theme, shrink }) => theme.mediaQueries.medium`
    width: 100%;
    height: 120%;

    & > div {
      transform: ${
        shrink
          ? "translate(80%, 10%) scale(-1.1, 1.1)"
          : "translate(80%, 30%) scale(-1.6, 1.6)"
      };
    }
  `}

  ${({ theme, shrink }) => theme.mediaQueries.medium`
    & > div {
      transform: ${
        shrink
          ? "translate(90%, 10%) scale(-1.1, 1.1)"
          : "translate(90%, 30%) scale(-1.6, 1.6)"
      };
    }
  `}

  ${({ theme, shrink }) => theme.mediaQueries.largeMobile`
    & > div {
      transform: ${
        shrink
          ? "translate(90%, 0%) scale(-0.7, 0.7)"
          : "translate(90%, 40%) scale(-0.9, 0.9)"
      };
    }
  `}
`;

const About: React.FC<ComicSectionProps> = ({
  id,
  sectionIndex,
  allowEdit,
  isActive,
  setActive,
  goBackwards,
  goForwards
}) => {
  useScrollTopOnMount();

  const {
    user: { name, email, educationLevel, graduatingYear }
  } = useFormContext();

  // internal value to allow for empty strings to make typescript happy
  const eduLevel = useMemo(() => educationLevel || "", [educationLevel]);

  /**
   * speech bubble containing other inputs should be shown once any text
   * is entered in any input in this section
   */
  const shouldShowBubble = useMemo(
    () =>
      name.trim() !== "" ||
      email.trim() !== "" ||
      (educationLevel || "").trim() !== "" || // possibly null
      graduatingYear.trim() !== "",
    [name, email, educationLevel, graduatingYear]
  );

  return (
    <ComicSectionContainer
      id={id}
      sectionIndex={sectionIndex}
      isActive={isActive}
      onClick={isActive ? () => {} : setActive}
    >
      <ComicFrame>
        <NameQuestionContainer>
          <div className="question">
            <Text variant="body">{copy.about.questionName}</Text>
            <NameTextInput allowEdit={allowEdit} />
          </div>
        </NameQuestionContainer>

        <GooseContainer
          shrink={shouldShowBubble}
          imgSrc={EduLevelBackgrounds[eduLevel]}
        >
          <div>
            <CustomGoose />
          </div>
        </GooseContainer>

        <SpeechBubble
          show={shouldShowBubble}
          allowEdit={allowEdit}
          goForwards={goForwards}
        />
      </ComicFrame>
    </ComicSectionContainer>
  );
};

export default About;
