import React from "react";
import styled from "styled-components";
import { Text } from "@hackthenorth/north";

import fullCopy from "copy";

const copy = fullCopy.auth.loading;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

/**
 * To be used in the Auth page.
 */
const AccountLoading = () => {
  return (
    <LoadingContainer>
      <Text variant="body">{copy.loadingMsg}</Text>
    </LoadingContainer>
  );
};

export default AccountLoading;
