import React from "react";
import styled from "styled-components";
import { Button, ButtonProps } from "@hackthenorth/north";
import { PenEditIconImg } from "static/img";

export interface AttachedButtonStyleProps extends ButtonProps {
  textColour?: string;
  primaryColour?: string;
  attachedPlacement?: "left" | "right";
  attachedText?: string;
}

export const AttachedTextButton = styled(Button)<AttachedButtonStyleProps>`
  color: ${props =>
    props.theme.globalConstants.color[props.textColour || "primaryBlue"]};
  border-radius: 5px;
  background-color: ${props =>
    props.theme.globalConstants.color[props.primaryColour || "activeBlue"]};

  transition: opacity 250ms ease;

  &:disabled {
    background-color: ${props => props.theme.globalConstants.color.inactive};
  }

  &:after {
    position: absolute;
    left: ${props => (props.attachedPlacement === "right" ? "110%" : "unset")};
    right: ${props => (props.attachedPlacement === "left" ? "110%" : "unset")};
    content: "${props => props.attachedText}";
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    opacity: 0.8;
  }
`;

export const AttachedTextInvisButton = styled(Button)<AttachedButtonStyleProps>`
  padding: 0 5px !important;
  color: ${props =>
    props.theme.globalConstants.color[props.primaryColour || "activeBlue"]};

  transition: opacity 250ms ease;
  opacity: 0.7;

  &:after {
    color: ${props =>
      props.theme.globalConstants.color[props.primaryColour || "activeBlue"]};

    position: absolute;
    left: ${props => (props.attachedPlacement === "right" ? "110%" : "unset")};
    right: ${props => (props.attachedPlacement === "left" ? "110%" : "unset")};
    content: "${props => props.attachedText}";
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    opacity: 1;
  }
`;

const EditImg = styled.img`
  height: 1em;
`;

export const EditButton: React.FC<AttachedButtonStyleProps> = ({
  onClick,
  ...rest
}) => (
  <AttachedTextInvisButton
    onClick={onClick}
    attachedText="Edit"
    attachedPlacement="right"
    {...rest}
  >
    <EditImg
      src={PenEditIconImg}
      alt="An icon of a pencil, indicating ability to edit"
    />
  </AttachedTextInvisButton>
);

export const ComicOKButton: React.FC<AttachedButtonStyleProps> = ({
  onClick,
  ...rest
}) => (
  <AttachedTextButton
    onClick={onClick}
    attachedText="press\u00A0ENTER"
    attachedPlacement="right"
    {...rest}
  >
    OK
  </AttachedTextButton>
);
