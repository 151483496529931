/**
 * Context to handle all state operations related to the application form
 *  - Fetches user data from HackerAPI and unpacks them for application sections
 *  - Set all form state
 *  - Log in and out of user's HackerAPI account
 *  - Status flags for submission
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useMemo,
  useEffect
} from "react";
import { applicationTemplate } from "copy";
import Terms from "interfaces/terms";
import {
  ApplicationStatus,
  Application,
  AppQuestion
} from "interfaces/application";
import { User, GooseColor, NestedPartial } from "interfaces/user";
import { isValidUrl } from "utils/isValidUrl";
import { useSiteContext, Stage } from "./site";

// eslint-disable-next-line
const { HackerAPI } = window as any;

export interface FormState {
  user: User;
  application: Application;
  terms: Terms;

  setUser: (user: NestedPartial<User>) => void;
  setApplication: (app: Application) => void;
  setTerm: (term: keyof Terms, agrees: boolean) => void;
  agreesToTerms: () => boolean; // true if the user has agreed to all terms
  createAccount: (password: string) => Promise<any>;
  logIn: (password: string) => Promise<any>;
  logOut: () => void;
  updateApplicationResponse: (
    questionId: string,
    response: string | string[],
    isCustomResponse?: boolean
  ) => void;
  submitApplication: () => Promise<any>;
  saveApplication: () => Promise<any>;
  savePersonalInfo: () => Promise<any>;
  saveApplicationQuestion: (q: AppQuestion) => Promise<any>;
  canSaveComic: boolean;
  canSaveTerms: boolean;
  canSaveExtendedResponses: boolean;
  canSaveApplication: boolean;
  submitted: boolean;
}

export const NUM_QUESTIONS = Object.keys(applicationTemplate.questions).length;
export const NUM_NON_SURVEY_QUESTIONS = Object.values(
  applicationTemplate.questions
).filter(question => !question.isSurvey).length;
export const APP_QUESTION_IDS = Object.keys(applicationTemplate.questions);
export const hasValidResponse = (ques: AppQuestion) =>
  // all mandatory questions answered
  (ques.optional ||
    (ques.type === "multiSelect"
      ? ques.responses // multiSelect should always have responses defined
        ? ques.responses.length > 0 // has 1+ responses
        : false
      : ques.response.length <= (ques.charLimit || Infinity) && // all answers within char limit
        ques.response.trim().length > 0)) &&
  // AND all links are valid links
  (ques.hackerapiField === "links"
    ? ques.responses
      ? ques.responses.every(isValidUrl)
      : ques.optional // if no responses, should still be able to save if optional
    : true);

export const defaultState: FormState = {
  user: {
    name: "",
    email: "",
    graduatingYear: "",
    educationLevel: null,
    school: "",
    degree: "",
    location: "",
    latitude: -80.5416618,
    longitude: 43.4728746,
    numHackathons: null,
    color: GooseColor.MINT,
    accessory: null
  },
  application: applicationTemplate,
  terms: {
    dataForOrganizationalPurposes: false,
    dataForReporting: false,
    consentsToPrivacyPolicy: false
  },

  setUser: () => {},
  setApplication: () => {},
  setTerm: () => {},
  agreesToTerms: () => false,
  createAccount: () => Promise.resolve(),
  logIn: () => Promise.resolve(),
  logOut: () => {},
  updateApplicationResponse: () => {},
  submitApplication: () => Promise.resolve(),
  saveApplication: () => Promise.resolve(),
  savePersonalInfo: () => Promise.resolve(),
  saveApplicationQuestion: () => Promise.resolve(),
  canSaveComic: false,
  canSaveTerms: false,
  canSaveExtendedResponses: false,
  canSaveApplication: false,
  submitted: false
};

export const FormContext: React.Context<FormState> = createContext(
  defaultState
);

export const useFormContext = () => useContext(FormContext);

export const FormProvider: React.FC = ({ children, ...rest }) => {
  const [user, updateUser] = useState(defaultState.user);
  const [application, updateApplication] = useState({
    ...defaultState.application
  });
  const [terms, updateTerms] = useState(defaultState.terms);
  const [applicationLoaded, setApplicationLoaded] = useState(false);
  const {
    onLogIn,
    onLogOut,
    curStage,
    goToStage,
    bypassToLogin,
    setSeenStages
  } = useSiteContext();

  const setUser = (newUserDetails: NestedPartial<User>) =>
    updateUser(prevUserDetails => ({ ...prevUserDetails, ...newUserDetails }));
  const setApplication = (newApplication: Application) =>
    updateApplication(newApplication);

  const updateApplicationResponse = (
    questionId: string,
    response: string | string[],
    isCustomResponse: boolean = false
  ) => {
    let appWithUpdatedResponse = { ...application };
    const questionBeingUpdated = {
      ...appWithUpdatedResponse.questions[questionId]
    };
    if (questionBeingUpdated) {
      if (Array.isArray(response)) {
        questionBeingUpdated.responses = response;
      } else {
        questionBeingUpdated.response = response;
      }
      if (questionBeingUpdated.allowCustomResponses) {
        questionBeingUpdated.isCustomResponse = isCustomResponse;
      }
    }

    appWithUpdatedResponse.questions[questionId] = questionBeingUpdated;

    updateApplication(appWithUpdatedResponse);
  };

  const setTerm: FormState["setTerm"] = (term, agrees) => {
    updateTerms({
      ...terms,
      [term]: agrees
    });
  };

  const agreesToTerms: FormState["agreesToTerms"] = () => {
    return Object.values(terms).every(x => x);
  };

  /**
   * Unpacks an hackerapi response into form state
   */
  const unpackApplication = useCallback((app: any) => {
    updateUser({
      name: app.name || defaultState.user.name,
      email: app.email || defaultState.user.email,
      graduatingYear: app.graduatingYear || defaultState.user.graduatingYear,
      educationLevel: app.levelOfStudy || defaultState.user.educationLevel,
      school: app.school || defaultState.user.school,
      degree: app.major || defaultState.user.degree,
      location: app.travelLocation || defaultState.user.location,
      latitude: app.latitude || defaultState.user.latitude,
      longitude: app.longitude || defaultState.user.longitude,
      numHackathons:
        typeof app.hackathonsAttended === "number"
          ? app.hackathonsAttended
          : defaultState.user.numHackathons,
      color: app.gooseColor || defaultState.user.color,
      accessory: app.gooseAccessory || defaultState.user.accessory
    });
    updateApplication({
      questions: {
        ...defaultState.application.questions,
        hackerType: {
          ...defaultState.application.questions.hackerType,
          response: "",
          responses:
            app.hackerKinds ||
            defaultState.application.questions.hackerType.responses
        },
        hackerLinks: {
          ...defaultState.application.questions.hackerLinks,
          response: "",
          responses:
            app.links ||
            defaultState.application.questions.hackerLinks.responses
        },
        gainHtn: {
          ...defaultState.application.questions.gainHtn,
          response:
            app.gainFromHackTheNorth ||
            defaultState.application.questions.gainHtn.response,
          responses: []
        },
        hardestChallenge: {
          ...defaultState.application.questions.hardestChallenge,
          response:
            app.hardestChallenge ||
            defaultState.application.questions.hardestChallenge.response,
          responses: []
        },
        teamStressful: {
          ...defaultState.application.questions.teamStressful,
          response:
            app.stressfulTeamSituation ||
            defaultState.application.questions.teamStressful.response,
          responses: []
        },
        gender: {
          ...defaultState.application.questions.gender,
          response:
            app.gender || defaultState.application.questions.gender.response,
          responses: []
        },
        ethnicities: {
          ...defaultState.application.questions.ethnicities,
          response: "",
          responses:
            app.ethnicities ||
            defaultState.application.questions.ethnicities.responses
        },
        technologyMostExcitedAbout: {
          ...defaultState.application.questions.technologyMostExcitedAbout,
          response:
            app.technologyMostExcitedAbout ||
            defaultState.application.questions.technologyMostExcitedAbout
              .response,
          responses: []
        },
        hadHighSchoolTechCourses: {
          ...defaultState.application.questions.hadHighSchoolTechCourses,
          response:
            app.hadHighSchoolTechCourses ||
            defaultState.application.questions.hadHighSchoolTechCourses
              .response,
          responses: []
        },
        yearsBuilding: {
          ...defaultState.application.questions.yearsBuilding,
          response:
            app.yearsBuilding ||
            defaultState.application.questions.yearsBuilding.response,
          responses: []
        },
        familyInTech: {
          ...defaultState.application.questions.familyInTech,
          response:
            app.familyInTech ||
            defaultState.application.questions.familyInTech.response,
          responses: []
        },
        firstInFamilyToPursuePostSecondary: {
          ...defaultState.application.questions
            .firstInFamilyToPursuePostSecondary,
          response:
            app.firstInFamilyToPursuePostSecondary ||
            defaultState.application.questions
              .firstInFamilyToPursuePostSecondary.response,
          responses: []
        }
      },
      status: app.stage
    });
    updateTerms({
      dataForOrganizationalPurposes: !!app.dataForOrganizationalPurposes,
      dataForReporting: !!app.dataForReporting,
      consentsToPrivacyPolicy: !!app.consentsToPrivacyPolicy
    });
  }, []);

  const packUser = useCallback(() => {
    return {
      name: user.name,
      email: user.email.trim(),
      graduatingYear: user.graduatingYear,
      levelOfStudy: user.educationLevel,
      school: user.school,
      major: user.degree,
      travelLocation: user.location,
      latitude: user.latitude,
      longitude: user.longitude,
      hackathonsAttended: user.numHackathons
    };
  }, [
    user.degree,
    user.educationLevel,
    user.email,
    user.graduatingYear,
    user.latitude,
    user.location,
    user.longitude,
    user.name,
    user.numHackathons,
    user.school
  ]);

  const packComicSection = useCallback(() => {
    return {
      ...packUser(),
      gooseColor: user.color,
      gooseAccessory: user.accessory
    };
  }, [packUser, user.accessory, user.color]);

  const packLongAnswer = useCallback(() => {
    return {
      name: user.name,
      hackerKinds: (application.questions.hackerType.responses || []).map(x =>
        x.toLowerCase()
      ),
      links: (application.questions.hackerLinks.responses || []).map(x =>
        x.toLowerCase()
      ),
      gainFromHackTheNorth: application.questions.gainHtn.response || undefined,
      hardestChallenge:
        application.questions.hardestChallenge.response || undefined,
      stressfulTeamSituation:
        application.questions.teamStressful.response || undefined,
      gender: application.questions.gender.response || null,
      ethnicities: application.questions.ethnicities.responses || [],
      technologyMostExcitedAbout:
        application.questions.technologyMostExcitedAbout.response || null,
      hadHighSchoolTechCourses:
        application.questions.hadHighSchoolTechCourses.response || null,
      yearsBuilding: application.questions.yearsBuilding.response || null,
      familyInTech: application.questions.familyInTech.response || null,
      firstInFamilyToPursuePostSecondary:
        application.questions.firstInFamilyToPursuePostSecondary.response ||
        null
    };
  }, [
    application.questions.ethnicities.responses,
    application.questions.familyInTech.response,
    application.questions.firstInFamilyToPursuePostSecondary.response,
    application.questions.gainHtn.response,
    application.questions.gender.response,
    application.questions.hackerLinks.responses,
    application.questions.hackerType.responses,
    application.questions.hadHighSchoolTechCourses.response,
    application.questions.hardestChallenge.response,
    application.questions.teamStressful.response,
    application.questions.technologyMostExcitedAbout.response,
    application.questions.yearsBuilding.response,
    user.name
  ]);

  const packTerms = useCallback(() => {
    return {
      name: user.name,
      ...terms
    };
  }, [terms, user.name]);

  const packName = useCallback(
    (hackerapiName?: string) => {
      return {
        name: user.name || hackerapiName
      };
    },
    [user.name]
  );

  const fetchApplication = useCallback(() => {
    return HackerAPI.Event.Application.fetch({ slug: "hackthenorth2019" }).then(
      unpackApplication
    );
  }, [unpackApplication]);

  const createApplication = useCallback(
    (name?: string) => {
      let app = {};
      if (bypassToLogin) {
        app = {
          ...packName(name)
        };
      } else {
        app = {
          ...packComicSection(),
          ...packTerms()
        };
      }
      return HackerAPI.Event.Application.create(
        { slug: "hackthenorth2019" },
        new HackerAPI.Event.Application(app)
      );
    },
    [bypassToLogin, packComicSection, packName, packTerms]
  );

  const saveApplicationQuestion = useCallback(
    (q: AppQuestion) => {
      const question = {
        name: user.name,
        [q.hackerapiField]: q.response
          ? q.response
          : (q.responses || []).map(x => x.toLowerCase())
      };
      return HackerAPI.Event.Application.update(
        { slug: "hackthenorth2019" },
        new HackerAPI.Event.Application(question)
      );
    },
    [user.name]
  );

  const savePersonalInfo = useCallback(() => {
    const updatedUser = packUser();
    return HackerAPI.Event.Application.update(
      { slug: "hackthenorth2019" },
      new HackerAPI.Event.Application(updatedUser)
    );
  }, [packUser]);

  const saveApplication = useCallback(() => {
    let app = {};
    switch (curStage) {
      case Stage.ABOUT:
        app = {
          ...packComicSection()
        };
        break;
      case Stage.ACCOUNT:
        app = {
          ...packTerms()
        };
        break;
      case Stage.APPLICATION:
        app = { ...packLongAnswer() };
        break;
      default:
        app = {
          ...packComicSection(),
          ...packTerms(),
          ...packLongAnswer()
        };
    }

    return HackerAPI.Event.Application.update(
      { slug: "hackthenorth2019" },
      new HackerAPI.Event.Application(app)
    );
  }, [curStage, packComicSection, packLongAnswer, packTerms]);

  const submitApplication = useCallback(() => {
    return saveApplication()
      .then(() =>
        HackerAPI.Event.Application.submit({ slug: "hackthenorth2019" })
      )
      .then(() => {
        updateApplication(prevApp => ({
          ...prevApp,
          status: ApplicationStatus.SUBMITTED
        }));
        goToStage(Stage.STATUS);
      });
  }, [goToStage, saveApplication]);

  /**
   * Fetches the current user's application. If it can't be fetched then it attempts to create one
   */
  const fetchOrCreateApplication = useCallback(
    (name?: string) => {
      return fetchApplication().catch(() => createApplication(name));
    },
    [createApplication, fetchApplication]
  );

  const logIn: FormState["logIn"] = useCallback(
    password => {
      return HackerAPI.Auth.login(user.email.trim(), password).then(
        (user: any) => {
          onLogIn(user.token);
          return fetchOrCreateApplication(user.name).then(() =>
            setApplicationLoaded(true)
          );
        }
      );
    },
    [fetchOrCreateApplication, onLogIn, user.email]
  );

  const createAccount: FormState["createAccount"] = password => {
    return HackerAPI.User.create(
      new HackerAPI.User({ name: user.name, email: user.email.trim() }),
      { password }
    ).then(() => logIn(password));
  };

  const logOut: FormState["logOut"] = useCallback(() => {
    onLogOut();
    // Literally just fucking reload the page
    // We have issues with `application` modifying the default state so we
    // can't reset it without a refactor. In the interest of time, this is a quicker, less obtrusive
    // solution
    window.location.href = "/";
  }, [onLogOut]);

  /**
   * Whether all responses to the profile are filled out.
   */
  const canSaveComic = useMemo(() => {
    return (
      user.name.trim().length > 0 &&
      user.email.trim().length > 0 &&
      user.school.trim().length > 0 &&
      user.graduatingYear !== "" &&
      user.degree.trim().length > 0 &&
      user.location.trim().length > 0 &&
      user.numHackathons !== null
    );
  }, [
    user.degree,
    user.email,
    user.school,
    user.graduatingYear,
    user.location,
    user.name,
    user.numHackathons
  ]);

  const canSaveTerms = useMemo(() => {
    return (
      terms.consentsToPrivacyPolicy &&
      terms.dataForOrganizationalPurposes &&
      terms.dataForReporting
    );
  }, [
    terms.consentsToPrivacyPolicy,
    terms.dataForOrganizationalPurposes,
    terms.dataForReporting
  ]);

  /**
   * Whether all responses to the application questions are filled out
   * correctly, based on if there is a character limit, if they're optional, or
   * if they're valid, etc
   */
  const canSaveExtendedResponses = useMemo(() => {
    return Object.values(application.questions).every(hasValidResponse);
  }, [application]);

  const canSaveApplication = useMemo(() => {
    return canSaveComic && canSaveTerms && canSaveExtendedResponses;
  }, [canSaveComic, canSaveExtendedResponses, canSaveTerms]);

  const submitted = useMemo(() => {
    return application.status !== ApplicationStatus.IN_PROGRESS;
  }, [application.status]);

  useEffect(() => {
    if (applicationLoaded) {
      if (submitted) {
        setSeenStages(
          new Set([
            Stage.LANDING,
            Stage.ABOUT,
            Stage.ACCOUNT,
            Stage.APPLICATION,
            Stage.REVIEW,
            Stage.STATUS
          ])
        );
        goToStage(Stage.STATUS);
      } else if (canSaveApplication || canSaveExtendedResponses) {
        setSeenStages(
          new Set([
            Stage.LANDING,
            Stage.ACCOUNT,
            Stage.ABOUT,
            Stage.APPLICATION,
            Stage.REVIEW
          ])
        );
        goToStage(Stage.REVIEW);
      } else if (canSaveTerms) {
        setSeenStages(
          new Set([
            Stage.LANDING,
            Stage.ABOUT,
            Stage.ACCOUNT,
            Stage.APPLICATION
          ])
        );
        goToStage(Stage.APPLICATION);
      } else if (canSaveComic) {
        setSeenStages(new Set([Stage.LANDING, Stage.ABOUT, Stage.ACCOUNT]));
        goToStage(Stage.ABOUT);
      } else if (bypassToLogin) {
        setSeenStages(new Set([Stage.LANDING]));
        goToStage(Stage.LANDING);
      }
    }
  }, [applicationLoaded]); // eslint-disable-line

  const formState: FormState = {
    user,
    application,
    terms,
    setUser,
    setApplication,
    setTerm,
    agreesToTerms,
    createAccount,
    logIn,
    logOut,
    updateApplicationResponse,
    submitApplication,
    saveApplication,
    savePersonalInfo,
    saveApplicationQuestion,
    canSaveComic,
    canSaveTerms,
    canSaveExtendedResponses,
    canSaveApplication,
    submitted
  };

  return (
    <FormContext.Provider value={formState} {...rest}>
      {children}
    </FormContext.Provider>
  );
};
