import React, { useCallback } from "react";
import { Text, TextInput } from "@hackthenorth/north";

import { useFormContext } from "context/form";
import copy from "copy";

interface EmailTextInputProps {
  allowEdit: boolean;
  isReview?: boolean;
}

const EmailTextInput: React.FC<EmailTextInputProps> = ({
  allowEdit,
  isReview
}) => {
  const {
    user: { email },
    setUser
  } = useFormContext();

  const updateEmail = useCallback(
    (newEmail: string) =>
      setUser({
        email: newEmail
      }),
    [setUser]
  );

  return (
    <>
      {allowEdit ? (
        <TextInput
          readOnly={!allowEdit}
          value={email}
          onChange={updateEmail}
          placeholder={copy.about.placeholderEmail}
        />
      ) : isReview ? (
        <Text variant="reviewBody">{email}</Text>
      ) : null}
    </>
  );
};

export default EmailTextInput;
