import React from "react";
import styled from "styled-components";
import { Text } from "@hackthenorth/north";

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: auto;
  padding: 16px;
  background-color: #d8000c;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const AppsClosedBanner = () => (
  <Wrapper>
    <Text variant="nav">Applications for Hack the North 2019 are closed.</Text>
  </Wrapper>
);
