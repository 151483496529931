import React from "react";
import PageWrapper from "components/PageWrapper";

/**
 * A HOC to wrap your component with the PageWrapper component.
 */
export const withPageWrapper = <T extends {}>(
  Component: React.FC<T>
): React.FC<T> => props => (
  <>
    <Component {...props} />
    <PageWrapper />
  </>
);
