import React, { useCallback } from "react";
import styled from "styled-components";
import { Button } from "@hackthenorth/north";

import { ColorSpeechBubbleColor } from "static/img";

import copy from "copy";
import { useFormContext } from "context/form";
import { GooseColor } from "interfaces/user";
import { SplitViewProps, SelectionProps } from "..";

const noOp = () => {};

const AccessibleButton = styled(Button)<SelectionProps>`
  &:hover,
  &:focus {
    opacity: ${({ selected, readOnly }) => (selected || readOnly ? 1 : 0.5)};
  }

  padding: 0 !important;

  cursor: ${({ readOnly }) => (readOnly ? "default" : "pointer")};
`;

const ColorContainer = styled.div<SplitViewProps>`
  grid-area: color;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  height: 350px;
  margin-top: 10px;
  margin-right: -10px;
  border: 10px solid ${({ theme }) => theme.globalConstants.color.navy};
  background-color: ${({ theme }) => theme.globalConstants.color.navy};

  z-index: 2;

  ${({ theme, showOnMobile }) => theme.mediaQueries.tablet`
    display: ${showOnMobile ? "flex" : "none"};
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-evenly;

    height: 150px;
    padding: 20px 50px 0 50px;
    margin-right: 0;
    margin-bottom: 100px;
  `}

  ${({ theme }) => theme.mediaQueries.largeMobile`
    padding: 20px 20px 0 20px;
  `}

${({ theme }) => theme.mediaQueries.smallMobile`
    padding: 20px 10px 0 10px;
  `}
`;

const Color = styled.div<SelectionProps>`
  width: 60px;
  height: 60px;
  border-radius: 100%;
  background-color: ${props => props.color};
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  user-select: none;

  ${({ color, selected }) =>
    // show striped background for selected
    selected &&
    `
    background-color: transparent;
    background-image: 
      repeating-linear-gradient(45deg, 
        ${color},
        ${color} 10px, 
        ${color}99 10px, 
        ${color}99 20px
      );
    border: 4px solid ${color};
  `}

  ${({ theme }) => theme.mediaQueries.tablet`
    margin: 0 5px;
  `}
`;

const ColourSpeechBubble = styled.img`
  position: absolute;
  top: -100px;
  width: 295px;

  ${({ theme }) => theme.mediaQueries.tablet`
    top: 70px;
  `}
`;

const ColorPicker: React.FC<SplitViewProps> = ({ showOnMobile }) => {
  const { user, setUser, submitted } = useFormContext();

  const changeColor = useCallback(
    (name: GooseColor) => () =>
      setUser({
        color: name
      }),
    [setUser]
  );

  return (
    <ColorContainer showOnMobile={showOnMobile}>
      {copy.pyrg.colors.map(({ name, color }) => (
        <AccessibleButton
          key={name}
          tabIndex={user.color === name ? -1 : 0} // do not allow focus if is currently selected
          selected={user.color === name}
          readOnly={submitted}
          onClick={!submitted ? changeColor(name) : noOp}
        >
          <Color
            selected={user.color === name}
            data-tip={GooseColor[name]}
            data-for="pyrg-tooltip"
            color={color}
          />
        </AccessibleButton>
      ))}
      <ColourSpeechBubble src={ColorSpeechBubbleColor} />
    </ColorContainer>
  );
};

export default ColorPicker;
