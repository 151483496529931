import React, { useCallback, useState } from "react";
import Heading from "components/Heading";

import fullCopy from "copy";
import { Text, Button, TextInput } from "@hackthenorth/north";
import { useFormContext } from "context/form";
import { useSiteContext, AuthPage } from "context/site";
import ActionButtonsContainer from "components/ActionButtonsContainer";
import { FormField } from "./components";

const copy = fullCopy.auth.forgotPassword;

// eslint-disable-next-line
const { HackerAPI } = window as any;

const noop = () => {};

const ForgotPassword = () => {
  const { user, setUser } = useFormContext();
  const { setAuthPage } = useSiteContext();
  const [loading, setLoading] = useState(false);
  const [submitFailed, setSubmitFailed] = useState(false);

  const onEmailChange = useCallback(
    (email: string) => {
      setUser({
        email
      });
    },
    [setUser]
  );

  const goToLogIn = useCallback(() => {
    setAuthPage(AuthPage.LOG_IN);
  }, [setAuthPage]);

  const goToCreateAccount = useCallback(() => {
    setAuthPage(AuthPage.CREATE_ACCOUNT);
  }, [setAuthPage]);

  const sendForgotPasswordEmail = useCallback(
    (e?: React.FormEvent) => {
      if (e) {
        e.preventDefault();
      }
      setLoading(true);
      HackerAPI.Auth.resetPassword(user.email, "", true)
        .then(() => {
          setLoading(false);
          setAuthPage(AuthPage.RESET_PASSWORD);
        })
        .catch(() => {
          setLoading(false);
          setSubmitFailed(true);
        });
    },
    [setAuthPage, user.email]
  );

  return (
    <form onSubmit={sendForgotPasswordEmail}>
      <Heading title={copy.title} subtitle={copy.subtitle} />
      <FormField>
        <Text variant="heading">{copy.email}</Text>
        <TextInput
          value={user.email}
          onChange={onEmailChange}
          placeholder={copy.emailPlaceholder}
        />
      </FormField>
      {submitFailed && <Text variant="subtextError">{copy.error}</Text>}
      <ActionButtonsContainer>
        <Button variant="primary" onClick={noop} disabled={loading}>
          {copy.button}
        </Button>
        <Button variant="link" onClick={goToCreateAccount} type="button">
          {copy.noAccount}
        </Button>
        <Button variant="link" onClick={goToLogIn} type="button">
          {copy.rememberPassword}
        </Button>
      </ActionButtonsContainer>
    </form>
  );
};

export default ForgotPassword;
