import React, { useCallback, useState, useEffect } from "react";
import styled from "styled-components";
import { Text, Button } from "@hackthenorth/north";
import { saveAs } from "file-saver";

import ComicPage from "components/ComicPage";
import { useFormContext } from "context/form";
import { captureDom } from "utils/captureDom";
import copy from "copy";

const COMIC_ID = "final-comic-page";
const COMIC_CONTAINER_ID = "zoom-comic-container";

const Container = styled.div<{ height?: number | null }>`
  position: relative;

  ${({ height }) => typeof height === "number" && `height: ${height * 0.6}px;`}
  transform-origin: top center;
  transform: scale(0.6);

  ${({ theme, height }) => theme.mediaQueries.tablet`
    ${typeof height === "number" && `height: ${height * 0.85}px;`}
    transform: scale(0.85);
  `}

  ${({ theme }) => theme.mediaQueries.largeMobile`
    height: initial;
    transform: scale(1);
  `}
`;

const MobileCutoff = styled.div`
  position: relative;
`;

const ComicContainer = styled.div`
  position: relative;

  background-color: white;
  border: 10px solid black;
  padding: 0 40px;

  box-shadow: 15px 15px 0px 0px rgba(0, 0, 0, 0.75);

  & > h3 {
    margin-top: 40px;
  }

  ${({ theme }) => theme.mediaQueries.tablet`
    padding: 0 30px;
    box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);

    & > h3 {
      margin-top: 30px;
    }
  `}

  ${({ theme }) => theme.mediaQueries.largeMobile`
    padding: 0 15px;
    box-shadow: 10px 10px 0px 0px rgba(0, 0, 0, 0.75);
  `}
`;

const ActionButtonContainer = styled.div`
  display: flex;
  justify-content: center;

  position: relative;
  margin: 50px auto;

  transform-origin: top center;
  transform: scale(${1 / 0.6});

  ${({ theme }) => theme.mediaQueries.tablet`
    margin: 40px auto;

    transform: scale(${1 / 0.85});
  `}

  ${({ theme }) => theme.mediaQueries.largeMobile`
    margin: 30px auto;

    transform: scale(1);
  `}
`;

const FinalComic: React.FC = () => {
  const {
    user: { name }
  } = useFormContext();

  const [isDownloading, setDownloading] = useState(false);
  const [canDownload, setCanDownload] = useState(false);
  const [height, setHeight] = useState<number | null>(null);

  /**
   * Disable downloading on webkit (all iOS, also MacOS Safari)
   * since downloading is bugged for them.
   */
  useEffect(() => {
    if (!navigator.vendor.match(/apple/i)) {
      setCanDownload(true);
    }
  }, [setCanDownload]);

  const downloadComic = useCallback(async () => {
    setDownloading(true);

    let fileName = "My Comic.png";
    if (name) {
      const firstName = name.trim().split(" ")[0];
      fileName = // so much work for a filename
        firstName.slice(-1).toLowerCase() === "s"
          ? `${firstName[0].toUpperCase() + firstName.substr(1)}' Comic.png`
          : `${firstName[0].toUpperCase() + firstName.substr(1)}'s Comic.png`;
    }

    // get the image
    const comicImgUrl = await captureDom(COMIC_ID);

    if (canDownload) {
      saveAs(comicImgUrl, fileName);
    } else {
      alert(copy.afterSubmit.downloadError);
    }

    setDownloading(false);
  }, [canDownload, name]);

  /**
   * Calculate the height after scaling down.
   */
  const calculateActualHeight = useCallback(() => {
    const zoomEl = document.getElementById(COMIC_CONTAINER_ID);
    if (zoomEl) {
      setHeight(zoomEl.offsetHeight);
    }
  }, []);

  /**
   * Calculate height when mounting.
   */
  useEffect(() => {
    if (height === null) calculateActualHeight();
  }, [calculateActualHeight, height]);

  return (
    <Container id={COMIC_CONTAINER_ID} height={height}>
      <MobileCutoff>
        <ComicContainer id={COMIC_ID}>
          <Text variant="heading" as="h3">
            {copy.afterSubmit.comicPageTitle}
          </Text>
          <Text variant="subtext" as="span">
            {copy.afterSubmit.comicPageSubtitle(name)}
          </Text>
          <ComicPage allowEdit={false} />
        </ComicContainer>
      </MobileCutoff>

      {/* <ComicCover darken={isDownloading} /> */}
      <ActionButtonContainer>
        {canDownload ? (
          <Button
            variant="primary"
            onClick={downloadComic}
            disabled={isDownloading}
          >
            {isDownloading ? "Downloading..." : "Download"}
          </Button>
        ) : (
          <Text variant="subtextError">{copy.afterSubmit.downloadError}</Text>
        )}
      </ActionButtonContainer>
    </Container>
  );
};

export default FinalComic;
