import React from "react";
import styled from "styled-components";

export interface ContentContainerProps {
  id?: string;
  className?: string;
  onClick?: () => void;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(
    100vh -
      ${({ theme }) =>
        theme.globalConstants.border.pageTop +
        theme.globalConstants.border.pageBottom}px
  );

  margin: ${({ theme: { globalConstants } }) =>
    `${globalConstants.border.pageTop}px
     ${globalConstants.border.pageSides}px
     ${globalConstants.border.pageBottom}px
     ${globalConstants.border.pageSides}px`};

  background-color: ${({ theme }) => theme.globalConstants.color.white};

  ${({ theme }) => theme.mediaQueries.largeMobile`
    margin:
      ${theme.globalConstants.border.pageTop}px
      ${theme.globalConstants.border.pageSides}px
      ${theme.globalConstants.border.pageBottom}px
      ${theme.globalConstants.border.pageSides}px;
  `}
`;

const Wrapper = styled.div`
  width: 100%;
  max-width: 1000px;

  margin: auto;
  padding: 75px;

  z-index: 2;

  ${({ theme }) => theme.mediaQueries.tablet`
    padding: 35px;
  `}

  ${({ theme }) => theme.mediaQueries.largeMobile`
    padding: 15px;
  `}
`;

const ContentContainer: React.FC<ContentContainerProps> = ({
  children,
  ...rest
}) => (
  <Container {...rest}>
    <Wrapper>{children}</Wrapper>
  </Container>
);

export default ContentContainer;
