import React, { useMemo } from "react";
import styled from "styled-components";
import { Text, Dropdown } from "@hackthenorth/north";

import { useFormContext } from "context/form";
import copy from "copy";

export enum HackathonExperience {
  FIRST,
  SECOND,
  THIRD,
  FOURTH,
  FIFTH,
  SIXTH,
  SEVENTHPLUS
}

interface HackathonDropdownProps {
  allowEdit: boolean;
  isReview?: boolean;
  comic?: boolean;
}

interface DropdownWrapperProps {
  comic?: boolean;
}

const DropdownWrapper = styled.div<DropdownWrapperProps>`
  width: 130px;
  margin-left: ${props => (props.comic ? "10px" : "0")};

  ${({ theme }) => theme.mediaQueries.largeMobile`
    width: 45%;
    text-align: center;

    margin-left: 0;
  `}
`;

const HackathonDropdown: React.FC<HackathonDropdownProps> = ({
  allowEdit,
  isReview,
  comic
}) => {
  const {
    user: { numHackathons },
    setUser
  } = useFormContext();

  const updateExperience = (newValue: string) => {
    // finds the corresponding enum value for the current displayedText value
    const corresExperience = Object.values(copy.hackathon.options).indexOf(
      newValue
    );
    setUser({
      numHackathons: corresExperience
    });
  };

  // text that's displayed to a user
  const displayedText = useMemo(
    () =>
      numHackathons !== null
        ? isReview
          ? copy.hackathon.optionsReview[numHackathons]
          : copy.hackathon.options[numHackathons]
        : "",
    [isReview, numHackathons]
  );

  return (
    <>
      {allowEdit ? (
        <>
          <DropdownWrapper comic={comic}>
            <Dropdown
              readOnly={!allowEdit}
              options={Object.values(copy.hackathon.options).map(answer => ({
                value: answer
              }))}
              value={displayedText}
              placeholder={copy.hackathon.placeholder}
              onChange={updateExperience}
            />
          </DropdownWrapper>
        </>
      ) : comic ? (
        <Text variant="body">{`${copy.hackathon.firstLine} ${displayedText} ${
          copy.hackathon.secondLine
        }`}</Text>
      ) : (
        <Text variant="reviewBody">{displayedText}</Text>
      )}
    </>
  );
};

export default HackathonDropdown;
