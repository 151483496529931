import styled from "styled-components";

interface ComicSectionContainerProps {
  sectionIndex: number;
  isActive: boolean;
}

export const ComicSectionContainer = styled.section<ComicSectionContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: relative;
  width: 100%;
  margin: 50px 0;
  z-index: ${({ isActive, sectionIndex }) =>
    isActive ? 20 - sectionIndex : 5 - sectionIndex};

  transition: all 250ms;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};

  background-color: ${({ theme }) => theme.globalConstants.color.white};

  & > div {
    height: 100%;
    width: 100%;
  }
`;
