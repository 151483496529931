import React, { useCallback } from "react";
import { Button } from "@hackthenorth/north";
import ContentContainer from "components/ContentContainer";
import Term from "./components/Term";
import fullCopy from "copy";
import styled from "styled-components";
import { useFormContext } from "context/form";
import Heading from "components/Heading";
import ActionButtonsContainer from "components/ActionButtonsContainer";
import { useScrollTopOnMount } from "utils/hooks/useScrollTopOnMount";
import { ApplicationStatus } from "interfaces/application";

const copy = fullCopy.terms;

const TermsContainer = styled.div``;

interface TermsProps {
  onContinue: () => void;
}

const Terms: React.FC<TermsProps> = ({ onContinue }) => {
  useScrollTopOnMount();

  const {
    terms: {
      dataForOrganizationalPurposes,
      dataForReporting,
      consentsToPrivacyPolicy
    },
    setTerm,
    application: { status }
  } = useFormContext();

  const onDataForOrganizationalPurposesClick = useCallback(
    (checked: boolean) => {
      setTerm("dataForOrganizationalPurposes", checked);
    },
    [setTerm]
  );

  const onDataForReportingClick = useCallback(
    (checked: boolean) => {
      setTerm("dataForReporting", checked);
    },
    [setTerm]
  );

  const onConsentsToPrivacyPolicyClick = useCallback(
    (checked: boolean) => {
      setTerm("consentsToPrivacyPolicy", checked);
    },
    [setTerm]
  );

  const canProgress =
    dataForOrganizationalPurposes &&
    dataForReporting &&
    consentsToPrivacyPolicy;

  const disableInput = status !== ApplicationStatus.IN_PROGRESS;

  return (
    <ContentContainer>
      <Heading title={copy.title} subtitle={copy.subtitle} />
      <TermsContainer>
        <Term
          {...copy.terms.dataForOrganizationalPurposes}
          onClick={onDataForOrganizationalPurposesClick}
          checked={dataForOrganizationalPurposes}
          id="dataForOrganizationalPurposes"
          disabled={disableInput}
        />
        <Term
          {...copy.terms.dataForReporting}
          onClick={onDataForReportingClick}
          checked={dataForReporting}
          id="dataForReporting"
          disabled={disableInput}
        />
        <Term
          {...copy.terms.consentsToPrivacyPolicy}
          onClick={onConsentsToPrivacyPolicyClick}
          checked={consentsToPrivacyPolicy}
          id="consentsToPrivacyPolicy"
          disabled={disableInput}
        />
      </TermsContainer>
      <ActionButtonsContainer>
        <Button variant="primary" onClick={onContinue} disabled={!canProgress}>
          {copy.button}
        </Button>
      </ActionButtonsContainer>
    </ContentContainer>
  );
};

export default React.memo(Terms);
