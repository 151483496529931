import React from "react";
import { North } from "@hackthenorth/north";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import { SiteProvider, RouteNames, Stage } from "context/site";
import { FormProvider } from "context/form";
import themeDefinition from "theme";

import PYRG from "pages/PYRG";
import Comic from "pages/Comic";
import Account from "pages/Account";
import ExtendedResponses from "pages/ExtendedResponses";
import AppStatus from "pages/AppStatus";
import Review from "pages/Review";

import { AppsClosedBanner } from "components/AppsClosedBanner";

const App: React.FC = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <SiteProvider>
          <FormProvider>
            <North themeDefinition={themeDefinition}>
              <AppsClosedBanner />
              <Switch>
                <Route
                  exact
                  path={RouteNames[Stage.LANDING]}
                  component={PYRG}
                />
                <Route exact path={RouteNames[Stage.ABOUT]} component={Comic} />
                <Route
                  exact
                  path={RouteNames[Stage.ACCOUNT]}
                  component={Account}
                />
                <Route
                  exact
                  path={RouteNames[Stage.APPLICATION]}
                  component={ExtendedResponses}
                />
                <Route
                  exact
                  path={RouteNames[Stage.REVIEW]}
                  component={Review}
                />
                <Route
                  exact
                  path={RouteNames[Stage.STATUS]}
                  component={AppStatus}
                />
                <Route exact path={RouteNames.LOGIN} component={Account} />
              </Switch>
            </North>
          </FormProvider>
        </SiteProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
