/**
 * Source of truth for all copy, assets, etc.
 */
import React from "react";
import styled from "styled-components";
import {
  LogoIconLight,
  HardhatImg,
  BeretImg,
  VerticalLinesBg,
  ScarfImg,
  HeadphonesIcon
} from "static/img";
import { ApplicationStatus, Application } from "interfaces/application";
import { GooseColor, GooseAccessory } from "interfaces/user";
import { Link } from "@hackthenorth/north";
import degrees from "copy/degrees";
import { RouteNames } from "context/site";
import { HackathonExperience } from "components/HackathonDropdown";
import skills from "./skills";

const PrimaryBlueText = styled.span`
  color: ${({ theme }) => theme.globalConstants.color.primaryBlue};
`;

// exporting separately allows the template to be typed properly
// and separates out some of the exports.
export const applicationTemplate: Application = {
  questions: {
    hackerType: {
      title: "What kind of hacker are you?",
      desc: "",
      hackerapiField: "hackerKinds",
      type: "multiSelect",
      optional: false,
      options: ["Software", "Hardware", "Design"],
      response: "",
      responses: [],
      allowCustomResponses: false,
      allowEdit: false
    },

    hackerLinks: {
      title: "Where can we learn more about you?",
      desc:
        "These can be links to your website, Devpost, Github, Behance, Dribbble, LinkedIn, etc",
      hackerapiField: "links",
      type: "multiSelect",
      optional: true,
      options: [],
      response: "",
      responses: [],
      allowCustomResponses: true,
      allowEdit: false,
      triggerKeys: ["Enter", " "]
    },

    gainHtn: {
      title: "What are you looking to gain from coming to Hack the North?",
      desc: "",
      hackerapiField: "gainFromHackTheNorth",
      type: "longAnswer",
      optional: false,
      charLimit: 500,
      response: "",
      allowCustomResponses: false,
      allowEdit: false
    },

    hardestChallenge: {
      title: "What is the hardest challenge you’ve taken on?",
      desc: "What approach did you take? What were your takeaways?",
      hackerapiField: "hardestChallenge",
      type: "longAnswer",
      optional: false,
      charLimit: 1500,
      response: "",
      allowCustomResponses: false,
      allowEdit: false
    },

    teamStressful: {
      title:
        "Tell us about a stressful situation you’ve experienced with a team.",
      desc: "How did the team handle it?",
      hackerapiField: "stressfulTeamSituation",
      type: "longAnswer",
      optional: false,
      charLimit: 1000,
      response: "",
      allowCustomResponses: false,
      allowEdit: false
    },
    // Survey
    gender: {
      title: "What is your gender?",
      desc: "",
      type: "select",
      optional: true,
      isSurvey: true,
      options: [
        "Male",
        "Female",
        "Non-binary, genderfluid, or two-spirited",
        "Prefer not to specify"
      ],
      response: "",
      allowCustomResponses: true,
      allowEdit: false,
      hackerapiField: "gender"
    },
    ethnicities: {
      title: "What are your ethnicities?",
      desc: "",
      options: [
        "African",
        "Carribbean",
        "Central Asian",
        "East Asian",
        "European/Caucasian",
        "Latin, Central and South American",
        "Middle Eastern",
        "North American Indigenous",
        "Oceanic",
        "South Asian",
        "Southeast Asian"
      ],
      response: "",
      responses: [],
      type: "multiSelect",
      allowCustomResponses: false,
      optional: true,
      isSurvey: true,
      allowEdit: false,
      hackerapiField: "ethnicities"
    },
    technologyMostExcitedAbout: {
      title: "What technology are you most excited about?",
      desc: "",
      response: "",
      responses: [],
      type: "select",
      optional: true,
      isSurvey: true,
      options: skills,
      allowEdit: false,
      allowCustomResponses: false,
      hackerapiField: "technologyMostExcitedAbout"
    },
    hadHighSchoolTechCourses: {
      title: "Were technical courses available at your high school?",
      desc: "",
      response: "",
      type: "select",
      optional: true,
      isSurvey: true,
      options: ["Yes", "No"],
      allowEdit: false,
      allowCustomResponses: false,
      hackerapiField: "hadHighSchoolTechCourses"
    },
    yearsBuilding: {
      title: "How many years have you been building or designing?",
      desc: "",
      response: "",
      type: "number",
      optional: true,
      isSurvey: true,
      allowEdit: false,
      allowCustomResponses: true,
      hackerapiField: "yearsBuilding"
    },
    familyInTech: {
      title:
        "Do you have any direct family members that have worked or studied in a field of technology?",
      desc: "",
      response: "",
      type: "select",
      optional: true,
      isSurvey: true,
      allowEdit: false,
      allowCustomResponses: false,
      options: ["Yes", "No"],
      hackerapiField: "familyInTech"
    },
    firstInFamilyToPursuePostSecondary: {
      title:
        "Are you, or will you be, the first in your family to pursue post-secondary education?",
      desc: "",
      response: "",
      type: "select",
      optional: true,
      isSurvey: true,
      allowEdit: false,
      allowCustomResponses: false,
      options: ["Yes", "No"],
      hackerapiField: "firstInFamilyToPursuePostSecondary"
    }
  },
  status: ApplicationStatus.IN_PROGRESS
};

export default {
  pageWrapper: {
    logo: LogoIconLight,
    brandText: "#YourHackTheNorth",
    link: "https://hackthenorth.com/",
    logIn: "Log in"
  },
  navBar: {
    login: "Have an account?"
  },
  pyrg: {
    greeting: (name: string) =>
      `${name ? `${name}'s` : "My"} Hack the North 2019 Application`,
    hashtag: "#YourHackTheNorth",
    tipLoggedOut: (
      <>
        Already have an account or started an application?{" "}
        <Link variant="animUnderline" href={RouteNames.LOGIN}>
          Log in to continue your story
        </Link>
      </>
    ),
    tipLoggedIn: (
      <>
        Want to create an amazing application?{" "}
        <Link
          variant="animUnderline"
          href="https://medium.com/@hackthenorth/hacking-the-hackathon-application-41028c4989a0"
        >
          Check out our article
        </Link>{" "}
        for some tips!
      </>
    ),
    cta: "Start your story",
    accessories: [
      { name: GooseAccessory.HARDHAT, img: HardhatImg },
      { name: GooseAccessory.BERET, img: BeretImg },
      { name: GooseAccessory.HEADPHONES, img: HeadphonesIcon },
      { name: GooseAccessory.SCARF, img: ScarfImg }
    ],
    colors: [
      { name: GooseColor.MINT, color: "#EFF8FA" },
      { name: GooseColor.BEIGE, color: "#FCE5D0" },
      { name: GooseColor.BLUE, color: "#84E1FF" },
      { name: GooseColor.GREEN, color: "#85EFC3" }
    ]
  },
  comicSection: {
    title: "My Hack the North 2019 story",
    errorUnanswered: "Make sure to fill out the entire comic!"
  },
  about: {
    questionName: "Hi! My name is",
    placeholderName: "full name",
    questionEmail: "My email is",
    placeholderEmail: "primary email",
    questionEdu: "and I'm currently a(n)",
    questionEduNoEdit: "I'm currently a(n)",
    placeholderEdu: "study level",
    questionGrad: "I'll be graduating in",
    placeholderGrad: "graduating year"
  },
  education: {
    firstQuestion: "I'm attending",
    placeholderFirst: "school/institution",
    secondQuestion: "and I'm studying",
    placeholderSecond: "field of education",
    schools: [
      "University of Toronto",
      "University of Waterloo",
      "University of British Columbia",
      "Carleton University",
      "McGill University",
      "McMaster University",
      "University of Ottawa",
      "Western University"
    ],
    degrees
  },
  travel: {
    heading: "I'll be travelling from",
    background: VerticalLinesBg
  },
  hackathon: {
    firstLine: "Oh! This is my",
    placeholder: "first",
    secondLine: "time going to a hackathon.",
    options: {
      [HackathonExperience.FIRST]: "first",
      [HackathonExperience.SECOND]: "second",
      [HackathonExperience.THIRD]: "third",
      [HackathonExperience.FOURTH]: "fourth",
      [HackathonExperience.FIFTH]: "fifth",
      [HackathonExperience.SIXTH]: "sixth",
      [HackathonExperience.SEVENTHPLUS]: "seventh+"
    },
    optionsReview: {
      [HackathonExperience.FIRST]: "None",
      [HackathonExperience.SECOND]: "One",
      [HackathonExperience.THIRD]: "Two",
      [HackathonExperience.FOURTH]: "Three",
      [HackathonExperience.FIFTH]: "Four",
      [HackathonExperience.SIXTH]: "Five",
      [HackathonExperience.SEVENTHPLUS]: "Six or more"
    },
    hint:
      "That’s great! In fact, 30% of our hackers were newcomers in previous years."
  },
  extendedQuestions: {
    cta: "Review your application",
    skipButton: "Skip this question",
    errorUnanswered: "Please make sure that all responses are valid.",
    subheading:
      "Let's finish up your application by answering a few more questions. If you want to make changes later, make sure to get to the Review stage.",
    subheadingSubmitted:
      "Looks like you've already submitted your application.",
    subtextUnanswered: "A response to this question is required.",
    subtextInvalidLink: "Please make sure all answers are valid links.",
    surveyHeading: "Survey questions",
    surveySubheading: (
      <>
        We want to understand who our hackers are so that we do our best to help
        them succeed at Hack the North. The following survey questions are only
        used for aggregate reporting and are completely optional; they will{" "}
        <span style={{ fontStyle: "italic" }}>not</span> have any effect on your
        application.
      </>
    )
  },
  applicationStatus: {
    statuses: {
      // copy is WIP
      [ApplicationStatus.IN_PROGRESS]: (
        <>
          Your application has not been submitted yet. Please head to the{" "}
          <PrimaryBlueText>Review</PrimaryBlueText> page to submit it.
        </>
      ),
      [ApplicationStatus.SUBMITTED]: (
        <>
          Your application has been submitted! In the meantime, share your comic
          below and stay updated by following us on{" "}
          <Link
            variant="animUnderline"
            href="https://www.facebook.com/hackthenorth/"
          >
            social media
          </Link>{" "}
          😁
        </>
      ),
      [ApplicationStatus.NOT_ACCEPTED]:
        "Unfortunately, we were not able to offer you a spot in Hack the North this year.",
      [ApplicationStatus.WAITLISTED]: "You have been waitlisted.",
      [ApplicationStatus.ACCEPTED]: "Congratulations! You have been accepted!"
    }
  },
  terms: {
    title: "Terms and agreements",
    subtitle:
      "Before creating an account, please carefully review our terms and conditions.",
    button: "Continue",
    terms: {
      dataForOrganizationalPurposes: {
        text:
          "We require your information to organize an amazing hackathon. This includes using your information for admissions, travel planning, hardware requests, dietary restrictions, etc.",
        label: "I consent to providing my data for event organization purposes"
      },
      dataForReporting: {
        text:
          "We also periodically compile and release aggregate information about our hackers for marketing, sponsorship, and logistical purposes. Any aggregate information released outside of our organization will always be anonymized for privacy and security.",
        label: "I consent to having my data be part of aggregate data reporting"
      },
      consentsToPrivacyPolicy: {
        text: (
          <>
            Finally, we take your privacy and security seriously. We have a{" "}
            <Link
              href="https://hackthenorth.com/privacy"
              newTab
              variant="plain"
            >
              privacy policy
            </Link>{" "}
            that outlines how we collect, store, and use your data in a safe and
            responsible manner.
          </>
        ),
        label: "I consent to the Hack the North privacy policy"
      }
    }
  },
  auth: {
    alreadyLoggedIn: {
      subtitle:
        "You're already logged in. You can continue your application by navigating to the Application page!",
      cta: "Go to Application",
      logOut: "Log out"
    },
    createAccount: {
      title: "Create an account",
      subtitle:
        "You’ve just constructed your own comic! Now, create an account so that you can come back anytime to complete your application.",
      password: "Password",
      passwordPlaceholder: "Please enter a password",
      confirmPassword: "Confirm password",
      confirmPasswordPlaceholder: "Please confirm your password",
      email: "Email",
      emailPlaceholder: "Please enter your email",
      button: "Create account",
      haveAccount: "Have an account?",
      accountCreationError:
        "Unable to create account. Please make sure your information is correct and try again.",
      pwLengthError: "Your password must be 8 or more characters in length.",
      pwMatchingError: "Your password and password confirmation do not match."
    },
    logIn: {
      title: "Log in to your account",
      subtitle: "Please log in to your existing account to continue.",
      email: "Email",
      emailPlaceholder: "Please enter your email",
      password: "Password",
      passwordPlaceholder: "Please enter your password",
      button: "Log in",
      noAccount: "Don't have an account?",
      noAccountOrApplication: "No account? Start a new application!",
      forgotPassword: "Forgot your password?",
      error:
        "Unable to login. Please make sure your information is correct and try again."
    },
    forgotPassword: {
      title: "Forgot password",
      subtitle:
        "We'll need the email of the account you're trying to access to reset your password.",
      email: "Email",
      emailPlaceholder: "Please enter your email",
      noAccount: "Don't have an account?",
      rememberPassword: "Remember your password?",
      button: "Reset password",
      error:
        "An error occurred. Please make sure your email is correct and an account exists under that email."
    },
    resetPassword: {
      title: "Reset password",
      subtitle:
        "We've sent a token to the email you provided. Please check your inbox.",
      password: "Password",
      passwordPlaceholder: "Please enter a password",
      confirmPassword: "Confirm password",
      confirmPasswordPlaceholder: "Please confirm your password",
      authToken: "Reset Token",
      authTokenPlaceholder: "Please enter your token",
      button: "Reset password",
      error:
        "Unable to reset password. Please make sure your reset token is correct."
    },
    loading: { loadingMsg: "Loading..." }
  },
  review: {
    heading: "Review application",
    description:
      "Your responses are now saved. Please review your application before submitting. You can also submit at a later time by returning to this page.",
    personalInfo: {
      heading: "Personal information",
      name: "Name",
      email: "Email",
      levelOfStudy: "Level of study",
      gradYear: "Expected graduating year",
      currSchool: "Current school",
      fieldOfStudy: "Field of study",
      location: "Travelling location",
      prevExperience: "Number of prior hackathons"
    },
    questionHeading: "Question ",
    submit: "Submit application"
  },
  afterSubmit: {
    comicPageTitle: "My Hack the North 2019 Application",
    comicPageSubtitle: (name: string) => `A story by ${name}`.toUpperCase(),
    downloadError:
      "Unfortunately, sharing this comic is not supported in this browser."
  }
};
