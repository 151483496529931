/**
 * Options for the degrees dropdown
 */
const degrees = [
  "Aboriginal Studies",
  "Accounting",
  "Accounting and Financial Management",
  "Actuarial Science",
  "Acupuncture",
  "Administrative Assistant",
  "Advertising and Marketing",
  "African Studies",
  "Agriculture",
  "Ancient History",
  "Animation",
  "Anthropology",
  "Applied Mathematics",
  "Applied Statistics",
  "Archaeology",
  "Architecture",
  "Art History",
  "Art Therapy",
  "Arts Management",
  "Asian American Studies",
  "Asian Canadian Studies",
  "Asian Geographies",
  "Asian Literatures and Cultures",
  "Astronomical Science",
  "Astronomy",
  "Astronomy and Astrophysics",
  "Astronomy and Physics",
  "Athletic Training",
  "Audio and Video Production",
  "Audiology and Speech Pathology",
  "Automotive Engineering",
  "Aviation",
  "Baking And Pastry",
  "Behavioral Science",
  "Biochemistry",
  "Biodiversity and Conservation Biology",
  "Bioengineering",
  "Bioethics",
  "Bioinformatics",
  "Bioinformatics and Computational Biology",
  "Biological Chemistry",
  "Biological Physics",
  "Biology",
  "Biology for Health Sciences",
  "Biomedical Communications",
  "Biomedical Engineering",
  "Biomedical Physics",
  "Biomedical Science",
  "Biomedical Toxicology",
  "Biophysics",
  "Biotechnology",
  "Biotechnology/Chartered Professional Accountancy",
  "Biotechnology/Economics",
  "Book and Media Studies",
  "Botany",
  "Business Administration",
  "Business Administration and Computer Science Double Degree",
  "Business Administration and Mathematics Double Degree",
  "Canadian Studies",
  "Caribbean Studies",
  "Cell and Molecular Biology",
  "Chemical Engineering",
  "Chemical Physics",
  "Chemical and Biomolecular Engineering",
  "Chemistry",
  "Child Care",
  "Child Development",
  "Chiropractic",
  "Christian Counseling",
  "Christianity and Culture",
  "Cinema Studies",
  "Cinematography And Film",
  "City Studies",
  "Civil Engineering",
  "Classical Civilization",
  "Classical Studies",
  "Clinical Psychology",
  "Cognitive Science",
  "Combinatorics and Optimization",
  "Commerce",
  "Communication",
  "Comparative Literature",
  "Comparative Physiology",
  "Computational Biology",
  "Computational Mathematics",
  "Computational Neuroscience",
  "Computer Engineering",
  "Computer Graphics",
  "Computer Networking",
  "Computer Programming",
  "Computer Science",
  "Computer and Cognitive Science",
  "Computing and Financial Management",
  "Conditional Admission to Pharmacy (CAP)",
  "Conservation and Biodiversity",
  "Construction Management",
  "Cosmetology",
  "Counseling Psychology",
  "Creative Writing",
  "Criminal Justice",
  "Criminology",
  "Critical Writing Program",
  "Croatian and Serbian Studies",
  "Culinary Arts",
  "Dance",
  "Dental Assistant",
  "Dental Hygienist",
  "Dental Medicine",
  "Dentistry",
  "Developmental And Child Psychology",
  "Developmental Biology",
  "Dietetics",
  "Digital Media Design",
  "Drama",
  "Early Childhood Education",
  "Earth Sciences",
  "East Asian Languages and Civilization",
  "East Asian Studies",
  "Economics",
  "Educational Leadership and Administration",
  "Electrical Engineering",
  "Elementary Education",
  "Engineering Management",
  "English",
  "English Language Programs",
  "Environment and Business",
  "Environment and Resource Studies",
  "Environmental Engineering",
  "Environmental Health",
  "Environmental Science",
  "Environmental Studies",
  "Environmental and Wildlife Management",
  "Equine Studies",
  "Esthetician",
  "European Studies",
  "European Union Studies",
  "Exceptionality in Human Learning",
  "Exercise Physiology",
  "Fashion Design",
  "Fashion Merchandising",
  "Finance",
  "Finance and Economics",
  "Financial Economics",
  "Fine Arts",
  "Finnish Studies",
  "Fire Science",
  "Food Science",
  "Forensic Psychology",
  "Forensic Science",
  "Forest Biomaterials Science",
  "Forestry",
  "Francophone Studies",
  "French",
  "French Studies",
  "French and Italian",
  "Game Design",
  "General Physics",
  "General Studies",
  "Genome Biology",
  "Geocomputational Science",
  "Geographic Information Systems",
  "Geographical Information Systems",
  "Geography",
  "Geography and Aviation",
  "Geography and Environmental Management",
  "Geological Engineering",
  "Geology",
  "Geomatics",
  "Geoscience",
  "German Studies",
  "German and Ukrainian",
  "Germanic Languages and Literatures",
  "German",
  "Global Asia Studies",
  "Global Business and Digital Arts",
  "Global Health",
  "Graphic Design",
  "Greek",
  "Guidance Counselor",
  "Health Informatics",
  "Health Promotion",
  "Health Studies",
  "Health and Disease",
  "Health and Societies",
  "Healthcare Administration",
  "Hispanic Studies",
  "History",
  "History and Philosophy oHistory and d Technology",
  "History and Political Science",
  "History of Art",
  "History of Religions",
  "Holistic Health and Nutrition",
  "Honours Arts and Business",
  "Honours Arts",
  "Hotel and Hospitality Management",
  "Human Biology",
  "Human Geography",
  "Human Resources",
  "Human Services",
  "Hungarian Studies",
  "Illustration",
  "Immunology",
  "Independent Studies",
  "Industrial Design",
  "IndustriaIndustriaInng",
  "Information Security",
  "Information Systems",
  "Information Technology",
  "Information Technology Management",
  "Integrative Biology",
  "Interactive Digital Media",
  "Interior Design",
  "International Affairs",
  "International Business",
  "International Development Studies",
  "International Relations",
  "International Studies and Business",
  "Islamic Studies",
  "Italian",
  "Italian Culture and Communication Studies",
  "Italian Studies",
  "Kinesiology",
  "Knowledge Integration",
  "Language Certificate Program",
  "Language Teaching and Learning: French and Italian",
  "Latin",
  "Latin American StLatin Ameri American and Latino Studies",
  "Lauder Institute of Management",
  "Law",
  "Law and Justice Administration",
  "Legal Studies",
  "Legal Studies and History",
  "Liberal Arts",
  "Liberal Studies",
  "Life Physics",
  "Life Sciences",
  "Linguistics",
  "Literary Studies",
  "Literature and Film Studies",
  "Logistics and Supply Chain Management",
  "Makeup Artist",
  "Management",
  "Management Engineering",
  "Management and AccountiManagement an and Finance",
  "Management and Human Resources",
  "Management and Information Technology",
  "Management and International Business",
  "Management and Marketing",
  "Management and Technology",
  "Managing Electronic Commerce",
  "Marine Biology",
  "Marriage and Family Therapy",
  "Massage Therapy",
  "Material Culture",
  "Materials Engineering",
  "Materials Science",
  "Materials Science and Engineering",
  "Mathematical Finance",
  "Mathematical Physics",
  "Mathematical Sciences",
  "Mathematical Studies",
  "Mathematical Optimization",
  "Mathematics",
  "Mathematics and Philosophy",
  "Mathematics and Physics",
  "Mathematics/Business Administration",
  "Mathematics/Chartered Professional Accountancy",
  "Mathematics/Financial Analysis and Risk Management",
  "Mathematics/Teaching",
  "Mechanical Engineering",
  "Mechatronics Engineering",
  "Media Studies",
  "Medical Assistant",
  "Medical Radiation Sciences",
  "Medical Transcription",
  "Medicinal Chemistry",
  "Medicine",
  "Medieval Studies",
  "Mental Health Counseling",
  "Mental Health Studies",
  "Microbiology",
  "Ministry",
  "Modern Languages Program",
  "Modern Middle Eastern Studies",
  "Molecular Biology",
  "Molecular Biology and Biotechnology",
  "Molecular Genetics and Microbiology",
  "Museum Studies",
  "ics and  Culture",
  "Music Management",
  "Music Therapy",
  "Music and Culture",
  "Music in Performance",
  "Nanoscience",
  "Nanotechnology Engineering",
  "Neuroscience",
  "Nursing",
  "Nursing and Health Care Management",
  "Nutritional Sciences",
  "Optometry",
  "Organizational Psychology",
  "Organizational and Nonprofit Management",
  "Paralegal",
  "Parks and Recreation Management",
  "Peace and Conflict Studies",
  "Petroleum Engineering",
  "Pharmaceutical Chemistry",
  "Pharmacology",
  "Pharmacology and Biomedical Toxicology",
  "Pharmacy",
  "Philosophy",
  "Philosophy and Physics",
  "Philosophy of Science",
  "Photojournalism",
  "Physical Education",
  "Physical Education and Health",
  "Physical Sciences",
  "Physician Assistant",
  "Physics",
  "Physics and Astronomy",
  "Physiology",
  "Planetary Science",
  "Planning",
  "Polish Language and Literature",
  "Polish Studies",
  "Political Science",
  "Prehistoric Archeology",
  "Professional Writing and Communication",
  "Property Management",
  "Psycholinguistics",
  "Psychology",
  "Public Administration",
  "Public Health",
  "PublicPublicPublicPublelations",
  "Pure Mathematics",
  "Radio And Television Broadcasting",
  "Recreation and Leisure Studies",
  "Religious Studies",
  "Renaissance Studies",
  "Respiratory Therapy",
  "Risk Management and Insurance",
  "Russian and East European Studies",
  "Science and Aviation",
  "Science and Business",
  "Semiotics and Communication Theory",
  "Sexual Diversity Studies",
  "Sexuality, Marriage, and Family Studies",
  "Sign Language",
  "Slavic Languages & Literatures",
  "Social Development Studies",
  "Social Work",
  "Sociology",
  "Software Engineering",
  "Spanish",
  "Speech Communication",
  "Sports Management",
  "Sports Medicine",
  "Statistics",
  "Statistics and Mathematics",
  "Statistics for Health Statistics Management",
  "Structural Engineering",
  "Substance Abuse and Addiction Counseling",
  "Surgical Technologist",
  "Sustainable Energy",
  "Synthetic and Catalytic Chemistry",
  "Systems Design Engineering",
  "Systems Engineering",
  "Systems Science and Engineering",
  "Teacher Education Program",
  "Theatre Arts",
  "Theatre and Performance Studies",
  "Therapeutic Recreation",
  "Tourism and Parks Management",
  "Urban Education Program",
  "Urban Planning",
  "Urban Studies",
  "Visual Culture and Communication",
  "Visual Studies",
  "Web Design",
  "Wildlife Biology",
  "Women and Gender Studies",
  "Women's Studies",
  "Writing and Rhetoric",
  "Zoology"
];

export default degrees;
