import React, { useState, useCallback, useEffect } from "react";
import styled from "styled-components";
import { Text, Button } from "@hackthenorth/north";
import debounce from "lodash.debounce";

import { scrollToCenter, getMostVisible } from "utils/viewportHelpers";
import { useScrollTopOnMount } from "utils/hooks/useScrollTopOnMount";
import { withPageWrapper } from "utils/withPageWrapper";
import { withAuth } from "utils/withAuth";
import {
  useFormContext,
  NUM_QUESTIONS,
  NUM_NON_SURVEY_QUESTIONS,
  APP_QUESTION_IDS
} from "context/form";
import { useSiteContext } from "context/site";
import copy from "copy";
import ContentContainer from "components/ContentContainer";
import Heading from "components/Heading";

import { buildQuestionElementId } from "./utils/build-question-id";
import Question from "./Question";

const SubmitContainer = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  z-index: 1;

  & > button {
    margin-right: 15px;
  }

  ${props => props.theme.mediaQueries.largeMobile`
    flex-direction: column-reverse;
    text-align: center;

    & > button {
      margin-right: 0;
      margin-top: 15px;
    }
  `}
`;

/**
 * ------------------ **EXTENDED RESPONSES PAGE** --------------------
 */
const ExtendedResponses = () => {
  useScrollTopOnMount();

  const [curQuestionIndex, updateCurQuestionIndex] = useState(0);
  const { goToNextStage, loggedIn } = useSiteContext();
  const {
    user: { name },
    application: { questions },
    submitted,
    saveApplication,
    canSaveExtendedResponses
  } = useFormContext();

  const finishAndGoToReview = useCallback(() => {
    if (loggedIn) {
      saveApplication().then(() => goToNextStage());
    } else {
      goToNextStage();
    }
  }, [goToNextStage, loggedIn, saveApplication]);

  /**
   * Sets the question with the provided `questionIndex` to be the
   * active question, and focuses on the main input in that question.
   */
  const updateCurQuestion = useCallback(
    (questionIndex: number) => {
      updateCurQuestionIndex(questionIndex);
    },
    [updateCurQuestionIndex]
  );

  /**
   * Helper functions to navigate to specific questions.
   */
  const goToQuestion = useCallback(
    (questionIndex: number) => {
      const element = document.getElementById(
        buildQuestionElementId(APP_QUESTION_IDS[questionIndex])
      );

      if (element) {
        scrollToCenter(element);
        updateCurQuestion(questionIndex);
      }
    },
    [updateCurQuestion]
  );
  const goPrevQuestion = useCallback(() => {
    const prevQuestionIndex = Math.max(0, curQuestionIndex - 1); // lower bound of 0 since you can't have a negative index
    goToQuestion(prevQuestionIndex);
  }, [curQuestionIndex, goToQuestion]);
  const goNextQuestion = useCallback(() => {
    const nextQuestionIndex = Math.min(NUM_QUESTIONS - 1, curQuestionIndex + 1); // upper bound of the max index in the question array
    goToQuestion(nextQuestionIndex);
  }, [curQuestionIndex, goToQuestion]);

  /**
   * Checks each question to see which one is the most centered.
   */
  const checkCurQuestionCentered = useCallback(
    debounce(() => {
      const elements: HTMLElement[] = [];
      APP_QUESTION_IDS.forEach(id => {
        const element = document.getElementById(buildQuestionElementId(id));
        if (element) {
          elements.push(element);
        }
      });

      // find most centered question of all questions and mark it as active
      const mostCenteredElement = getMostVisible(elements);
      if (mostCenteredElement.el) {
        const mostCenteredId = mostCenteredElement.el.id;
        const mostCenteredIndex = APP_QUESTION_IDS.findIndex(
          id => buildQuestionElementId(id) === mostCenteredId
        );

        // update index internally
        updateCurQuestion(mostCenteredIndex);

        // focus on the input element in the most centered question
        const childInputElements = mostCenteredElement.el
          ? mostCenteredElement.el.getElementsByTagName("input")
          : [];
        const childTextAreaElements = mostCenteredElement.el
          ? mostCenteredElement.el.getElementsByTagName("textarea")
          : [];

        if (childInputElements[0]) {
          (childInputElements[0] as HTMLElement).focus();
        } else if (childTextAreaElements[0]) {
          (childTextAreaElements[0] as HTMLElement).focus();
        }
      }
    }, 50),
    [updateCurQuestion]
  );

  /**
   * On scroll and page load, sets the question centered most in the viewport
   * to be the active question.
   */
  useEffect(() => {
    window.addEventListener("scroll", checkCurQuestionCentered);

    return () => window.removeEventListener("scroll", checkCurQuestionCentered);
  }, [checkCurQuestionCentered]);

  return (
    <ContentContainer>
      <Heading
        title={`Hi${name && `, ${name}`}!`}
        subtitle={
          !submitted
            ? copy.extendedQuestions.subheading
            : copy.extendedQuestions.subheadingSubmitted
        }
      />
      {Object.entries(questions)
        .filter(([, question]) => !question.isSurvey)
        .map(([id, question], i) => (
          <Question
            key={id}
            questionNum={i + 1}
            questionId={id}
            question={question}
            isActive={i === curQuestionIndex}
            isReadOnly={submitted}
            setActive={() => goToQuestion(i)}
            goPrevQuestion={goPrevQuestion}
            goNextQuestion={goNextQuestion}
            isFirst={i === 0}
            isLast={false}
          />
        ))}
      <Heading
        title={copy.extendedQuestions.surveyHeading}
        subtitle={copy.extendedQuestions.surveySubheading}
      />
      {Object.entries(questions)
        .filter(([, question]) => question.isSurvey)
        .map(([id, question], i) => (
          <Question
            key={id}
            questionNum={i + 1 + NUM_NON_SURVEY_QUESTIONS}
            questionId={id}
            question={question}
            isActive={i + NUM_NON_SURVEY_QUESTIONS === curQuestionIndex}
            isReadOnly={submitted}
            setActive={() => goToQuestion(i + NUM_NON_SURVEY_QUESTIONS)}
            goPrevQuestion={goPrevQuestion}
            goNextQuestion={goNextQuestion}
            isFirst={i + NUM_NON_SURVEY_QUESTIONS === 0}
            isLast={i + NUM_NON_SURVEY_QUESTIONS === NUM_QUESTIONS - 1}
            isSurvey
          />
        ))}

      {!submitted && (
        <SubmitContainer>
          <Button
            variant="primary"
            type="button"
            onClick={finishAndGoToReview}
            disabled={!canSaveExtendedResponses}
          >
            {copy.extendedQuestions.cta}
          </Button>
          {!canSaveExtendedResponses && (
            <Text variant="subtextError">
              {copy.extendedQuestions.errorUnanswered}
            </Text>
          )}
        </SubmitContainer>
      )}
    </ContentContainer>
  );
};
export default withAuth(withPageWrapper(ExtendedResponses));
