import React from "react";
import styled from "styled-components";

import { Stage, useSiteContext } from "context/site";
import { usePercentScrolled } from "utils/hooks/usePercentScrolled";

import Section from "./components/Section";

const NAVBAR_SECTIONS: { [stage in Stage]: string } = {
  [Stage.LANDING]: "Produce-a-Goose",
  [Stage.ABOUT]: "My Story",
  [Stage.ACCOUNT]: "Account",
  [Stage.APPLICATION]: "Application",
  [Stage.REVIEW]: "Review",
  [Stage.STATUS]: "Status"
};

const Container = styled.div`
  display: flex;
  flex: 1 1 0;
  flex-direction: row;
  justify-content: space-between;

  position: relative;
  margin: 20px ${({ theme }) => theme.globalConstants.border.pageSides}px;

  ${({ theme }) => theme.mediaQueries.tablet`
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    white-space: nowrap;
    overflow-x: scroll;
  `}

  ${({ theme }) => theme.mediaQueries.largeMobile`
    margin: 15px 0;
  `}
`;

const NavBar: React.FC = () => {
  const { curStage, seenStages, goToStage } = useSiteContext();

  const percentScrolled = usePercentScrolled();

  return (
    <Container>
      {Object.entries(NAVBAR_SECTIONS).map(([stageName, sectionName], i) => {
        const stageWasSeen = seenStages.has(Number(stageName));
        const currentScrollPercent =
          Number(stageName) === curStage ? percentScrolled : null;
        const goToThisStage = () => goToStage(Number(stageName));

        return (
          <Section
            key={sectionName}
            name={sectionName}
            seen={stageWasSeen}
            currentScrollPercent={currentScrollPercent}
            onClick={stageWasSeen ? goToThisStage : () => {}}
          />
        );
      })}
    </Container>
  );
};

export default NavBar;
