import React, { useMemo } from "react";
import styled from "styled-components";
import { Text, ProgressBar } from "@hackthenorth/north";

interface NavBarSectionProps {
  name: string;
  seen?: boolean;
  currentScrollPercent: number | null; // between 0 and 100, null if stage is not current stage
  onClick: () => void;
}

interface ContainerProps {
  isCurrent: boolean;
  seen?: boolean;
  color: "green" | "white" | "inactive";
}

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;

  cursor: ${({ seen }) => (seen ? "pointer" : "not-allowed")};

  position: relative;
  width: 100%;
  margin: 0 10px;

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: 0;
  }

  & > span {
    z-index: 2;

    transition: all 250ms;
    color: ${({ theme, color }) => theme.globalConstants.color[color]};
    margin-bottom: 5px;
    white-space: nowrap;
  }

  & > div {
    z-index: 1;

    transition: all 250ms;
    transform-origin: center bottom;
    background-color: ${({ theme, color }) =>
      color === "green"
        ? `${theme.globalConstants.color[color]}99`
        : theme.globalConstants.color[color]};
    & > progress + div {
      background-color: ${({ theme, color }) =>
        theme.globalConstants.color[color]};
    }

    ${({ theme, color }) =>
      color === "green" &&
      `mask-image: linear-gradient(to right, black, black, transparent)`};
  }

  &:hover > span {
    color: ${({ theme, color, seen }) =>
      color === "green"
        ? seen && theme.globalConstants.color.white
        : seen && theme.globalConstants.color.primaryBlue};
  }

  &:hover > div {
    transform: ${({ seen }) => (seen ? "scaleY(5.3)" : "none")};
  }

  ${({ theme }) => theme.mediaQueries.largeMobile`
    &:first-of-type {
     padding-left: ${theme.globalConstants.border.pageSidesMobile}px;
    }

    &:last-of-type {
      padding-right: ${theme.globalConstants.border.pageSidesMobile}px;
    }
  `}
`;

const Section: React.FC<NavBarSectionProps> = ({
  name,
  seen,
  currentScrollPercent,
  onClick
}) => {
  /**
   * Color of the bar and the text.
   * If seen, it should be white, if it's the current stage,
   * it should be highlighted, otherwise it should be grey.
   */
  const color = useMemo(() => {
    if (seen && currentScrollPercent !== null) {
      return "green";
    } else if (seen) {
      return "white";
    } else {
      return "inactive";
    }
  }, [seen, currentScrollPercent]);

  /**
   * Percent of the progress bar.
   * If it's the current stage, it should reflect the user's
   * scroll position. If seen and not the current stage,
   * it should be full, otherwise it should be empty.
   */
  const percent = useMemo(() => {
    if (currentScrollPercent !== null) {
      return currentScrollPercent;
    } else if (seen) {
      return 100;
    } else {
      return 0;
    }
  }, [seen, currentScrollPercent]);

  return (
    <Container
      color={color}
      seen={seen}
      isCurrent={currentScrollPercent !== null}
      onClick={onClick}
    >
      <Text variant="nav" as="span">
        {name}
      </Text>
      <ProgressBar progress={percent} />
    </Container>
  );
};

export default Section;
