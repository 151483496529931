import React, { useState, useCallback } from "react";
import { Text, Dropdown } from "@hackthenorth/north";
import debounce from "lodash.debounce";

import copy from "copy";
import { useFormContext } from "context/form";
import { School } from "interfaces/user";

const defaultSchoolOptions = copy.education.schools.map(optionValue => ({
  value: optionValue
}));

// eslint-disable-next-line
const { HackerAPI } = window as any;
const fetchSchools = (q: string): Promise<{ value: string }[]> => {
  if (q.length < 3) {
    return Promise.resolve([]);
  }
  return HackerAPI.Search.institutions({ q })
    .then((institutions: { name: string; countryCode: string }[]) =>
      institutions.map(({ name, countryCode }) => ({
        value: `${name} (${countryCode})`
      }))
    )
    .catch(() => defaultSchoolOptions);
};

interface SchoolDropdownProps {
  allowEdit: boolean;
}

const SchoolDropdown: React.FC<SchoolDropdownProps> = ({ allowEdit }) => {
  const {
    user: { school },
    setUser
  } = useFormContext();

  const [schools, setSchools] = useState<{ value: string }[]>([]);

  const fetchAndUpdateSchools = useCallback(
    debounce((s: School) => {
      fetchSchools(s).then(setSchools);
    }, 300),
    []
  );

  const updateSchool = useCallback(
    (newSchool: School) => {
      if (newSchool !== school) {
        fetchAndUpdateSchools(newSchool);
      }

      setUser({
        school: newSchool
      });
    },
    [fetchAndUpdateSchools, school, setUser]
  );

  return (
    <>
      {allowEdit ? (
        <Dropdown
          readOnly={!allowEdit}
          options={schools}
          value={school}
          placeholder={copy.education.placeholderFirst}
          filterable
          creatable
          onChange={updateSchool}
        />
      ) : (
        <Text variant="reviewBody">{school}</Text>
      )}
    </>
  );
};

export default SchoolDropdown;
