import React, { useCallback } from "react";
import { useSiteContext, Stage, RouteNames } from "context/site";
import styled from "styled-components";
import { Link, Text, Button } from "@hackthenorth/north";
import { useFormContext } from "context/form";
import useWindowWidth from "utils/hooks/useWindowWidth";
import copy from "copy";

const Container = styled.div`
  display: flex;
  align-items: center;

  position: fixed;
  z-index: 101;

  left: ${({ theme }) => theme.globalConstants.border.pageSides + 15}px;
  bottom: 10px;

  ${({ theme }) => theme.mediaQueries.largeMobile`
    left: ${theme.globalConstants.border.pageSidesMobile + 5}px;
    bottom: 5px;
  `}
`;
const AccountInfo = () => {
  const { loggedIn, goToStage } = useSiteContext();
  const {
    user: { name, email }
  } = useFormContext();

  const goToAccount = useCallback(() => {
    goToStage(Stage.ACCOUNT);
  }, [goToStage]);

  const width = useWindowWidth();

  if (loggedIn) {
    return (
      <Container>
        <Button onClick={goToAccount} variant="plain">
          <Text variant="nav">
            {name}
            {width > 425 && ` (${email})`}
          </Text>
        </Button>
      </Container>
    );
  } else {
    return (
      <Container>
        <Link variant="plain" href={RouteNames.LOGIN}>
          <Text variant="nav">{copy.navBar.login}</Text>
        </Link>
      </Container>
    );
  }
};

export default AccountInfo;
