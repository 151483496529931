import React, { useMemo, useState } from "react";
import styled from "styled-components";
import { Text, Button } from "@hackthenorth/north";
import ReactVivus from "react-vivus";
import posed, { PoseGroup } from "react-pose";

import { useFormContext } from "context/form";
import { School } from "interfaces/user";
import copy from "copy";
import { ComicSectionProps } from "components/ComicPage";

import CustomGoose from "components/CustomGoose";
import { ComicSectionContainer } from "components/ComicPage/components/ComicSectionContainer";
import SchoolDropdown from "components/SchoolDropdown";
import FieldOfStudyDropdown from "components/FieldOfStudyDropdown";

import {
  EducationBg,
  DottedBg,
  EducationBoom,
  UofTImg,
  CarletonImg,
  WaterlooImg,
  McGillImg,
  McMasterImg,
  UOttawaImg,
  WesternImg,
  UbcImg,
  GenericUniImg,
  UniBgModern,
  UniBgTraditional
} from "static/img";

const BORDER = 10;

const Container = styled.div`
  display: grid;
  font-family: ${props => props.theme.globalConstants.fontFamily.heading};
  color: ${({ theme }) => theme.globalConstants.color.primaryBlue};

  grid-template-areas: "details graphics";
  grid-template-columns: 60% 40%;

  position: relative;

  ${({ theme }) => theme.mediaQueries.tablet`
    width: 100%;
  `}

  ${({ theme }) => theme.mediaQueries.largeMobile`
    grid-template-areas:
      "details"
      "graphics";
    grid-template-columns: auto;
    grid-row-gap: 20px;
    width: auto;
  `}
`;

const DetailsWrapper = styled.div`
  width: 100%;
  height: 500px;
  grid-area: details;
  position: relative;
  z-index: 1;

  &:before {
    content: "";
    grid-area: details;
    display: block;
    position: absolute;
    width: 100%;
    height: 500px;
    background: ${({ theme }) => theme.globalConstants.color.navy};
    box-sizing: border-box;
    clip-path: polygon(0 0, 100% 0%, 88% 100%, 0% 100%);
    z-index: -1;
  }
`;

const SlantedDetailsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 2;

  &:after {
    content: "";
    position: absolute;
    background-color: ${({ theme }) => theme.globalConstants.color.white};
    top: ${BORDER}px;
    left: ${BORDER}px;
    width: calc(97% - ${BORDER}px);
    height: 480px;
    clip-path: polygon(0 0, 100% 0%, 88% 100%, 0% 100%);
    padding: 5px;
    z-index: -1;
  }

  ${({ theme }) => theme.mediaQueries.tablet`
    justify-content: space-between;
    &:before {
      width: 100%;
    }
  `}
`;

const GraphicsWrapper = styled.div`
  grid-area: graphics;
  display: inline-block;
  position: relative;
  width: 100%;
  height: 500px;
  background: ${({ theme }) => theme.globalConstants.color.navy};
  box-sizing: border-box;
  clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);

  ${({ theme }) => theme.mediaQueries.tablet`
    justify-self: end;
  `}
`;

const SlantedGraphicWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  overflow: hidden;
  position: absolute;
  background-color: ${({ theme }) => theme.globalConstants.color.white};
  background-image: url(${DottedBg});
  background-position: 90% 100%;
  top: ${BORDER}px;
  left: ${BORDER}px;
  width: calc(96% - ${BORDER}px);
  height: 480px;
  clip-path: polygon(15% 0, 100% 0%, 100% 100%, 0% 100%);
`;

const BoomWrapper = styled.div<{ showInFrame: boolean }>`
  position: absolute;
  right: ${({ showInFrame }) => (showInFrame ? "-130px" : "-30px")};
  top: -110px;
  z-index: 3;

  & p {
    width: 66%;
    margin: auto;
  }

  ${({ theme, showInFrame }) => theme.mediaQueries.tablet`
    right: ${showInFrame ? "-85px" : "10px"};
    top: ${showInFrame ? "-90px" : "10px"};
  `}

  ${({ theme, showInFrame }) => theme.mediaQueries.largeMobile`
    right: ${showInFrame ? "-80px" : "-30px"};
    ${showInFrame && "transform: scale(0.6);"}
  `}
`;

const BoomContent = styled.div`
  position: absolute;
  top: 210px;
  right: -3%;

  width: 100%;
  text-align: center;

  & > div {
    width: 58%;
  }

  & input {
    text-align: center;
  }

  ${({ theme }) => theme.mediaQueries.tablet`
    width: 110%;
    top: 155px;
    right: -60px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & > div {
      width: 54%;
    }
  `}

  ${({ theme }) => theme.mediaQueries.largeMobile`
    top: 165px;
    right: -40px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `}
`;

const StudyWrapper = styled.div`
  justify-self: flex-end;
  padding: 25px 0 0 25px;

  & > p {
    margin: 0;
  }

  ${({ theme }) => theme.mediaQueries.tablet`
    padding: 0 0 30px 30px;
  `}

  ${({ theme }) => theme.mediaQueries.largeMobile`
    width: 80%;
  `}
`;

const BoomImgContainer = styled.div`
  width: 608px;
  height: 414px;
  z-index: 0;

  ${({ theme }) => theme.mediaQueries.tablet`
    width: 400px;
    height: auto;
    transform: scale(1.1, 1.1) translate(30px, 20px);
  `}

  ${({ theme }) => theme.mediaQueries.largeMobile`
    transform: scale(1.1, 1.1) translate(0, 20px);
  `}
`;

const EducationBackground = styled.img`
  padding: 15px 0 0 15px;

  ${({ theme }) => theme.mediaQueries.tablet`
    padding-top: 10px;
    width: 95%;
  `}
`;

const GooseCage = styled.div`
  position: absolute;
  transform: scaleX(-1);
  right: -90px;
  top: 60px;
`;

const SchoolImg = styled(
  posed.img({
    enter: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0 }
  })
)`
  width: 130%;
`;

const ButtonWrapper = styled.div`
  margin-top: 10px;
`;

const schoolImages = {
  [School.UOFT]: UofTImg,
  [School.UW]: WaterlooImg,
  [School.CARLETON]: CarletonImg,
  [School.MCGILL]: McGillImg,
  [School.MCMASTER]: McMasterImg,
  [School.UBC]: UbcImg,
  [School.UOTTAWA]: UOttawaImg,
  [School.UW]: WaterlooImg,
  [School.WESTERN]: WesternImg
};

const noSchoolSelectedImg =
  Math.random() > 0.5 ? UniBgModern : UniBgTraditional;

const Education: React.FC<ComicSectionProps> = ({
  id,
  sectionIndex,
  allowEdit,
  isActive,
  setActive,
  goBackwards,
  goForwards
}) => {
  const {
    user: { school, degree }
  } = useFormContext();

  const [hasBeenActive, setHasBeenActive] = useState(false);
  if (isActive && !hasBeenActive) {
    setHasBeenActive(true);
  }

  const shouldAllowGoNext = useMemo(() => school !== "" && degree !== "", [
    school,
    degree
  ]);

  const selectedSchoolInfo = useMemo(() => {
    if (school === "") {
      return {
        img: noSchoolSelectedImg,
        alt: `A silhouette of a school building`
      };
    } else if (schoolImages[school]) {
      return {
        img: schoolImages[school],
        alt: `An illustration of ${school}`
      };
    } else {
      return {
        img: GenericUniImg,
        alt: "An illustration of a non-specific school building"
      };
    }
  }, [school]);

  return (
    <ComicSectionContainer
      id={id}
      sectionIndex={sectionIndex}
      isActive={isActive}
      onClick={isActive ? () => {} : setActive}
    >
      <Container>
        <DetailsWrapper>
          {(isActive || hasBeenActive) && (
            <BoomWrapper showInFrame={!allowEdit}>
              <BoomImgContainer>
                <ReactVivus
                  id="education-exclamation-bubble"
                  option={{
                    file: EducationBoom,
                    start: allowEdit ? "inViewport" : "autostart",
                    animTimingFunction: "LINEAR",
                    type: "oneByOne",
                    duration: 70
                  }}
                />
              </BoomImgContainer>
              <BoomContent>
                <Text variant="body">{copy.education.firstQuestion}</Text>
                <SchoolDropdown allowEdit={allowEdit} />
              </BoomContent>
            </BoomWrapper>
          )}
          <SlantedDetailsWrapper>
            <EducationBackground src={EducationBg} alt="background" />
            <StudyWrapper>
              <Text variant="body">{copy.education.secondQuestion}</Text>
              <FieldOfStudyDropdown allowEdit={allowEdit} />
              <ButtonWrapper>
                {allowEdit && (
                  <Button
                    variant="secondary"
                    onClick={goForwards}
                    disabled={!shouldAllowGoNext}
                  >
                    OK
                  </Button>
                )}
              </ButtonWrapper>
            </StudyWrapper>
          </SlantedDetailsWrapper>
        </DetailsWrapper>
        <GraphicsWrapper>
          <SlantedGraphicWrapper>
            <GooseCage>
              <CustomGoose />
            </GooseCage>
            <PoseGroup>
              <SchoolImg
                key={selectedSchoolInfo.alt}
                src={selectedSchoolInfo.img}
                alt={selectedSchoolInfo.alt}
              />
            </PoseGroup>
          </SlantedGraphicWrapper>
        </GraphicsWrapper>
      </Container>
    </ComicSectionContainer>
  );
};

export default React.memo(Education);
