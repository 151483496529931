import React, { useEffect, useCallback, useRef, useState } from "react";
import styled from "styled-components";
import mapboxgl from "mapbox-gl";
import maxBoxGeocoder from "@mapbox/mapbox-gl-geocoder";
import { ApplicationStatus } from "interfaces/application";
import { useFormContext } from "context/form";

interface MapProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onResult: (location: string, latitude: number, longitude: number) => void;
  allowEdit: boolean;
  latitude: number;
  longitude: number;
  location: string;
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const accessToken: string = process.env.REACT_APP_MAPBOX_API as string;
mapboxgl.accessToken = accessToken;
const mapStyle = process.env.REACT_APP_MAPBOX_STYLE;
const defaultZoom = 10;
const geocoderTypes = "country, region, place";

const FLY_PARAMS = {
  bearing: 45,
  zoom: defaultZoom,
  pitch: 45,
  curve: 1.5,
  speed: 2
};

const MapContent = styled.div`
  & .mapboxgl-canvas-container {
    position: relative;
    height: 100%;

    & canvas {
      height: 100% !important;
    }
  }

  & .mapboxgl-control-container {
    display: none;
  }

  ${({ theme }) => theme.mediaQueries.tablet`
    width: 100%;
    height: 370px;
  `}
`;

const Map: React.FC<MapProps> = ({
  onResult,
  allowEdit,
  latitude,
  longitude,
  location
}) => {
  const {
    application: { status }
  } = useFormContext();

  const [imageLoaded, setImageLoaded] = useState(false);

  const mapRef = useRef<mapboxgl.Map>();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const geocoderRef = useRef<any>();

  // handles the location when the user selects one from the dropdown
  const handleLocation = useCallback(
    (res: { result: { place_name: string; center: [number, number] } }) => {
      onResult(
        res.result.place_name,
        res.result.center[0],
        res.result.center[1]
      );
    },
    [onResult]
  );

  // initialize the map and geocoder input
  useEffect(() => {
    // prevent map from re-rendering on search
    if (mapRef.current) return;

    mapRef.current = new mapboxgl.Map({
      container: "mapbox",
      style: mapStyle,
      zoom: defaultZoom,
      center: [latitude, longitude],
      preserveDrawingBuffer: !allowEdit
    });
    geocoderRef.current = new maxBoxGeocoder({
      accessToken: accessToken,
      types: geocoderTypes,
      flyTo: FLY_PARAMS,
      minLength: 3,
      limit: 3,
      marker: false,
      placeholder: "find a location"
    });

    // add a listener to save the selected location when chosen
    if (geocoderRef.current) {
      geocoderRef.current.on("result", handleLocation);
    }

    if (!allowEdit) {
      // set static image behind map when map first finishes loading
      mapRef.current.on("idle", () => {
        const staticMapImgEl = document.getElementById(
          "staticMap"
        ) as HTMLImageElement;
        if (staticMapImgEl) {
          staticMapImgEl.src = (mapRef.current as mapboxgl.Map)
            .getCanvas()
            .toDataURL("image/png");
          setImageLoaded(true);
        }
      });
    }

    // add the input box to the DOM if it hasn't been yet
    const geocoderEl = document.getElementById("geocoder") as HTMLDivElement;
    if (geocoderEl) {
      const mapboxEl = document.getElementsByClassName(
        "mapboxgl-ctrl-geocoder--input"
      )[0];
      if (!mapboxEl) {
        geocoderEl.appendChild(geocoderRef.current.onAdd(mapRef.current));
        (document.getElementsByClassName(
          "mapboxgl-ctrl-geocoder--input"
        )[0] as HTMLInputElement).readOnly = !allowEdit;
        (document.getElementsByClassName(
          "mapboxgl-ctrl-geocoder--input"
        )[0] as HTMLInputElement).value = location;
        (document.getElementsByClassName(
          "mapboxgl-ctrl-geocoder--input"
        )[0] as HTMLInputElement).disabled =
          status !== ApplicationStatus.IN_PROGRESS;
      }
    }

    const inputEl = document.getElementsByClassName(
      "mapboxgl-ctrl-geocoder--input"
    )[0] as HTMLInputElement;
    if (inputEl) {
      inputEl.value = location;
    }

    // remove map `<canvas>` element from tab order
    const canvasEl = document.getElementsByClassName(
      "mapboxgl-canvas"
    )[0] as HTMLCanvasElement;
    if (canvasEl) canvasEl.tabIndex = -1;
  }, [allowEdit, handleLocation, latitude, location, longitude, status]); // eslint-disable-next-line react-hooks/exhaustive-deps

  return (
    <>
      <img
        id="staticMap"
        alt="a static layer representing the map"
        style={{ zIndex: allowEdit || !imageLoaded ? 1 : 2 }}
      />
      <MapContent id="mapbox" style={{ zIndex: allowEdit ? 2 : 1 }} />
    </>
  );
};

export default React.memo(Map);
