import React, { useCallback } from "react";
import { Text, TextInput } from "@hackthenorth/north";

import copy from "copy";
import { useFormContext } from "context/form";

interface NameTextInputProps {
  allowEdit: boolean;
}

const NameTextInput: React.FC<NameTextInputProps> = ({ allowEdit }) => {
  const {
    user: { name },
    setUser
  } = useFormContext();

  const updateName = useCallback(
    (newName: string) =>
      setUser({
        name: newName
      }),
    [setUser]
  );

  return (
    <>
      {allowEdit ? (
        <TextInput
          readOnly={!allowEdit}
          value={name}
          onChange={updateName}
          placeholder={copy.about.placeholderName}
        />
      ) : (
        <Text variant="reviewBody">{name}</Text>
      )}
    </>
  );
};

export default NameTextInput;
