import React, { useCallback } from "react";
import { Text, Dropdown } from "@hackthenorth/north";

import { useFormContext } from "context/form";
import copy from "copy";

interface GradYearDropdownProps {
  allowEdit: boolean;
  show?: boolean;
}

const options = ["2019", "2020", "2021", "2022", "2023", "2024"].map(year => ({
  value: year
}));

const GradYearDropdown: React.FC<GradYearDropdownProps> = ({
  allowEdit,
  show
}) => {
  const {
    user: { graduatingYear },
    setUser
  } = useFormContext();

  const updateGradYear = useCallback(
    newGradYear =>
      setUser({
        graduatingYear: newGradYear
      }),
    [setUser]
  );

  return (
    <>
      {allowEdit ? (
        <Dropdown
          readOnly={!allowEdit}
          options={options}
          value={graduatingYear}
          placeholder={copy.about.placeholderGrad}
          onChange={updateGradYear}
          tabIndex={show ? 0 : -1}
        />
      ) : (
        <Text variant="reviewBody">{graduatingYear}</Text>
      )}
    </>
  );
};

export default GradYearDropdown;
