import React, { useState, useCallback, useEffect } from "react";
import { Text, Button } from "@hackthenorth/north";
import styled from "styled-components";
import debounce from "lodash.debounce";

import { scrollToCenter, getMostVisible } from "utils/viewportHelpers";
import { withPageWrapper } from "utils/withPageWrapper";
import { useScrollTopOnMount } from "utils/hooks/useScrollTopOnMount";
import { useSiteContext } from "context/site";
import { useFormContext } from "context/form";
import copy from "copy";

import ContentContainer from "components/ContentContainer";
import ComicPage, { SECTION_IDS, NUM_SECTIONS } from "components/ComicPage";
import Heading from "components/Heading";

const FinishButtonContainer = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  z-index: 1;

  & > button {
    margin-left: 15px;
  }

  ${props => props.theme.mediaQueries.largeMobile`
    flex-direction: column;
    text-align: center;
    justify-content: center;

    & > button {
      margin-left: 0;
      margin-top: 15px;
    }
  `}
`;

const Comic = () => {
  useScrollTopOnMount();

  const { goToNextStage, loggedIn } = useSiteContext();
  const { saveApplication, canSaveComic, submitted } = useFormContext();

  /**
   * The current section is the section that's active and centered currently.
   * NOTE: this logic is very similar to the logic in the ExtendedQuestions component.
   */
  const [curSection, setCurSection] = useState(0); // note: these sections are internal and does not refer to stages of the overall hacker app procress

  const goToSection = useCallback(
    (sectionIndex: number) => {
      const element = document.getElementById(SECTION_IDS[sectionIndex]);
      if (element) {
        scrollToCenter(element);
        setCurSection(sectionIndex);
      }
    },
    [setCurSection]
  );

  const finishAndGoToNextStage = useCallback(() => {
    if (loggedIn) {
      saveApplication().then(() => {
        goToNextStage();
      });
    } else {
      goToNextStage();
    }
  }, [goToNextStage, loggedIn, saveApplication]);

  /**
   * going forwards/backwards can mean going to the next section, doing
   * nothing (if it's the first section), or going to the next STAGE
   * (if it's )
   */
  const goBackwards = useCallback(
    (callingSection: number) => () => {
      // don't do anything if the clicked callback was not in current section
      if (callingSection === curSection) {
        const prevSectionIndex = Math.max(0, curSection - 1); // lower bound of 0 since you can't have a negative index
        goToSection(prevSectionIndex);
      }
    },
    [curSection, goToSection]
  );

  const goForwards = useCallback(
    (callingSection: number) => () => {
      // don't do anything if the clicked callback was not in current section
      if (callingSection === curSection) {
        const atLastStage = curSection === NUM_SECTIONS - 1;

        if (atLastStage) {
          finishAndGoToNextStage();
        } else {
          goToSection(curSection + 1);
        }
      }
    },
    [curSection, finishAndGoToNextStage, goToSection]
  );

  const setAsActive = useCallback(
    (callingSection: number) => () => {
      if (callingSection !== curSection) {
        goToSection(callingSection);
      }
    },
    [curSection, goToSection]
  );

  const checkCurSectionCentered = useCallback(
    debounce(() => {
      const elements: HTMLElement[] = [];
      SECTION_IDS.forEach(id => {
        const element = document.getElementById(id);
        if (element) {
          elements.push(element);
        }
      });

      const mostCenteredElement = getMostVisible(elements);
      if (mostCenteredElement.el) {
        const mostCenteredId = mostCenteredElement.el.id;
        const mostCenteredIndex = SECTION_IDS.indexOf(mostCenteredId);
        setCurSection(mostCenteredIndex);
      }
    }, 50),
    [setCurSection]
  );

  /**
   * On scroll and page load, sets the comic section centered most in
   * the viewport to be the active section.
   */
  useEffect(() => {
    window.addEventListener("scroll", checkCurSectionCentered);

    return () => window.removeEventListener("scroll", checkCurSectionCentered);
  }, [checkCurSectionCentered]);

  return (
    <ContentContainer>
      <Heading title={copy.comicSection.title} />

      <ComicPage
        curSection={curSection}
        setAsActive={setAsActive}
        goBackwards={goBackwards}
        goForwards={goForwards}
      />

      {!submitted && (
        <FinishButtonContainer>
          {!canSaveComic && (
            <Text variant="subtextError">
              {copy.comicSection.errorUnanswered}
            </Text>
          )}
          <Button
            variant="primary"
            type="button"
            onClick={finishAndGoToNextStage}
            disabled={!canSaveComic}
          >
            Finish
          </Button>
        </FinishButtonContainer>
      )}
    </ContentContainer>
  );
};

export default withPageWrapper(Comic);
