import React, { useMemo } from "react";
import styled from "styled-components";
import posed from "react-pose";
import { Button, Text } from "@hackthenorth/north";
import ReactVivus from "react-vivus";

import copy from "copy";
import { Bubble } from "static/img";
import { useFormContext } from "context/form";
import EmailTextInput from "components/EmailTextInput";
import LevelOfStudyDropdown from "components/LevelOfStudyDropdown";
import GradYearDropdown from "components/GradYearDropdown";

interface SpeechBubbleProps {
  allowEdit: boolean;
  show: boolean;
  goForwards: () => void;
}

const ButtonContainer = posed.div({
  show: { opacity: 1, scale: 1 },
  hide: { opacity: 0, scale: 0 }
});

const SpeechBubbleBackground = styled.div`
  position: absolute;
  top: -10px;
  right: -11px;
  width: 370px;

  transform: scaleX(1.4);
  transform-origin: right center;

  ${({ theme }) => theme.mediaQueries.medium`
    top: auto;
    right: 102%;
    bottom: -10px;
    width: 66%;

    transform: rotate(90deg);
    transform-origin: right bottom;
  `}

  ${({ theme }) => theme.mediaQueries.tablet`
    top: 29%;
    right: 104%;
    bottom: auto;
    width: 67%;

    transform: rotate(90deg) scaleX(1.4);

    &:after {
      content: "";
      position: absolute;
      top: 6px;
      right: 0;
      height: 95%;
      width: 20%;
      background-color: ${theme.globalConstants.color.white};
    }
  `}

  ${({ theme }) => theme.mediaQueries.largeMobile`
    top: 32%;
    right: 105%;
    width: 68%;

    transform: rotate(90deg) scaleX(1);
  `}
`;

const SpeechBubbleContainer = styled(
  posed.div({
    show: {
      opacity: 1,
      scale: 1,
      delayChildren: 900,
      staggerChildren: 400,
      transition: "linear"
    },
    hide: { opacity: 0, scale: 0 }
  })
)<{ allowEdit: boolean }>`
  grid-area: other;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;

  & > * {
    text-align: right;
    position: relative;
    margin: 10px 30px;

    &:first-child {
      margin-top: ${({ allowEdit }) => (allowEdit ? "30px" : "90px")};
    }

    & input {
      text-align: right;
    }
  }

  ${({ theme }) => theme.mediaQueries.medium`
    align-items: center;

    & > * {
      text-align: center;
      left: 50px;
      margin: 5px 30px;

      &:first-child {
        margin-top: 10px;
      }

      &:last-child {
        margin-bottom: 5%;
      }

      & input {
        text-align: center;
      }
    }
  `}

  ${({ theme }) => theme.mediaQueries.tablet`
    & > * {
      left: 20px;
      margin: 5px 30px;
    }
  `}

  ${({ theme }) => theme.mediaQueries.largeMobile`
    & > * {
      left: 5px;
      margin: 5px 15px;
    }
  `}

  ${({ theme }) => theme.mediaQueries.smallMobile`
    & > * {
      left: 0;
      margin: 5px;
    }
  `}
`;

const Question = styled(
  posed.div({
    show: { opacity: 1, y: 0 },
    hide: { opacity: 0, y: 10 }
  })
)`
  &.dotPostfix input {
    &:after {
      content: ".";
      width: 1em;
      height: 1em;
      position: absolute;
      top: 50%;
      right: 5px;

      transform: translateY(-50%);
    }
  }
`;

const SpeechBubble: React.FC<SpeechBubbleProps> = ({
  allowEdit,
  show,
  goForwards
}) => {
  const { user } = useFormContext();

  const { name, email, educationLevel, graduatingYear } = user;

  // internally, allow for empty strings to make typescript happy
  const eduLevel = useMemo(() => educationLevel || "", [educationLevel]);
  const gradYear = useMemo(() => graduatingYear || "", [graduatingYear]);

  /**
   * Once all fields are filed in, enable the button to go to the next section
   */
  const shouldAllowGoNext = useMemo(
    () => name !== "" && email !== "" && eduLevel && gradYear !== "",
    [eduLevel, email, gradYear, name]
  );

  return (
    <>
      {// remount speech bubble to allow vivus to "re-draw" the svg
      show && (
        <SpeechBubbleBackground>
          <ReactVivus
            id="speech-bubble"
            option={{
              file: Bubble,
              start: "autostart",
              animTimingFunction: "EASE_IN",
              type: "oneByOne",
              duration: 70
            }}
          />
        </SpeechBubbleBackground>
      )}

      <SpeechBubbleContainer
        pose={show ? "show" : "hide"}
        allowEdit={allowEdit}
      >
        <Question>
          {allowEdit && <Text variant="body">{copy.about.questionEmail}</Text>}
          <EmailTextInput allowEdit={allowEdit} />
        </Question>

        <Question>
          <Text variant="body">
            {allowEdit ? copy.about.questionEdu : copy.about.questionEduNoEdit}
          </Text>
          <LevelOfStudyDropdown allowEdit={allowEdit} show={show} />
        </Question>

        <Question className="dotPostfix">
          <Text variant="body">{copy.about.questionGrad}</Text>
          <GradYearDropdown allowEdit={allowEdit} show={show} />
        </Question>

        <ButtonContainer>
          {allowEdit && (
            <Button
              variant="secondary"
              onClick={goForwards}
              disabled={!shouldAllowGoNext}
              tabIndex={show ? 0 : -1}
            >
              OK
            </Button>
          )}
        </ButtonContainer>
      </SpeechBubbleContainer>
    </>
  );
};

export default SpeechBubble;
