import { toPng, toJpeg, toSvgDataURL } from "html-to-image";

/**
 * Produces an image of a specified element in the DOM.
 * @param domElementId `id` of the element in the DOM
 * @param format file format of the captured image. one of `png`, `svg`, or `jpg`
 */
export const captureDom = async (
  domElementId: string,
  format: "png" | "svg" | "jpg" = "png"
) => {
  const domEl = document.getElementById(domElementId);
  let dataUrl = "";

  if (domEl) {
    try {
      switch (format) {
        case "png":
          dataUrl = await toPng(domEl);
          return dataUrl;

        case "svg":
          dataUrl = await toSvgDataURL(domEl);
          return dataUrl;

        case "jpg":
          dataUrl = await toJpeg(domEl);
          return dataUrl;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error("Error generating image: ", err);
    }
  }
  return "";
};
