import React from "react";
import { Redirect } from "react-router-dom";

import { useSiteContext, RouteNames } from "context/site";

/**
 * A HOC to wrap your component with a check for whether the user is logged in.
 */
export const withAuth = <T extends {}>(
  Component: React.FC<T>
): React.FC<T> => props => {
  const { loggedIn } = useSiteContext();

  if (!loggedIn) return <Redirect to={RouteNames.LOGIN} />;

  return <Component {...props} />;
};
