import React, { useEffect, useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import posed, { PoseGroup } from "react-pose";
import { Button } from "@hackthenorth/north";

import {
  GooseColor,
  GooseAccessory,
  NUM_GOOSE_COLORS,
  NUM_GOOSE_ACCESSORIES
} from "interfaces/user";
import { jackInTheBoxAnimation, pulseAnimation } from "utils/animations";

import {
  BeigeRobogooseImg,
  BlueRobogooseImg,
  GreenRobogooseImg,
  MintRobogooseImg,
  BeretImg,
  HeadphonesImg,
  ScarfImg,
  HardhatImg
} from "static/img";

import { useFormContext } from "context/form";

interface ContainerProps {
  editable: boolean;
}

interface CustomGooseProps {
  color?: GooseColor;
  accessory?: GooseAccessory | null;
  editable?: boolean;
}

/**
 * Offset of each accessory.
 */
interface AccessoryProps {
  width: number;
  x: number;
  y: number;
  transforms?: string;
}

const GOOSE_ANIMATION_TIME_MS = 600;

const Container = styled.div<ContainerProps>`
  position: relative;

  &.change:not(:focus-within) {
    animation: ${jackInTheBoxAnimation} ${GOOSE_ANIMATION_TIME_MS}ms ease-in 1;
  }

  &.randomizing {
    animation: ${pulseAnimation} ${GOOSE_ANIMATION_TIME_MS}ms ease-in 1;
  }

  & > button {
    cursor: ${({ editable }) => (editable ? "pointer" : "default")};
  }
`;

const GooseBodyContainer = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
`;

const RoboGoose = styled.img<{ show: boolean }>`
  width: 250px;
  grid-row: 1;
  grid-column: 1;

  display: ${({ show }) => (show ? "default" : "none")};
`;

const AccessoryContainer = styled(
  posed.div({
    enter: { opacity: 1, scale: 1 },
    exit: { opacity: 0, scale: 0 }
  })
)`
  position: absolute;
  top: ${({ y }) => y}px;
  left: ${({ x }) => x}px;
`;

const Accessory = styled.img<AccessoryProps>`
  width: ${({ width }) => width}px;
  transform: ${({ transforms }) => transforms};
`;

const rogobooseAccessories = {
  [GooseAccessory.BERET]: {
    width: 100,
    x: 128,
    y: -40,
    transforms: "rotate(-20deg)",
    img: BeretImg
  },
  [GooseAccessory.HARDHAT]: {
    width: 70,
    x: 147,
    y: -25,
    img: HardhatImg
  },
  [GooseAccessory.HEADPHONES]: {
    width: 90,
    x: 120,
    y: -16,
    transforms: "scale(0.95)",
    img: HeadphonesImg
  },
  [GooseAccessory.SCARF]: {
    width: 100,
    x: 135,
    y: 85,
    transforms: "scale(1.1) rotate(-2deg)",
    img: ScarfImg
  }
};

/**
 * **COMPONENT**
 * Allow color and accessory to be passed into goose. This is useful in case we ever
 * want to display a goose that's not the current user's (essentially overriding the chosen
 * color and accessory in state). If not specified, will default to the values stored in state.
 *
 * If `editable` is provided, tapping on the goose will randomize the accessory and color.
 */
const CustomGoose: React.FC<CustomGooseProps> = ({
  color,
  accessory,
  editable = false
}) => {
  // whether the color or accessory has recently changed. used for animations.
  const [changing, setChanging] = useState(false);
  const [randomized, setRandomized] = useState(false);
  const {
    user: { color: storedColor, accessory: storedAccessory },
    submitted,
    setUser
  } = useFormContext();

  // Show color if provided, or default to saved values in state if not specified.
  const colorToDisplay = useMemo(() => color || storedColor, [
    color,
    storedColor
  ]);
  const accessoryToDisplay = useMemo(() => accessory || storedAccessory, [
    accessory,
    storedAccessory
  ]);

  /**
   * Randomize goose color and accessories. Used for randomizing on click of goose!
   */
  const randomizeGoose = useCallback(() => {
    const randomColor = Object.values(GooseColor)[
      Math.floor(Math.random() * NUM_GOOSE_COLORS)
    ];
    const randomAccessory = Object.values(GooseAccessory)[
      Math.floor(Math.random() * NUM_GOOSE_ACCESSORIES)
    ];

    setUser({ color: randomColor, accessory: randomAccessory });

    setRandomized(true);

    const animTimer = setTimeout(() => {
      setRandomized(false);
    }, GOOSE_ANIMATION_TIME_MS);

    return () => clearTimeout(animTimer);
  }, [setUser, setRandomized]);

  /**
   * Trigger animation whenever goose color changes (user picked a new goose)
   */
  useEffect(() => {
    setChanging(true);

    const animTimer = setTimeout(() => {
      setChanging(false);
    }, GOOSE_ANIMATION_TIME_MS);

    return () => clearTimeout(animTimer);
  }, [colorToDisplay, setChanging]);

  // get info relating to selected accessory
  const chosenAccessoryInfo = rogobooseAccessories[String(accessoryToDisplay)]; // coerce into string to avoid `null cannot be used as index type` error

  const isActuallyEditable = editable && !submitted;

  return (
    <Container
      className={`
        goose 
        ${changing ? "change" : ""} 
        ${randomized ? "randomizing" : ""}
      `}
      editable={isActuallyEditable}
    >
      <Button
        variant="invisible"
        onClick={isActuallyEditable ? randomizeGoose : () => {}}
        tabIndex={isActuallyEditable ? 0 : -1}
      >
        <GooseBodyContainer>
          <RoboGoose
            src={GreenRobogooseImg}
            show={colorToDisplay === GooseColor.GREEN}
          />
          <RoboGoose
            src={BeigeRobogooseImg}
            show={colorToDisplay === GooseColor.BEIGE}
          />
          <RoboGoose
            src={BlueRobogooseImg}
            show={colorToDisplay === GooseColor.BLUE}
          />
          <RoboGoose
            src={MintRobogooseImg}
            show={colorToDisplay === GooseColor.MINT}
          />
        </GooseBodyContainer>
        <PoseGroup>
          <AccessoryContainer
            {...chosenAccessoryInfo}
            key={String(accessoryToDisplay)}
          >
            <Accessory
              {...chosenAccessoryInfo}
              src={chosenAccessoryInfo ? chosenAccessoryInfo.img : ""}
            />
          </AccessoryContainer>
        </PoseGroup>
      </Button>
    </Container>
  );
};

export default CustomGoose;
