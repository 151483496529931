import React from "react";
import { Button } from "@hackthenorth/north";

import fullCopy from "copy";
import { useSiteContext } from "context/site";
import { useFormContext } from "context/form";

import Heading from "components/Heading";
import ActionButtonsContainer from "components/ActionButtonsContainer";

const copy = fullCopy.auth.alreadyLoggedIn;

/**
 * To be used in the Auth page.
 */
const AlreadyLoggedIn = () => {
  const { goToNextStage } = useSiteContext();
  const {
    user: { name },
    logOut
  } = useFormContext();

  return (
    <>
      <Heading title={`Hi${name && `, ${name}`}!`} subtitle={copy.subtitle} />
      <ActionButtonsContainer>
        <Button variant="primary" onClick={goToNextStage}>
          {copy.cta}
        </Button>
        <Button variant="link" onClick={logOut} type="button">
          {copy.logOut}
        </Button>
      </ActionButtonsContainer>
    </>
  );
};

export default AlreadyLoggedIn;
