import React, { useState, useCallback, useMemo } from "react";
import Heading from "components/Heading";

import fullCopy from "copy";
import { Text, Button, TextInput } from "@hackthenorth/north";
import { useFormContext } from "context/form";
import { useSiteContext, AuthPage } from "context/site";
import ActionButtonsContainer from "components/ActionButtonsContainer";
import { FormField, SubtextContainer } from "./components";

const copy = fullCopy.auth.createAccount;

const CreateAccount = () => {
  const { user, setUser, createAccount } = useFormContext();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [createAccountFailed, setCreateAccountFailed] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);

  const { setAuthPage, goToNextStage } = useSiteContext();

  const canSubmit = useMemo(
    () =>
      user.email.trim().length > 0 &&
      password === confirmPassword &&
      password.length > 7,
    [user.email, password, confirmPassword]
  );

  const onCreateAccountClick = useCallback(() => {
    createAccount(password)
      .then(() => {
        goToNextStage();
      })
      .catch(() => setCreateAccountFailed(true));
  }, [createAccount, goToNextStage, password]);

  const onEmailChange = useCallback(
    (email: string) => {
      setUser({
        email
      });
    },
    [setUser]
  );

  const goToLogIn = useCallback(() => {
    setAuthPage(AuthPage.LOG_IN);
  }, [setAuthPage]);

  const errorText = useMemo(() => {
    if (createAccountFailed) {
      return copy.accountCreationError;
    } else if (passwordTouched && password.length && password.length <= 7) {
      return copy.pwLengthError;
    } else if (
      confirmPasswordTouched &&
      password.length &&
      confirmPassword.length &&
      password !== confirmPassword
    ) {
      return copy.pwMatchingError;
    }

    return null;
  }, [
    password,
    confirmPassword,
    passwordTouched,
    confirmPasswordTouched,
    createAccountFailed
  ]);

  return (
    <form
      onSubmit={e => {
        setPasswordTouched(true);
        setConfirmPasswordTouched(true);
        e.preventDefault();
      }}
    >
      <Heading title={copy.title} subtitle={copy.subtitle} />
      <FormField>
        <Text variant="heading">{copy.email}</Text>
        <TextInput
          value={user.email}
          placeholder={copy.emailPlaceholder}
          onChange={onEmailChange}
        />
      </FormField>
      <FormField>
        <Text variant="heading">{copy.password}</Text>
        <TextInput
          value={password}
          onChange={setPassword}
          onBlur={() => setPasswordTouched(true)}
          onKeyUp={(key: string) => {
            if (key === "Enter") {
              setPasswordTouched(true);
            }
          }}
          placeholder={copy.passwordPlaceholder}
          type="password"
        />
      </FormField>
      <FormField>
        <Text variant="heading">{copy.confirmPassword}</Text>
        <TextInput
          value={confirmPassword}
          onChange={setConfirmPassword}
          onBlur={() => setConfirmPasswordTouched(true)}
          onKeyUp={(key: string) => {
            if (key === "Enter") {
              setConfirmPasswordTouched(true);
            }
          }}
          placeholder={copy.confirmPasswordPlaceholder}
          type="password"
        />
        <SubtextContainer>
          {errorText && <Text variant="subtextError">{errorText}</Text>}
        </SubtextContainer>
      </FormField>
      <ActionButtonsContainer>
        <Button
          variant="primary"
          onClick={onCreateAccountClick}
          disabled={!canSubmit}
        >
          {copy.button}
        </Button>
        <Button variant="link" onClick={goToLogIn} type="button">
          {copy.haveAccount}
        </Button>
      </ActionButtonsContainer>
    </form>
  );
};

export default CreateAccount;
