import styled from "styled-components";

const ActionButtonsContainer = styled.div`
  margin-top: 60px;
  ${({ theme }) => theme.mediaQueries.largeMobile`
    margin-top: 30px;
  `}

  & > * {
    margin: 0 auto;
    display: block;
  }
`;

export default ActionButtonsContainer;
