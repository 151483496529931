import React from "react";
import styled from "styled-components";
import { Text } from "@hackthenorth/north";

interface HeadingProps {
  title: string;
  subtitle?: React.ReactNode;
}

const Header = styled.div`
  margin-bottom: ${({ theme }) => theme.globalConstants.margin.headingBottom}px;

  & > h3 {
    color: ${({ theme }) => theme.globalConstants.color.activeBlue};
  }
  ${({ theme }) => theme.mediaQueries.largeMobile`
    margin-bottom: 16px;
  `}
`;

const Heading: React.FC<HeadingProps> = ({ title, subtitle }) => (
  <Header>
    <Text variant="title" as="h1">
      {title}
    </Text>
    <Text variant="body" as="h3">
      {subtitle}
    </Text>
  </Header>
);

export default React.memo(Heading);
