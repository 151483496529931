import styled from "styled-components";

const SubtextContainer = styled.div`
  padding: 10px 0;
  & > button {
    padding: 0;
  }
`;

export default SubtextContainer;
