import { css } from "styled-components";
import UnderlineTextInput from "components/UnderlineTextInput";
import UnderlineTextAreaInput from "components/UnderlineTextAreaInput";
import { DropdownOptionProps } from "@hackthenorth/north";

/**
 * ** 📣 **Guidelines for shared component styles/variants** 📣 **
 * 1. Layout, size (width/height), position, and anything else that
 *    has to do with how the component looks external to itself
 *    should _not_ be defined here. It should be defined when the component is used,
 *    ideallly in a one-off styled component for that use case.
 * 2. If a variant is planned to be used only once or in one file, it should be
 *    defined in that file as a one-off styled component instead of as a variant.
 * 3. Try to use a variant or a one-off styled component as much as you can,
 *    and only add variants if they're 100% necessary.
 * 4. If adding something to sharedStyles, make sure you verify that it doesn't
 *    break other usages of the same component.
 */
const componentStyles = {
  button: {
    sharedStyles: css`
      position: relative;
      font-family: ${props => props.theme.globalConstants.fontFamily.heading};
      padding: ${props => props.theme.globalConstants.padding.button};

      &:disabled {
        cursor: not-allowed;
      }
    `,
    variants: {
      primary: css`
        color: ${props => props.theme.globalConstants.color.primaryBlue};
        border: 6px solid
          ${props => props.theme.globalConstants.color.primaryBlue};
        border-radius: ${props =>
          props.theme.globalConstants.borderRadius.button};

        transition: background-color 250ms ease;
        &:disabled {
          background-color: ${props =>
            props.theme.globalConstants.color.inactive};
        }

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
          background-color: ${props =>
            props.theme.globalConstants.color.activeBlue};
        }
      `,
      secondary: css`
        color: ${props => props.theme.globalConstants.color.primaryBlue};
        border-radius: 5px;
        background-color: ${props =>
          props.theme.globalConstants.color.activeBlue};

        transition: opacity 250ms ease;
        opacity: 1;

        &:disabled {
          background-color: ${props =>
            props.theme.globalConstants.color.inactive};
        }

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
          opacity: 0.8;
        }
      `,
      textOnly: css`
        color: ${props => props.theme.globalConstants.color.primaryBlue};

        transition: opacity 250ms ease;
        opacity: 0.7;

        &:hover:not(:disabled),
        &:focus:not(:disabled) {
          opacity: 1;
        }
      `,
      invisible: css`
        border: none;
        padding: 0;

        &:hover {
          background-color: transparent;
        }
      `,
      link: css`
        color: ${props => props.theme.globalConstants.color.activeBlue};
        font-size: ${props => props.theme.globalConstants.fontSize.subtext}px;

        cursor: pointer;
        text-decoration: none;

        &:focus,
        &:hover {
          text-decoration: underline;
        }
      `,
      plain: css`
        padding: 0;

        &:focus,
        &:hover {
          opacity: 0.8;
          text-decoration: none;
        }
      `
    }
  },
  dropdownOptionsContainer: {
    sharedStyles: css`
      z-index: 3;
      background-color: ${props =>
        props.theme.globalConstants.color.primaryBlue};
      color: ${props => props.theme.globalConstants.color.light};
      border: 2px solid ${props => props.theme.globalConstants.color.activeBlue};
      top: -2px;
      max-height: 210px;
    `
  },
  dropdownOption: {
    sharedStyles: css<DropdownOptionProps>`
      padding: 5px 10px;
      font-family: ${props => props.theme.globalConstants.fontFamily.heading};
      font-size: ${props => props.theme.globalConstants.fontSize.subtext}px;
      border: 2px solid
        ${props => props.theme.globalConstants.color.primaryBlue};
      ${({ hasFocus }) => (hasFocus ? "&," : "")} &:hover {
        background-color: ${props => props.theme.globalConstants.color.light};
        color: ${props => props.theme.globalConstants.color.activeBlue};
        border: 2px solid
          ${props => props.theme.globalConstants.color.activeBlue};
      }
      ${({ theme }) => theme.mediaQueries.tablet`
        font-size: ${theme.globalConstants.fontSize.subtext}px;
    `}
    `
  },
  dropdownMultiValue: {
    sharedStyles: css`
      font-family: ${props => props.theme.globalConstants.fontFamily.heading};
      font-size: ${props => props.theme.globalConstants.fontSize.subtext}px;
      border: 2px solid ${props => props.theme.globalConstants.color.activeBlue};
      background-color: ${props =>
        props.theme.globalConstants.color.primaryBlue};
      margin-right: 8px !important;
      color: ${props => props.theme.globalConstants.color.white};
      padding: 6px;
    `
  },
  dropdownMultiValueRemove: {
    sharedStyles: css`
      font-family: ${props => props.theme.globalConstants.fontFamily.heading};
      margin-left: 8px;
      transition: transform 150ms;
      &:hover,
      &:focus {
        transform: scale(1.25);
      }
    `
  },
  link: {
    sharedStyles: css`
      color: ${props => props.theme.globalConstants.color.activeBlue};

      cursor: pointer;
      text-decoration: none;

      &:focus,
      &:hover {
        text-decoration: underline;
      }
    `,
    variants: {
      animUnderline: css`
        position: relative;
        text-decoration: none;

        &:before {
          content: "";
          position: absolute;

          width: 100%;
          height: ${props => props.theme.globalConstants.border.input};
          bottom: 0;

          background-color: ${props =>
            props.theme.globalConstants.color.activeBlue};
          visibility: hidden;
          transform: scaleX(0);
          transition: all 150ms ease 0s;
        }

        &:focus,
        &:hover {
          text-decoration: none;
          outline: none;
        }

        &:focus:before,
        &:hover:before {
          visibility: visible;
          transform: scaleX(1);
        }
      `,
      plain: css`
        &:focus,
        &:hover {
          opacity: 0.8;
          text-decoration: none;
        }
      `
    }
  },
  progress: {
    sharedStyles: css`
      background-color: ${({ theme }) => theme.globalConstants.color.light};
    `
  },
  progressContainer: {
    sharedStyles: css`
      background-color: ${({ theme }) => theme.globalConstants.color.inactive};
      width: 100%;
      height: 8px;

      ${({ theme }) => theme.mediaQueries.largeMobile`
        height: 6px;
      `}
    `
  },
  text: {
    sharedStyles: css`
      margin: 0;
    `,
    variants: {
      title: css`
        font-family: ${props => props.theme.globalConstants.fontFamily.heading};
        font-size: ${props => props.theme.globalConstants.fontSize.title}px;
        color: ${props => props.theme.globalConstants.color.primaryBlue};

        ${({ theme }) => theme.mediaQueries.tablet`
          font-size: ${theme.globalConstants.fontSize.heading}px;
        `}
      `,
      heading: css`
        font-family: ${props => props.theme.globalConstants.fontFamily.heading};
        font-size: ${props => props.theme.globalConstants.fontSize.heading}px;
        color: ${props => props.theme.globalConstants.color.primaryBlue};
        margin: ${({ theme }) => theme.globalConstants.margin.heading};

        ${({ theme }) => theme.mediaQueries.tablet`
          font-size: ${theme.globalConstants.fontSize.subtext}px;
        `}
      `,
      nav: css`
        font-family: ${props => props.theme.globalConstants.fontFamily.heading};
        font-size: ${props => props.theme.globalConstants.fontSize.hint}px;
        color: ${props => props.theme.globalConstants.color.light};
        margin: 0;
        ${({ theme }) => theme.mediaQueries.largeMobile`
          font-size: ${theme.globalConstants.fontSize.hint}px;
        `}
      `,
      body: css`
        font-family: ${props => props.theme.globalConstants.fontFamily.heading};
        font-size: ${props => props.theme.globalConstants.fontSize.body}px;
        color: inherit;

        ${({ theme }) => theme.mediaQueries.tablet`
          font-size: ${theme.globalConstants.fontSize.smallBody}px;
        `}

        ${({ theme }) => theme.mediaQueries.largeMobile`
          font-size: ${theme.globalConstants.fontSize.subtext}px;
        `}
      `,
      subtext: css`
        font-family: ${props => props.theme.globalConstants.fontFamily.text};
        font-size: ${props => props.theme.globalConstants.fontSize.subtext}px;
        color: ${props => props.theme.globalConstants.color.primaryBlue};
      `,
      subtextSecondary: css`
        font-family: ${props => props.theme.globalConstants.fontFamily.text};
        font-size: ${props => props.theme.globalConstants.fontSize.subtext}px;
        color: ${props => props.theme.globalConstants.color.activeBlue};
      `,
      subtextBold: css`
        font-family: ${props => props.theme.globalConstants.fontFamily.text};
        font-size: ${props => props.theme.globalConstants.fontSize.subtext}px;
        color: ${props => props.theme.globalConstants.color.primaryBlue};
        font-weight: 700;
      `,
      subtextError: css`
        font-family: ${props => props.theme.globalConstants.fontFamily.text};
        font-size: ${props => props.theme.globalConstants.fontSize.subtext}px;
        color: ${props => props.theme.globalConstants.color.error};
      `,
      hint: css`
        font-family: ${props => props.theme.globalConstants.fontFamily.heading};
        font-size: ${props => props.theme.globalConstants.fontSize.hint}px;
        color: ${props => props.theme.globalConstants.color.primaryBlue};
      `,
      reviewTitle: css`
        font-family: ${props => props.theme.globalConstants.fontFamily.heading};
        font-size: ${props => props.theme.globalConstants.fontSize.body}px;
        color: ${props => props.theme.globalConstants.color.primaryBlue};

        ${({ theme }) => theme.mediaQueries.tablet`
          font-size: ${theme.globalConstants.fontSize.smallBody}px;
        `}

        ${({ theme }) => theme.mediaQueries.largeMobile`
          font-size: ${theme.globalConstants.fontSize.subtext}px;
        `}
      `,
      reviewBody: css`
        font-family: ${props => props.theme.globalConstants.fontFamily.heading};
        font-size: ${props => props.theme.globalConstants.fontSize.body}px;
        color: ${props => props.theme.globalConstants.color.activeBlue};
      `
    }
  },
  textInput: {
    component: UnderlineTextInput,
    sharedStyles: css`
      font-family: ${props => props.theme.globalConstants.fontFamily.heading};
      font-size: ${props => props.theme.globalConstants.fontSize.body}px;
      color: ${props => props.theme.globalConstants.color.activeBlue};
      padding: ${props => props.theme.globalConstants.padding.input};

      border: none;
      border-bottom: ${props =>
        `${props.theme.globalConstants.border.input} solid ${
          props.theme.globalConstants.color.inactive
        }`};

      &::placeholder {
        color: ${props => props.theme.globalConstants.color.inactive};
      }

      ${({ theme }) => theme.mediaQueries.tablet`
        font-size: ${theme.globalConstants.fontSize.smallBody}px;
      `}

      ${({ theme }) => theme.mediaQueries.largeMobile`
        font-size: ${theme.globalConstants.fontSize.subtext}px;
      `}
    `
  },
  textAreaInput: {
    component: UnderlineTextAreaInput,
    sharedStyles: css`
      font-family: ${props => props.theme.globalConstants.fontFamily.heading};
      font-size: ${props => props.theme.globalConstants.fontSize.body}px;
      color: ${props => props.theme.globalConstants.color.activeBlue};
      padding: ${props => props.theme.globalConstants.padding.input};

      border: none;
      border-bottom: ${props =>
        `${props.theme.globalConstants.border.input} solid ${
          props.theme.globalConstants.color.inactive
        }`};

      &::placeholder {
        color: ${props => props.theme.globalConstants.color.inactive};
      }

      ${({ theme }) => theme.mediaQueries.tablet`
        font-size: ${theme.globalConstants.fontSize.smallBody}px;
      `}

      ${({ theme }) => theme.mediaQueries.largeMobile`
        font-size: ${theme.globalConstants.fontSize.subtext}px;
      `}
    `
  }
};

export default componentStyles;
