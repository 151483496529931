import { useContext } from "react";
import {
  __RouterContext as RouterContext,
  RouteComponentProps
} from "react-router";
import { Location, History } from "history";

export const useRouter = <T>(): RouteComponentProps<T> =>
  useContext(RouterContext) as RouteComponentProps<T>;

export const useHistory = (): History => {
  const { history } = useRouter();
  return history;
};

export const useLocation = (): Location => {
  const { location } = useRouter();

  return location;
};

export const useParams = <T>(): T => {
  const { match } = useRouter<T>();
  return match.params;
};
