import React, { useCallback } from "react";
import styled from "styled-components";
import { Text } from "@hackthenorth/north";

import Map from "./components/Map";
import copy from "copy";
import { useFormContext } from "context/form";
import { ComicSectionProps } from "components/ComicPage";

import { ComicSectionContainer } from "components/ComicPage/components/ComicSectionContainer";
import CustomGoose from "components/CustomGoose";
import GeocoderInput from "components/GeocoderInput";

const { travel: travelCopy } = copy;

const GooseContainer = styled.div<{ bg: string; gooseUp: boolean }>`
  position: relative;
  background-image: url(${({ bg }) => bg});
  background-size: cover;
  height: 215px;

  .goose {
    transition: all 250ms;
    transform: scale(2)
      translate(0, ${({ gooseUp }) => (gooseUp ? "50px" : "90px")});

    ${({ theme, gooseUp }) => theme.mediaQueries.tablet`
      transform: 
        scale(2)
        translate(20%, ${gooseUp ? "120px" : "90px"});
    `}

    ${({ theme, gooseUp }) => theme.mediaQueries.largeMobile`
      transform:
        scale(2)
        translate(-25%, ${gooseUp ? "120px" : "90px"});
    `}
  }
`;

const TravelContainer = styled.div`
  display: grid;
  grid-template-areas: "input map";
  grid-template-columns: 13fr 11fr;

  background-color: ${({ theme }) => theme.globalConstants.color.grey};
  height: 450px;
  border: 10px solid ${({ theme }) => theme.globalConstants.color.primaryBlue};

  &:hover,
  &:focus {
    & .goose {
      transform: scale(2) translate(0, 90px);

      ${({ theme }) => theme.mediaQueries.tablet`
        transform: 
          scale(2)
          translate(20%, 90px);
      `}

      ${({ theme }) => theme.mediaQueries.largeMobile`
        transform:
          scale(2)
          translate(-25%, 90px);
      `}
    }
  }

  ${({ theme }) => theme.mediaQueries.tablet`
    display: grid;
    grid-template-areas: "input" "map";
    grid-template-columns: auto;
    grid-template-rows: 45% 55%;
  `}
`;

const TravelGeoInput = styled(GeocoderInput)`
  .mapboxgl-ctrl-geocoder--input {
    background-color: ${({ theme }) => theme.globalConstants.color.light};
  }
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  border-bottom: 10px solid
    ${({ theme }) => theme.globalConstants.color.primaryBlue};
  padding: 30px;

  & > h1 {
    position: relative;
    width: 100%;
  }

  ${({ theme }) => theme.mediaQueries.tablet`
    border: none;
    padding: 10px;
  `}
`;

const InputPanel = styled.div`
  grid-area: input;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-right: 10px solid
    ${({ theme }) => theme.globalConstants.color.primaryBlue};

  ${({ theme }) => theme.mediaQueries.tablet`
    border-right: none;
    border-bottom: 10px solid ${theme.globalConstants.color.primaryBlue};
    height: 310px;
    width: 100%;
  `}
`;

const MapPanel = styled.div`
  grid-area: map;

  position: relative;
  flex-grow: 1;

  display: grid;
  & > * {
    width: 100%;
    grid-column: 1;
    grid-row: 1;
  }

  ${({ theme }) => theme.mediaQueries.tablet`
    width: 100%;
    max-width: 100%;
  `}
`;

const Travel: React.FC<ComicSectionProps> = ({
  id,
  sectionIndex,
  allowEdit,
  isActive,
  setActive,
  goBackwards,
  goForwards
}) => {
  const {
    user: { location, latitude, longitude },
    setUser
  } = useFormContext();

  const setLocation = useCallback(
    (newLocation: string, latitude: number, longitude: number) =>
      setUser({
        location: newLocation,
        latitude,
        longitude
      }),
    [setUser]
  );

  return (
    <ComicSectionContainer
      id={id}
      sectionIndex={sectionIndex}
      isActive={isActive}
      onClick={isActive ? () => {} : setActive}
    >
      <TravelContainer>
        <InputPanel>
          <InputContainer>
            <Text as="h1" variant="body">
              {travelCopy.heading}
            </Text>
            {allowEdit ? (
              <TravelGeoInput id="geocoder" />
            ) : (
              <Text as="h1" variant="body">
                {location || "Undisclosed Location"}{" "}
              </Text>
            )}
          </InputContainer>
          <GooseContainer
            gooseUp={allowEdit && !location}
            className="goose-container"
            bg={travelCopy.background}
          >
            <CustomGoose />
          </GooseContainer>
        </InputPanel>

        <MapPanel id="mapContainer">
          <Map
            onResult={setLocation}
            allowEdit={allowEdit}
            latitude={latitude}
            longitude={longitude}
            location={location}
          />
        </MapPanel>
      </TravelContainer>
    </ComicSectionContainer>
  );
};

export default Travel;
