import React, { useState, useCallback, useMemo } from "react";
import styled from "styled-components";
import {
  Text,
  TextInput,
  TextAreaInput,
  Button,
  Dropdown,
  MultiDropdown,
  DropdownOptionValue
} from "@hackthenorth/north";

import { useFormContext, NUM_NON_SURVEY_QUESTIONS } from "context/form";
import { ReturnIconImg, ChevronIconImg } from "static/img";
import { AppQuestion } from "interfaces/application";
import copy from "copy";
import { isValidUrl } from "utils/isValidUrl";

export interface QuestionProps {
  questionNum: number;
  questionId: string;
  question: AppQuestion;
  isActive: boolean;
  isReadOnly: boolean;
  isSurvey?: boolean;
  isFirst: boolean;
  isLast: boolean;
  setActive: () => void;
  goPrevQuestion: () => void;
  goNextQuestion: () => void;
}

interface ContainerProps {
  questionNum: number;
  isActive: boolean;
}

const noOp = () => {};

/**
 * A filter function that filters for options that match the value AND have not been selected already.
 */
const displayOnlyNotSelected = (alreadySelectedOptions: string[] = []) => (
  option: DropdownOptionValue,
  value: string
) => {
  return (
    option.value.toLowerCase().indexOf(value.toLowerCase()) !== -1 && // typed string matches option
    alreadySelectedOptions.find(selectedVal => selectedVal === option.value) ===
      undefined // has not already been selected
  );
};

/**
 * ------------------ **COMPONENT STYLES** --------------------
 */
const Container = styled.div<ContainerProps>`
  position: relative;
  z-index: ${({ questionNum }) => 15 - questionNum};

  width: 100%;
  margin: 25vh auto;

  transition: opacity 250ms linear;
  opacity: ${props => (props.isActive ? 1 : 0.5)};

  &:first-of-type {
    margin-top: 10vh;
  }

  &:last-of-type {
    margin-bottom: 15vh;
  }

  & p {
    margin: 10px auto 20px auto;
  }

  & .questionNumLabel {
    position: absolute;
    bottom: 100%;

    margin-bottom: 10px;
  }

  & > * {
    margin: 0;
    width: 100%;
    z-index: 2;

    & > div:first-child {
      width: 100%;
    }
  }
`;

const NavChevronContainer = styled.div`
  position: absolute;

  display: flex;
  flex-direction: column;
  align-items: center;

  top: 5px;
  left: -45px;
  width: 20px;

  ${props => props.theme.mediaQueries.tablet`
    display: none;
  `}
`;

const NavChevronButton = styled(Button)<{ direction: "up" | "down" }>`
  transform: ${props => (props.direction === "up" ? "rotate(180deg)" : "none")};

  transition: opacity 250ms ease;
  opacity: 0.4;

  &:hover,
  &:focus,
  &:focus-within {
    opacity: 1;
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }

  & > img {
    width: 100%;
  }
`;

const QuestionTextAreaInput = styled(TextAreaInput)`
  resize: vertical;
  min-width: 100%;
  min-height: 5em;
`;

const ReturnButton = styled(Button)`
  position: relative;
  right: 0;
  width: 20px;

  transition: opacity 250ms ease;
  opacity: 0.7;

  &:hover,
  &:focus,
  &:focus-within {
    opacity: 1;
  }

  & > img {
    width: 100%;
  }
`;

const SkipButton = styled(Button)`
  transition: opacity 250ms ease;
  opacity: 0.7;

  &:hover,
  &:focus,
  &:focus-within {
    opacity: 1;
  }
`;

const SubOption = styled.div`
  display: flex;
  justify-content: space-between;

  height: 1.75em;
  margin-top: 20px;
`;

/**
 * Used to prevent any interaction with a question's contents unless it's
 * the active question (except for switching to the question).
 */
const DisabledRegion = styled.div<{ disabled: boolean }>`
  position: absolute;
  top: 0;
  left: -45px;

  width: calc(100% + 45px);
  height: 100%;

  z-index: ${props => (props.disabled ? 3 : -1)};

  ${props => props.theme.mediaQueries.tablet`
    left: 0;
    width: 100%;
  `}
`;

/**
 * Returns an input element corresponding to the question type
 * @param id id of the question
 * @param questionDetails details of the question
 * @param goNextQuestion callback for going to the next question
 * @param onChange callback for updating a response in the application
 * @param onCustonChange callback for adding a custom response in the application
 * @param isActive whether or not the current question is the active one
 */
export const inputElementFactory = (
  id: string,
  {
    type,
    options,
    charLimit,
    response,
    responses,
    allowCustomResponses,
    triggerKeys
  }: AppQuestion,
  onChange: (newResponse: string | string[]) => void,
  onCustomChange: (newResponse: string) => void,
  isActive: boolean,
  isReadOnly: boolean
) => {
  switch (type) {
    case "shortAnswer":
      return (
        <TextInput
          className={`text-input`}
          value={response}
          maxLength={charLimit}
          readOnly={!isActive || isReadOnly}
          onChange={onChange}
        />
      );

    case "number":
      return (
        <TextInput
          className={`text-input`}
          value={response}
          maxLength={charLimit}
          readOnly={!isActive || isReadOnly}
          onChange={onChange}
          type="number"
        />
      );

    case "longAnswer":
      return (
        <QuestionTextAreaInput
          className={`textarea-input`}
          value={response}
          maxLength={charLimit}
          readOnly={!isActive || isReadOnly}
          onChange={onChange}
        />
      );

    case "select":
      return (
        <Dropdown
          className={`dropdown-input`}
          options={(options || []).map(optionValue => ({ value: optionValue }))}
          value={response}
          filterable
          filterFunction={displayOnlyNotSelected([response])}
          creatable={allowCustomResponses}
          onChange={onChange}
          readOnly={!isActive || isReadOnly}
        />
      );

    case "multiSelect":
      return (
        <MultiDropdown
          className={`multidropdown-input`}
          options={(options || []).map(optionValue => ({ value: optionValue }))}
          values={responses}
          filterable
          filterFunction={displayOnlyNotSelected(responses)}
          creatable={allowCustomResponses}
          onChange={onChange}
          readOnly={!isActive || isReadOnly}
          creatableTriggerKeys={triggerKeys}
        />
      );

    default:
      return (
        <Text variant="subtextError" className={`error`}>
          Oops! Something went wrong loading this question.
        </Text>
      );
  }
};

/**
 * Determines the contents and type of subtext shown underneath the user input in the question.
 * @param questionDetails details of the question
 * @param goNextQuestion callback for going to the next question
 * @param isActive whether or not the current question is the active one
 * @param touched whether or not the question input has been interacted with
 */
export const subOptionFactory = (
  {
    optional,
    charLimit,
    response,
    responses,
    type,
    hackerapiField
  }: AppQuestion,
  goNextQuestion: () => void,
  touched: boolean,
  isReview: boolean,
  isReadOnly: boolean
) => {
  let optionText: React.ReactNode = "";
  let textVariant = "subtext";

  const noResponse =
    type === "multiSelect" ? (responses || []).length === 0 : !response;

  if (touched && noResponse && !optional) {
    optionText = copy.extendedQuestions.subtextUnanswered;
    textVariant = "subtextError";
  } else if (
    hackerapiField === "links" &&
    touched &&
    !noResponse && // has response
    !(responses || []).every(isValidUrl) // all responses are valid links
  ) {
    optionText = copy.extendedQuestions.subtextInvalidLink;
    textVariant = "subtextError";
  } else if (charLimit) {
    optionText = `${charLimit - response.length}/${charLimit} characters left`;
    textVariant = charLimit - response.length <= 0 ? "subtextError" : "subtext";
  } else if (optional && !isReview && !isReadOnly) {
    optionText = (
      <SkipButton variant="invisible" onClick={goNextQuestion}>
        {copy.extendedQuestions.skipButton}
      </SkipButton>
    );
    textVariant = "subtext";
  }

  return (
    <SubOption>
      <Text as="span" variant={textVariant}>
        {optionText}
      </Text>
      {isReview || isReadOnly ? null : (
        <ReturnButton variant="invisible" onClick={goNextQuestion}>
          <img
            src={ReturnIconImg}
            alt="An icon of a return symbol for navigating to the next question"
          />
        </ReturnButton>
      )}
    </SubOption>
  );
};

/**
 * ------------------ **QUESTION COMPONENT** --------------------
 */
const Question: React.FC<QuestionProps> = ({
  questionNum,
  questionId,
  question,
  isActive,
  isReadOnly,
  isFirst,
  isLast,
  isSurvey,
  goPrevQuestion,
  goNextQuestion,
  setActive
}) => {
  const { updateApplicationResponse } = useFormContext();
  const [touched, setTouched] = useState(false);

  /**
   * Helper method to use with onChange event in input to trigger an update of a response.
   */
  const internalUpdateApplicationResponse = useCallback(
    (isCustomResponse: boolean) => (newResponse: string | string[]) => {
      setTouched(true);
      updateApplicationResponse(questionId, newResponse, isCustomResponse);
    },
    [questionId, setTouched, updateApplicationResponse]
  );

  return (
    <Container
      id={`application-question-${questionId}`}
      isActive={isActive}
      questionNum={questionNum}
      onClick={isActive ? noOp : setActive}
    >
      <NavChevronContainer>
        <NavChevronButton
          direction="up"
          variant="invisible"
          onClick={goPrevQuestion}
          disabled={isFirst}
        >
          <img
            src={ChevronIconImg}
            alt="An icon of an upward-facing chevron for navigating to the previous question"
          />
        </NavChevronButton>
        <NavChevronButton
          direction="down"
          variant="invisible"
          onClick={goNextQuestion}
          disabled={isLast}
        >
          <img
            src={ChevronIconImg}
            alt="An icon of an downward-facing chevron for navigating to the next question"
          />
        </NavChevronButton>
      </NavChevronContainer>

      {!isSurvey && (
        <Text as="span" variant="subtext" className="questionNumLabel">
          Question {questionNum}/{NUM_NON_SURVEY_QUESTIONS}
        </Text>
      )}
      <Text as="h1" variant="heading">
        {question.title}
        {!question.optional && "\xA0*"}
      </Text>
      <Text as="p" variant="subtext">
        {question.desc}
      </Text>

      {useMemo(
        () =>
          inputElementFactory(
            questionId,
            question,
            internalUpdateApplicationResponse(false),
            internalUpdateApplicationResponse(true),
            isActive,
            isReadOnly
          ),
        [
          questionId,
          question,
          internalUpdateApplicationResponse,
          isActive,
          isReadOnly
        ]
      )}

      {!isSurvey &&
        subOptionFactory(question, goNextQuestion, touched, false, isReadOnly)}

      <DisabledRegion disabled={!isActive} />
    </Container>
  );
};

export default Question;
