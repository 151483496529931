import React, { useCallback } from "react";
import styled from "styled-components";
import { Button } from "@hackthenorth/north";

import { AccessorySpeechBubble } from "static/img";

import copy from "copy";
import { useFormContext } from "context/form";
import { GooseAccessory } from "interfaces/user";
import { SplitViewProps, SelectionProps } from "..";

const noOp = () => {};

const AccessibleButton = styled(Button)<SelectionProps>`
  transition: all 250ms ease;
  &:hover,
  &:focus {
    transform: ${({ selected, readOnly }) =>
      selected || readOnly ? "none" : "scale(1.1)"};
  }

  padding: 0 !important;

  cursor: ${({ readOnly }) => (readOnly ? "default" : "pointer")};
`;

const AccessoryContainer = styled.div<SplitViewProps>`
  border: 10px solid ${({ theme }) => theme.globalConstants.color.navy};
  grid-area: accessory;
  margin-top: 10px;
  height: 350px;
  padding: 15px 15px 0 15px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 120px 120px;
  justify-items: center;
  align-items: center;
  align-content: start;
  position: relative;

  & > button {
    padding: 0;
  }

  ${({ theme, showOnMobile }) => theme.mediaQueries.tablet`
    display: ${showOnMobile ? "grid" : "none"};
    align-content: end;
    padding: 0 15px 30px 15px;
    height: 325px;
  `}
`;

const accessoryIconHeights = {
  [GooseAccessory.BERET]: "110px",
  [GooseAccessory.HARDHAT]: "80px",
  [GooseAccessory.HEADPHONES]: "100px",
  [GooseAccessory.SCARF]: "110px"
};

const Accessory = styled.img<{ name: GooseAccessory; selected: boolean }>`
  height: ${({ name }) => accessoryIconHeights[name]};
  align-self: center;
  justify-self: center;

  transition: all 250ms ease;
  opacity: ${({ selected }) => (selected ? 0.2 : 1)};
  filter: ${({ selected }) => (selected ? "grayscale(100%)" : "none")};
`;

const AccessoryBubble = styled.img`
  position: absolute;
  bottom: -105px;
  right: -50px;
  width: 295px;

  ${({ theme }) => theme.mediaQueries.tablet`
    left: -37px;
    top: -50px;
    width: 220px;  
  `}
`;

const ColorPicker: React.FC<SplitViewProps> = ({ showOnMobile }) => {
  const { user, setUser, submitted } = useFormContext();

  const changeAccessory = useCallback(
    (name: GooseAccessory) => () =>
      setUser({
        accessory: name
      }),
    [setUser]
  );

  return (
    <AccessoryContainer showOnMobile={showOnMobile}>
      {copy.pyrg.accessories.map(({ name, img }) => (
        <AccessibleButton
          key={name}
          tabIndex={user.accessory === name ? -1 : 0} // do not allow focus if is currently selected
          selected={user.accessory === name}
          readOnly={submitted}
          variant="invisible"
          onClick={!submitted ? changeAccessory(name) : noOp}
        >
          <Accessory
            name={name}
            selected={user.accessory === name}
            data-tip={GooseAccessory[name]}
            data-for="pyrg-tooltip"
            src={img}
          />
        </AccessibleButton>
      ))}
      <AccessoryBubble src={AccessorySpeechBubble} />
    </AccessoryContainer>
  );
};

export default ColorPicker;
