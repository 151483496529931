import React, { useState, useCallback, useMemo } from "react";
import Heading from "components/Heading";

import fullCopy from "copy";
import { Text, Button, TextInput } from "@hackthenorth/north";
import { useFormContext } from "context/form";
import ActionButtonsContainer from "components/ActionButtonsContainer";
import { FormField, SubtextContainer } from "./components";
import { useSiteContext, AuthPage } from "context/site";

const copy = fullCopy.auth.resetPassword;

// eslint-disable-next-line
const { HackerAPI } = window as any;

const ResetPassword = () => {
  const [authToken, setAuthToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [resetPasswordFailed, setResetPasswordFailed] = useState(false);
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);

  const {
    user: { email }
  } = useFormContext();

  const { setAuthPage } = useSiteContext();

  const validPassword = password === confirmPassword && password.length > 7;

  const resetPassword = useCallback(
    (e: React.FormEvent) => {
      e.preventDefault();
      setLoading(true);

      HackerAPI.Auth.setPassword(email, password, authToken)
        .then(() => {
          setLoading(false);
          setResetPasswordFailed(false);
          setAuthPage(AuthPage.LOG_IN);
        })
        .catch(() => {
          setLoading(false);
          setResetPasswordFailed(true);
        });
    },
    [authToken, email, password, setAuthPage]
  );

  const errorText = useMemo(() => {
    if (resetPasswordFailed) {
      return copy.error;
    } else if (passwordTouched && password.length && password.length <= 7) {
      return fullCopy.auth.createAccount.pwLengthError;
    } else if (
      confirmPasswordTouched &&
      password.length &&
      confirmPassword.length &&
      password !== confirmPassword
    ) {
      return fullCopy.auth.createAccount.pwMatchingError;
    }

    return null;
  }, [
    resetPasswordFailed,
    passwordTouched,
    password,
    confirmPasswordTouched,
    confirmPassword
  ]);

  const disableSubmit = !validPassword || authToken.length === 0 || loading;

  return (
    <form onSubmit={resetPassword}>
      <Heading title={copy.title} subtitle={copy.subtitle} />
      <FormField>
        <Text variant="heading">{copy.authToken}</Text>
        <TextInput
          value={authToken}
          onChange={setAuthToken}
          placeholder={copy.authTokenPlaceholder}
        />
      </FormField>
      <FormField>
        <Text variant="heading">{copy.password}</Text>
        <TextInput
          value={password}
          onChange={setPassword}
          onBlur={() => setPasswordTouched(true)}
          onKeyUp={(key: string) => {
            if (key === "Enter") {
              setPasswordTouched(true);
            }
          }}
          placeholder={copy.passwordPlaceholder}
          type="password"
        />
      </FormField>
      <FormField>
        <Text variant="heading">{copy.confirmPassword}</Text>
        <TextInput
          value={confirmPassword}
          onChange={setConfirmPassword}
          onBlur={() => setConfirmPasswordTouched(true)}
          onKeyUp={(key: string) => {
            if (key === "Enter") {
              setConfirmPasswordTouched(true);
            }
          }}
          placeholder={copy.confirmPasswordPlaceholder}
          type="password"
        />
        <SubtextContainer>
          {errorText && <Text variant="subtextError">{errorText}</Text>}
        </SubtextContainer>
      </FormField>
      <ActionButtonsContainer>
        <Button variant="primary" onClick={() => {}} disabled={disableSubmit}>
          {copy.button}
        </Button>
      </ActionButtonsContainer>
    </form>
  );
};

export default ResetPassword;
